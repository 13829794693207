<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';
import { CmsRegisteredComponent } from '@/utils/cms';

const Header4 = defineComponent({
  name: 'Header4',
  props: {
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'h4' },
  },
});

export const Header4Registration: CmsRegisteredComponent = {
  component: Header4,
  name: 'Header4',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Header 4 text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default Header4;
</script>

<template>
  <component
    :is="headerTag"
    class="text-xl font-semibold leading-6 font-sofia-pro lg:text-2xl lg:leading-7 header-4"
  >
    <slot />
  </component>
</template>

<style scoped>
.header-4 {
  @apply mb-0;
}
</style>
