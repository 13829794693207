import mergeWith from 'lodash/mergeWith';
import type { PartialDeep } from 'type-fest';

const projectKey = import.meta.env.VITE_PROJECT_KEY as string;

export const bundledConfig = {
  algolia: {
    app: import.meta.env.VITE_ALGOLIA_APP_ID as string,
    key: import.meta.env.VITE_ALGOLIA_APP_KEY as string,
  },
  api: {
    host: import.meta.env.VITE_API_HOST as string,
    placeOrderUrl: import.meta.env.VITE_PLACE_ORDER_URL as string,
  },
  apple: {
    clientId: import.meta.env.VITE_APPLE_CLIENT_ID as string,
  },
  auth: {
    host: import.meta.env.VITE_AUTH_HOST as string,
    projectKey,
    credentials: {
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID as string,
      clientSecret: import.meta.env.VITE_AUTH_CLIENT_SECRET as string,
    },
    scopes: [`view_products:${projectKey}`],
  },
  builderIo: {
    key: import.meta.env.VITE_BUILDER_IO_KEY as string,
  },
  facebook: {
    appId: import.meta.env.VITE_FACEBOOK_APP_ID as string,
  },
  gladly: {
    baseUri: import.meta.env.VITE_GLADLY_BASE_URI,
    cdn: import.meta.env.VITE_GLADLY_CDN,
    orgId: import.meta.env.VITE_GLADLY_ORG_ID,
    brandId: import.meta.env.VITE_GLADLY_BRAND_ID,
  },
  google: {
    oauth: {
      clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID as string,
    },
    recaptcha: {
      invisible: {
        sitekey: import.meta.env.VITE_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY as string,
      },
      v3: {
        invisible: {
          sitekey: import.meta.env.VITE_GOOGLE_RECAPTCHA_V3_INVISIBLE_SITEKEY as string,
        },
      },
    },
  },
  preCheckoutDeliveryEstimate: {
    daysThreshold: import.meta.env.VITE_PRE_CHECKOUT_DELIVERY_ESTIMATE_DAYS_THRESHOLD as string,
  },
  rudderstack: {
    dataPlaneUrl: import.meta.env.VITE_RUDDERSTACK_DATA_PLANE_URL,
    writeKey: import.meta.env.VITE_RUDDERSTACK_WRITE_KEY,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  shopify: {
    clientId: import.meta.env.VITE_SHOPIFY_CLIENT_ID,
    shopId: import.meta.env.VITE_SHOPIFY_SHOP_ID,
  },
  ssr: {
    webstore: {
      host: import.meta.env.VITE_SSR_WEBSTORE_HOST as string,
      secure: false,
    },
  },
  webstore: {
    host: import.meta.env.VITE_WEBSTORE_HOST as string,
    secure: true,
  },
};

export type Config = typeof bundledConfig;

export type RuntimeConfig = PartialDeep<Config>;

export const runtimeConfig: RuntimeConfig = {};

export const assignRuntimeConfig = (configSchema: RuntimeConfig) => {
  Object.entries(runtimeConfig).forEach(([key]) => delete (runtimeConfig as any)[key]);
  Object.assign(runtimeConfig, configSchema);
};

export function getConfigEntry<T extends keyof Config>(key: T): Config[T] {
  const { [key]: bundledEntry = {} } = bundledConfig;
  const { [key]: runtimeEntry = {} } = runtimeConfig as any;
  return mergeWith({}, bundledEntry, runtimeEntry, (objValue, srcValue, fieldKey) => {
    if (fieldKey === 'scopes') return srcValue;
    if (objValue && srcValue && objValue.constructor === Array && srcValue.constructor === Array) {
      return srcValue.concat(objValue);
    }
    return undefined;
  });
}

export const getCtConfig = () => getConfigEntry('auth');

export default bundledConfig;
