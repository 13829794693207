import './opentelemetry';
import 'es6-promise/auto';
import 'intersection-observer';
import 'url-polyfill';

/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { track } from '@builder.io/sdk-vue';
import { captureEvent, init, setUser } from '@sentry/browser';
import VueAnnouncer from '@vue-a11y/announcer';
import { Buffer } from 'buffer';
import createDebug from 'debug';
import { createPinia, Pinia, setActivePinia } from 'pinia';
import { HydrationSpec } from 'serverRoot/ssr_utils/createHydrationSpec';
import smoothscroll from 'smoothscroll-polyfill';
import { createSSRApp } from 'vue';
import { Store } from 'vuex';

import { setAndUseRuntimeConfig } from '@/api';
import { getConfigEntry, RuntimeConfig } from '@/api/config';
import createSpaApp, { SpaAppContext } from '@/app/createApp';
import getComponent from '@/components/getComponent';
import BaseHeader from '@/components/layout/header/BaseHeader.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useWindowHooks } from '@/composables/useWindowHooks';
import { RouteSpec } from '@/router/createRouter';
import { initSentry } from '@/sentry';
import createStore from '@/store/createStore';
import { useCustomer } from '@/stores/customer';
import {
  installRudderTyperWrapper,
  RudderstackEventFromServer,
  sendRudderstackEvents,
} from '@/utils/analytics/rudderstack';

(window as any).Buffer = Buffer;

const debug = createDebug('nuts:entry-client');

interface WindowStartupContext {
  __APP_SPEC__: SpaAppContext['props'];
  __HYDRATION_SPECS__: HydrationSpec[];
  __INITIAL_ROUTE__: RouteSpec;
  __INITIAL_STATE__: Parameters<ReturnType<typeof createStore>['replaceState']>[0];
  __PINIA_STATE__: Pinia['state']['value'];
  __RUDDERSTACK_EVENTS_FROM_SERVER__: RudderstackEventFromServer[];
  __WEBFRONT_RUNTIME__: RuntimeConfig;
}

const {
  __APP_SPEC__,
  __HYDRATION_SPECS__ = [],
  __INITIAL_ROUTE__,
  __INITIAL_STATE__,
  __PINIA_STATE__,
  __RUDDERSTACK_EVENTS_FROM_SERVER__ = [],
  __WEBFRONT_RUNTIME__,
} = window as unknown as WindowStartupContext;

installRudderTyperWrapper();

if (__WEBFRONT_RUNTIME__) setAndUseRuntimeConfig(__WEBFRONT_RUNTIME__);

initSentry(
  { captureEvent, init, setUser },
  {
    tunnel: '/sentry',
    integrations(integrations) {
      return integrations.filter(
        (integration) =>
          !['BrowserApiErrors', 'GlobalHandlers', 'ReportingObserver'].includes(integration.name),
      );
    },
  },
);

window.builderIo = {
  track: (event: Parameters<typeof track>[0]) =>
    track({
      ...event,
      apiKey: getConfigEntry('builderIo').key,
    }),
};

smoothscroll.polyfill();

const createVueInstance = async (store: Store<any>, pinia: Pinia, hydrationSpec: HydrationSpec) => {
  const { componentName, props = {} } = hydrationSpec;
  const component = await getComponent(componentName);

  return createSSRApp(component, props).use(pinia).use(store).use(VueAnnouncer);
};

// The presence of __HYDRATION_SPECS__ indicates that the server
// used a mustache template and injected Vue components into the
// already-rendered html.
if (__HYDRATION_SPECS__.length) {
  const pinia = createPinia();
  const store = createStore();
  if (__INITIAL_STATE__) {
    store.replaceState(__INITIAL_STATE__);
  }
  if (__PINIA_STATE__) {
    pinia.state.value = __PINIA_STATE__;
  }

  for (const hydrationSpec of __HYDRATION_SPECS__) {
    try {
      createVueInstance(store, pinia, hydrationSpec).then((vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.config.performance =
          vm.config.performance || (useFeatureFlags().flags.vuePerformanceTiming ?? false);
        vm.mount(`#${hydrationSpec.id}`);
      });
    } catch (err) {
      debug('failed to mount component %s', hydrationSpec.componentName);
    }
  }
  setActivePinia(pinia);
  useCustomer().sendIdentifyEvent();
  Object.assign(window, useWindowHooks({ pinia, store }));
  sendRudderstackEvents(__RUDDERSTACK_EVENTS_FROM_SERVER__);
} else {
  const { app, pinia, router, store } = createSpaApp({
    props: __APP_SPEC__,
    piniaState: __PINIA_STATE__,
    initialRoute: __INITIAL_ROUTE__,
    initialState: __INITIAL_STATE__,
  });

  useCustomer().sendIdentifyEvent();

  Object.assign(window, useWindowHooks({ pinia, router, store }));

  router.isReady().then(() => {
    sendRudderstackEvents(__RUDDERSTACK_EVENTS_FROM_SERVER__);

    // subscribe to the afterEach hook to request critical data for subsequent navigation
    router.afterEach(async (to, from) => {
      const GlobalHeader = BaseHeader;
      const components = [
        GlobalHeader,
        ...router.currentRoute.value.matched.flatMap((m) => Object.values(m.components ?? {})),
      ];
      await Promise.all(components.map((c: any) => c.criticalData?.(store, to, from)));
    });

    app.mount('#app');
  });
}
