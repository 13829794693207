<script setup lang="ts">
import { useElementVisibility, useIntervalFn, useSwipe } from '@vueuse/core';
import { computed, onMounted, ref, useTemplateRef, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { sendPromotionClickedEvent } from '@/utils/analytics/promotionClickedEvent';
import { guardClickEvent } from '@/utils/browser';
import { GlobalCampaignCarousel } from '@/utils/cms';
import { isDefined } from '@/utils/isDefined';

const props = defineProps<{
  items?: GlobalCampaignCarousel['items'];
  isLoading: boolean;
}>();

const currentSlide = ref(0);

const totalSlides = computed(() => (props.items ? props.items.length + 1 : 1)); // +1 for the slot
const showControls = computed(() => totalSlides.value > 1);

const nextSlide = () => {
  if (currentSlide.value >= totalSlides.value - 1) {
    currentSlide.value = 0;
  } else {
    currentSlide.value += 1;
  }
};

const previousSlide = () => {
  if (currentSlide.value <= 0) {
    currentSlide.value = totalSlides.value - 1;
  } else {
    currentSlide.value -= 1;
  }
};

const swipeRoot = useTemplateRef('swipeRoot');
useRouterLinks(swipeRoot, useRouter());
const { direction } = useSwipe(swipeRoot);
const visible = useElementVisibility(swipeRoot);

watch(direction, (value) => {
  if (value === 'left') {
    previousSlide();
  } else if (value === 'right') {
    nextSlide();
  }
});

const { pause, resume } = useIntervalFn(
  () => {
    if (totalSlides.value > 1) {
      nextSlide();
    }
  },
  4000,
  { immediate: false },
);

function extractNestedTextNodesContent(childNode: ChildNode): (string | undefined)[] {
  if (childNode.nodeType !== Node.ELEMENT_NODE && childNode.nodeType !== Node.TEXT_NODE) return [];
  if (childNode.nodeType === Node.TEXT_NODE) return [childNode.textContent?.trim()];
  if (childNode.nodeType === Node.ELEMENT_NODE && childNode.childNodes.length) {
    const textEntries: string[] = [];
    childNode.childNodes.forEach((node) => {
      extractNestedTextNodesContent(node)
        .filter(isDefined)
        .forEach((textEntry) => {
          textEntries.push(textEntry);
        });
    });
    return textEntries;
  }
  return [];
}

const handlePromoClicked = (event: MouseEvent, position: number, promotionName: string) => {
  if (!guardClickEvent(event)) return;

  let content: string | undefined = event.currentTarget.innerText;
  if (!content) {
    if (!event.currentTarget.childNodes.length) return;
    content = Array.from(event.currentTarget.childNodes)
      .flatMap(extractNestedTextNodesContent)
      .join(' ')
      .trim();
  }
  /**
   * If no text could be found, give up and at least look for the first paragraph's textContent.
   * While this may include some HTML tags, the beginning of the content is likely to be there and
   * ought to provide some useful insights.
   */
  if (!content) {
    const firstParagraph = event.currentTarget.querySelector('p');
    if (firstParagraph) {
      content = firstParagraph.textContent?.trim() ?? '';
    }
  }
  if (!content) {
    console.error(
      'No content found for promotion click event in Global Campaign Carousel',
      event.currentTarget,
    );
    return;
  }

  sendPromotionClickedEvent({
    content,
    pageSection: 'Global Campaign Carousel',
    position: position + 1, // start at 1
    promotion_name: promotionName,
  });
};

function normalizeRichText(text: string) {
  if (text.startsWith('<p>') && text.endsWith('</p>')) {
    return text.substring(3, text.length - 4);
  }
  return text;
}

onMounted(() => {
  watchEffect(() => {
    if (visible.value && totalSlides.value > 1 && !props.isLoading) {
      resume();
    }
  });
});
</script>

<template>
  <div
    class="relative w-full overflow-hidden h-7 bg-nuts-amber-400 lg:bg-nuts-neutral-950 lg:text-white lg:max-w-[350px]"
    ref="swipeRoot"
    @focusin="pause"
    @focusout="resume"
    @mouseenter="pause"
    @mouseleave="resume"
  >
    <div v-if="!isLoading" class="relative h-full lg:ml-px">
      <div
        class="absolute inset-0 flex items-center justify-center w-full h-full truncate lg:max-w-[310px] lg:justify-start carousel-slide"
        :class="[
          {
            'fancy-slide-in z-10': currentSlide === 0,
            'fancy-slide-out z-0 invisible': currentSlide !== 0,
          },
          showControls ? 'lg:left-12' : 'lg-left-0',
        ]"
        data-test="campaign-carousel-slide"
        @click="handlePromoClicked($event, 0, 'Default Content')"
      >
        <slot />
      </div>

      <div
        v-for="(item, index) in items"
        :key="item.promotionName"
        class="absolute inset-0 flex items-center justify-center w-full h-full lg:left-12 truncate lg:max-w-[310px] lg:justify-start carousel-slide"
        :class="{
          'fancy-slide-in z-10 ': currentSlide === index + 1,
          'fancy-slide-out z-0 invisible': currentSlide !== index + 1,
        }"
        data-test="campaign-carousel-slide"
        @click="handlePromoClicked($event, index + 1, item.promotionName)"
      >
        <SmallBodyText>
          <span v-html="normalizeRichText(item.content)" />
        </SmallBodyText>
      </div>
    </div>

    <Transition :duration="600" name="fade">
      <span v-if="showControls && !isLoading">
        <UnstyledButton
          @click="previousSlide"
          class="left-0 flex items-center justify-center rounded-full carousel-control lg:hover:bg-white/25"
          aria-label="Previous slide"
        >
          <ChevronIcon direction="left" :size="20" class="text-neutral-500 lg:text-neutral-400" />
        </UnstyledButton>
        <UnstyledButton
          @click="nextSlide"
          class="right-0 flex items-center justify-center rounded-full lg:right-auto lg:left-5 carousel-control lg:hover:bg-white/25"
          aria-label="Next slide"
        >
          <ChevronIcon direction="right" :size="20" class="text-neutral-500 lg:text-neutral-400" />
        </UnstyledButton>
      </span>
    </Transition>
  </div>
</template>

<style scoped>
:deep(p) {
  @apply my-0;
}

:deep(a) {
  @apply text-black underline lg:text-white;
}

.fancy-slide-out {
  animation: slide-out 200ms;
  animation-fill-mode: forwards;
}

@keyframes slide-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

.fancy-slide-in {
  animation: slide-in 400ms;
  animation-delay: 100ms;
  animation-fill-mode: backwards;
}

@keyframes slide-in {
  from {
    transform: translateX(-3%);
    opacity: 0.25;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
}
</style>
