import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import qs from 'qs';

const isBrowser = typeof window !== 'undefined';

export const adaptMultipleQueryValues = (searchParams, rebuildParams = false) => {
  let { query } = searchParams;
  if (searchParams.query && typeof searchParams.query !== 'string') {
    query = query[query.length - 1];
  }
  if (rebuildParams) {
    return query !== searchParams.query
      ? {
          ...searchParams,
          query,
        }
      : searchParams;
  }
  return query;
};
const isSearchParam = (p) =>
  p.startsWith('refinementList') ||
  p.startsWith('toggle') ||
  ['page', 'query', 'sortBy'].includes(p);

export const createAlgoliaRouter = (router, initialRequestQuery) => ({
  read() {
    let { query: params } = router.currentRoute.value;
    if (!router.currentRoute.value.matched.length) {
      params = qs.parse(initialRequestQuery, { ignoreQueryPrefix: true });
    }
    return adaptMultipleQueryValues(params, true);
  },
  write(routeState) {
    if (isEmpty(routeState) || !isBrowser) return;
    if (this.writeTimer) {
      window.clearTimeout(this.writeTimer);
    }
    const self = this;
    this.writeTimer = setTimeout(() => {
      const current = self.read();
      const { sortBy, ...targetState } = routeState;
      if (sortBy !== 'Products') {
        targetState.sortBy = sortBy;
      }
      const otherParams = omitBy(current, (_, k) => isSearchParam(k));
      const updated = { ...targetState, ...otherParams };
      if (qs.stringify(updated) !== qs.stringify(current)) {
        router.push({ query: updated });
      }
      self.writeTimer = undefined;
    }, 400);
  },
  writeTimer: undefined,
  createURL(routeState) {
    return router.resolve({
      query: routeState,
    }).href;
  },
  onUpdate(callback) {
    if (!isBrowser) return;

    const self = this;
    const applyStateFromRouteQuery = () => {
      if (self.writeTimer) {
        window.clearTimeout(self.writeTimer);
        self.writeTimer = undefined;
      }
      callback(self.read());
    };

    this.forceUpdate = applyStateFromRouteQuery;

    // eslint-disable-next-line no-underscore-dangle
    this._onPopState = applyStateFromRouteQuery;

    // eslint-disable-next-line no-underscore-dangle
    window.addEventListener('popstate', this._onPopState);
  },
  dispose() {
    // eslint-disable-next-line no-underscore-dangle
    window.removeEventListener('popstate', this._onPopState);
    if (this.writeTimer) {
      window.clearTimeout(this.writeTimer);
    }
    // we purposely don't write on dispose, to prevent double entries on navigation
  },
});
