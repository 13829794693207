import { ContactSubscribed, contactSubscribed } from '@/rudder-typer';

export function sendContactSubscribed({ email, phone, reason, source }: ContactSubscribed) {
  contactSubscribed({
    email,
    phone,
    reason,
    source,
  });
}
