<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import HorizontalProductCard from '@/components/base/layout/HorizontalProductCard.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import WindowObserver from '@/components/base/WindowObserver.vue';
import { useCart } from '@/composables/useCart';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import money from '@/filters/money';
import {
  AnalyticsMetadata,
  BaseChoice,
  RecommendationsSlot,
} from '@/lib/personalization/dynamicYield';
import analytics, { gtag, setListMetadata } from '@/utils/analytics';
import {
  formatAnalyticsMetadata,
  ListSourceAttribution,
} from '@/utils/analytics/listSourceAttribution';
import {
  formatNutsAddToCartItemFromProductCard,
  NutsAddToCartItem,
  sendProductAddedEvent,
} from '@/utils/analytics/productAddedEvent';
import {
  isListPageSection,
  NutsViewItemListItem,
  ProductListViewedEventData,
  pushProductListViewed,
} from '@/utils/analytics/productListViewedEvent';
import { getCartId } from '@/utils/cart';
import { displayPrice, ProductCard, ProductCardData } from '@/utils/productCard';
import { reportError } from '@/utils/reportError';

const props = withDefaults(
  defineProps<{
    analyticsList?: string;
    analyticsMetadata?: AnalyticsMetadata;
    decisionId?: BaseChoice['decisionId'];
    enableAddToCart?: boolean;
    list?: RecommendationsSlot[];
    pageSection?: NutsAddToCartItem['pageSection'] | ProductListViewedEventData['pageSection'];
    skipCartAdded?: boolean;
    title?: string;
    trackingEventLocation?: string;
  }>(),
  {
    analyticsList: '',
    trackingEventLocation: '',
  },
);

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const store = useStore();
const { addToCart, cart } = useCart(store);

const { flags } = useFeatureFlags();

const addedItems = defineModel<Set<string>>('addedSkus');
const isAdded = (sku: string) => addedItems.value?.has(sku);

const recommendations = computed(() =>
  (props.list ?? []).map((recommendation) => {
    const productCard = ProductCard.fromDY(recommendation);
    return {
      displayPrice: displayPrice(productCard, flags.testVariantNameDisplay),
      productCard,
    };
  }),
);

const listSourceAttribution = computed<ListSourceAttribution | undefined>(
  () =>
    props.analyticsMetadata && {
      ...formatAnalyticsMetadata(props.analyticsMetadata),
      listSource: 'Dynamic Yield',
      listTitle: props.title,
    },
);

const microData = (product: ProductCardData, position: number) => ({
  id: product.sku,
  list: props.analyticsList || props.title,
  name: product.name,
  position,
  price: product.price,
});

const reportClick = (productCard: ProductCardData, index: number) => {
  analytics.sendEvent('productClick', props.analyticsList || props.title || '', {
    ecommerce: {
      click: {
        actionField: { list: props.analyticsList || props.title },
        products: [microData(productCard, index)],
      },
    },
  });
  setListMetadata({
    list: props.analyticsList || props.title || '',
    position: index,
  });
  gtag('event', 'select_item', {
    items: [
      {
        autodelivery_interval: undefined,
        cost: productCard.cost,
        coupon: productCard.totalSavings?.description?.en,
        discount: dollars(productCard.totalSavings?.value || from(0)),
        item_id: productCard.productKey,
        item_list_name: props.analyticsList || props.title,
        item_name: productCard.name,
        item_variant_name: productCard.unitName,
        item_variant: productCard.sku,
        price: productCard.price,
        price_before_discount: dollars(
          productCard.totalSavings?.comparisonPrice ?? productCard.piecePrice,
        ),
        quantity: 1,
        reporting_category: productCard.reportingCategory,
        weight: undefined,
      },
    ],
  });
};

const handleProductClick = (productCard: ProductCardData, index: number) => {
  reportClick(productCard, index);
  emit('click');
};

const addingToCartItems = ref<Record<string, boolean>>({});

async function handleAddToCart(productCard: ProductCardData) {
  const { sku } = productCard;
  addingToCartItems.value[sku] = true;

  sendProductAddedEvent(
    formatNutsAddToCartItemFromProductCard(productCard, {
      cartId: getCartId(cart.value),
      itemListName: props.analyticsList || props.title,
      listSourceAttribution: listSourceAttribution.value,
      markedAsGift: false,
      pageSection: props.pageSection,
      quantity: 1,
    }),
  );

  await addToCart(
    { cart_sku: { quantity: 1, sku_external_id: sku } },
    { postAddToCartCallback: () => null, skipCartAdded: true },
  );

  addingToCartItems.value[sku] = false;
  addedItems.value?.add(sku);
}

const impressionLogged = ref(new Map<string, boolean>());
const reportVisibility = (productCard: ProductCardData, index: number) => {
  if (impressionLogged.value.has(productCard.sku)) return;

  impressionLogged.value.set(productCard.sku, true);
  analytics.sendEvent('productImpressions', props.title ?? '', {
    ecommerce: {
      currencyCode: 'USD',
      impressions: [microData(productCard, index)],
    },
  });

  if (!isListPageSection(props.pageSection)) {
    reportError(`Incorrect pageSection for Product List Viewed event: ${props.pageSection}`);
    return;
  }

  const itemListName = props.analyticsList || props.title || '';
  const nutsViewItemListItem: NutsViewItemListItem = {
    cost: from(parseFloat(productCard.cost)),
    coupon: productCard.totalSavings?.description?.en,
    discount: productCard.totalSavings?.value || from(0),
    indexName: undefined,
    name: productCard.name,
    position: index,
    priceBeforeDiscount: productCard.totalSavings?.comparisonPrice ?? productCard.piecePrice,
    price: productCard.piecePrice,
    product_id: productCard.productKey,
    quantity: 1,
    reporting_category: productCard.reportingCategory,
    searchQueryID: undefined,
    sku: productCard.sku,
    variant: productCard.unitName,
    weight: undefined,
  };

  pushProductListViewed(itemListName, nutsViewItemListItem, props.pageSection);
};
</script>

<template>
  <div v-if="recommendations.length" data-test="recommendations">
    <div
      v-for="({ displayPrice, productCard }, index) in recommendations"
      :key="productCard.productKey"
      class="z-10 flex justify-between px-4 py-3 border-b border-solid gap-x-3 shrink-0 md:py-2 border-neutral-300"
    >
      <WindowObserver @intersect="reportVisibility(productCard, index)">
        <HorizontalProductCard
          data-test="recommendation-product-card"
          :decisionId
          imageSize="medium"
          :listSourceAttribution
          :pageSection="props.pageSection"
          :product="productCard"
          @click="handleProductClick(productCard, index)"
        >
          <template #price>
            <span v-if="productCard.totalSavings" class="sr-only">Price reduced from </span>
            <BaseBodyText
              :class="
                productCard.totalSavings?.onSale
                  ? 'text-nuts-red-800 font-semibold'
                  : 'text-neutral-500'
              "
            >
              {{ productCard.hidePrice ? 'Build your own' : displayPrice }}
            </BaseBodyText>
            <span v-if="productCard.totalSavings" class="sr-only"> to </span>
            <SmallBodyText
              v-if="productCard.totalSavings"
              class="ml-1.5 line-through text-neutral-500"
            >
              {{ money(productCard.totalSavings?.comparisonPrice) }}
            </SmallBodyText>
          </template>
        </HorizontalProductCard>
      </WindowObserver>
      <div class="flex items-center justify-center shrink-0">
        <slot name="button" :product="productCard">
          <AddToCartOrCustomize
            v-if="enableAddToCart"
            class="w-20 md:w-36"
            :disabled="isAdded(productCard.sku)"
            fullWidth
            :isLoading="addingToCartItems[productCard.sku]"
            :outOfStock="!productCard.inStock"
            :path="productCard.path"
            :requiresCustomization="productCard.requiresCustomization"
            size="default"
            @add-to-cart="handleAddToCart(productCard)"
            @customization-started="emit('click')"
          >
            <template v-if="isAdded(productCard.sku)" #add-to-cart-text>Added to Cart</template>
          </AddToCartOrCustomize>
        </slot>
      </div>
    </div>
  </div>
</template>
