<script setup lang="ts">
import { computed } from 'vue';

import rebrandLogoV1 from '@/assets/layout/nuts-stamp-logo.svg';
import logo from '@/assets/yellow_box_logo.svg';
import CashewIcon from '@/components/base/assets/CashewIcon.vue';
import Caption from '@/components/base/typography/Caption.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import HiddenLink from '@/components/layout/header/HiddenLink.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';

withDefaults(
  defineProps<{
    fullWidth?: boolean;
    logoAlt?: string;
    logoImage?: string;
    logoLink?: string;
  }>(),
  {
    fullWidth: false,
    logoAlt: 'Go to Homepage',
    logoLink: '/',
  },
);

const { flags } = useFeatureFlags();

const defaultLogo = computed(() => (flags.layoutRebrandingChangesV1 ? rebrandLogoV1 : logo));
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

export const SimplifiedHeaderRegistration: BuilderComponent = {
  name: 'Simplified Header',
  canHaveChildren: true,
  inputs: [
    {
      name: 'fullWidth',
      type: 'boolean',
      helperText: 'Should the header be full width or has a pre-defined max width?',
    },
    {
      name: 'logoAlt',
      type: 'string',
      helperText: 'Alt text for the logo',
    },
    {
      name: 'logoImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'logoLink',
      type: 'string',
      helperText: 'Where should the logo redirect to?',
    },
  ],
};
</script>

<template>
  <div role="navigation" id="appHeader" class="relative z-10 shadow-b">
    <div
      class="flex items-center justify-between w-full h-16 px-4 mx-auto md:h-20 md:px-10"
      data-test="simplified-header"
      :class="{ '2xl:max-w-screen-2xl': !fullWidth }"
    >
      <HiddenLink to="#skiptocontent">Skip to main content</HiddenLink>
      <HiddenLink to="https://nuts.com/accessibility.html">
        Click to go to accessibility policy page
      </HiddenLink>
      <div class="flex items-center">
        <RouterLink :to="logoLink">
          <img
            :alt="logoAlt"
            class="mr-8 md:h-14"
            :class="flags.layoutRebrandingChangesV1 ? 'h-12' : 'h-9'"
            data-test="logo-image"
            :src="logoImage ?? defaultLogo"
          />
        </RouterLink>
        <slot name="title" />
      </div>
      <slot name="right">
        <div class="flex items-center" data-test="need-help">
          <CashewIcon class="mr-2" />
          <div>
            <Caption>Need help?</Caption>
            <SmallBodyText>
              <a href="tel:8005586887" class="block tel">800-558-6887</a>
            </SmallBodyText>
          </div>
        </div>
      </slot>
    </div>
  </div>
  <span id="skiptocontent" class="sr-only">Main Content</span>
</template>

<style lang="scss" scoped>
.shadow-b {
  box-shadow: 0px 1px 5px 0px rgb(161 161 161 / 50%);
}
</style>
