<script setup lang="ts">
import { ProposedDelivery, Subscription } from '@nuts/auto-delivery-sdk';
import { useSessionStorage } from '@vueuse/core';
import { ref } from 'vue';

import AddedToSubscriptionActions from '@/components/auto-delivery/added-to-auto-delivery/AddedSubscriptionControls.vue';
import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useCallback } from '@/composables/useCallback';
import { getProposedDeliveryInterval } from '@/lib/autoDelivery';
import { AnalyticsMetadata } from '@/lib/personalization/dynamicYield';
import { useAutoDelivery } from '@/stores/autoDelivery';
import { sendProductSubscriptionCreatedEvent } from '@/utils/analytics/productSubscriptionCreatedEvent';
import { getDeliverySubscriptionBySku } from '@/utils/autoDelivery';
import { ProductCardData } from '@/utils/productCard';

const props = defineProps<{
  delivery: ProposedDelivery;
  mode: 'one-time' | 'recurring';
  product: ProductCardData;
  showManageButton?: boolean;
}>();

const emit = defineEmits<{
  added: [product: ProductCardData];
  removed: [product: ProductCardData];
}>();

const addedSubscription = ref<Subscription>();

const autoDeliveryStore = useAutoDelivery();

async function refreshOrders() {
  await autoDeliveryStore.getOrders({
    preloadPaymentMethod: false,
    preloadShippingAddresses: false,
  });
}

const autoDeliveryUpsellAttribution = useSessionStorage<{
  analyticsMetadata?: AnalyticsMetadata;
}>('autoDeliveryUpsell.attribution', {});

const onAddToAutoDelivery = useCallback(async () => {
  const interval =
    props.mode === 'recurring' ? getProposedDeliveryInterval(props.delivery, 'days') : undefined;

  await autoDeliveryStore.createSubscription({
    active: true,
    interval,
    nextShipOn: props.delivery.shipOn,
    offerLocation: 'Added to Auto-Delivery Modal',
    offerType: props.mode === 'recurring' ? 'IU Recurring' : 'IU Upsell',
    quantity: 1,
    shippingAddressId: props.delivery.shippingAddressId,
    sku: props.product.sku,
  });
  await refreshOrders();

  const updatedDelivery = autoDeliveryStore.orders.find(({ id }) => id === props.delivery.id);

  if (updatedDelivery) {
    addedSubscription.value = getDeliverySubscriptionBySku(props.delivery, props.product.sku);

    const { analyticsMetadata } = autoDeliveryUpsellAttribution.value;

    if (addedSubscription.value) {
      sendProductSubscriptionCreatedEvent({
        analyticsMetadata,
        pageSection: 'Added to Auto-Delivery Modal',
        productKey: props.product.productKey,
        subscription: addedSubscription.value,
      });
    }
  }

  emit('added', props.product);
});

const handleRemove = useCallback(async () => {
  if (!addedSubscription.value) return;
  await autoDeliveryStore.cancelSubscription(
    addedSubscription.value,
    'Removed with remove button',
    undefined,
    false,
  );
  await refreshOrders();
  addedSubscription.value = undefined;
  emit('removed', props.product);
});
</script>

<template>
  <Transition class="w-full" mode="out-in" name="fade">
    <AddedToSubscriptionActions
      v-if="addedSubscription"
      :delivery
      :isLoading="handleRemove.isPending"
      :showManageButton
      :subscription="addedSubscription"
      @remove="handleRemove.execute"
    />
    <AddToCartOrCustomize
      v-else-if="product.autoDeliveryEligible"
      class="h-10 gap-1 px-4 whitespace-nowrap"
      fullWidth
      :isLoading="onAddToAutoDelivery.isPending"
      theme="blue"
      @add-to-cart="onAddToAutoDelivery.execute"
    >
      <template #add-to-cart-text>
        <SmallBodyText>
          <template v-if="onAddToAutoDelivery.isPending">Adding</template>
          <template v-else-if="mode === 'recurring'">Add to Auto-Delivery</template>
          <template v-else>Ship One-Time</template>
        </SmallBodyText>
      </template>
    </AddToCartOrCustomize>
  </Transition>
</template>
