export async function retry<T>(
  fn: () => T | Promise<T>,
  {
    onError,
    reset,
    retries = 3,
  }: {
    /** called if `fn` throws; return `false` to refrain from retrying anymore */
    onError?: (error: any) => boolean | Promise<boolean>;
    /** called before retrying (use to reset state, etc if needed) */
    reset?: (error: any) => void | Promise<void>;
    /** maximum number of retries (after initial try; `retries: 3` may execute `fn` four times) */
    retries?: number;
  } = {},
): Promise<T> {
  let lastError;
  for (let retriesLeft = retries; retriesLeft >= 0; retriesLeft -= 1) {
    if (retriesLeft !== retries) {
      // eslint-disable-next-line no-await-in-loop
      if ((await onError?.(lastError)) === false) {
        break;
      }
      // debug('retrying');
      // eslint-disable-next-line no-await-in-loop
      await reset?.(lastError);
    }

    try {
      // eslint-disable-next-line no-await-in-loop
      return await fn();
    } catch (error) {
      lastError = error;
    }
  }
  throw lastError;
}
