import { computed, reactive, readonly, ref } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import { fetchDyChooseResults, RealtimeRule } from '@/api/dynamic-yield/experiences';
import { useDyRequestContext } from '@/composables/dynamic-yield/requestContext';
import { JsonChoice, RecommendationsChoice } from '@/lib/personalization/dynamicYield';
import { sendExperimentViewedEvent } from '@/utils/analytics/experimentViewedEvent';
import { reportError } from '@/utils/reportError';

type KeyedChoices<T> = Record<string, T | undefined>;

function deriveExperiences<T>(experienceNames: string[], preloadedChoices?: KeyedChoices<T>) {
  const choices = { ...preloadedChoices };
  experienceNames.forEach((name) => {
    if (name in choices) return;
    choices[name] = undefined;
  });
  return choices;
}

function normalizeStringArray(stringOrArray: string | string[]) {
  return Array.isArray(stringOrArray) ? stringOrArray : [stringOrArray];
}

interface Options<T> {
  preloadedChoices?: KeyedChoices<T>;
  syncContextWithRoute?: boolean;
}

export const useDyChooseResults = <T extends JsonChoice | RecommendationsChoice>(
  singleOrMultipleExperienceNames: string | string[],
  route?: RouteLocationNormalized,
  options?: Options<T>,
) => {
  const choicesByName = reactive(
    deriveExperiences<T>(
      normalizeStringArray(singleOrMultipleExperienceNames),
      options?.preloadedChoices,
    ),
  );
  const selectors = computed(() => {
    const experienceNames = Object.keys(choicesByName);
    const loadedExperiences: string[] = [];
    const pendingExperiences: string[] = [];
    experienceNames.forEach((name) => {
      if (choicesByName[name] === undefined) {
        pendingExperiences.push(name);
      } else {
        loadedExperiences.push(name);
      }
    });
    return { experienceNames, loadedExperiences, pendingExperiences };
  });

  /**
   * Work around the fact that DY has no way to avoid duplicate recommendations
   * across multiple decisions (i.e. multiple rec rows), even within the same
   * request/response.
   *
   * Note: For simplicity, this directly mutates the slots of its input. It
   * could be reworked to clone or carefully disassemble and reassemble the
   * input if needed.
   */
  const filterDuplicateDyRecommendations = (choices: T[]): T[] => {
    const seen = new Set<string>();
    return choices.map((choice) => {
      // FIXME Is our typedef correct that there might not be any variations for a RECS_DECISION?
      if (choice.type === 'RECS_DECISION' && choice.variations.length) {
        const { data } = choice.variations[0].payload;
        data.slots = data.slots.filter(({ productData }) =>
          seen.has(productData.product_key) ? false : seen.add(productData.product_key),
        );
      }
      return choice;
    });
  };

  const isPending = ref(false);
  const { additionalCustomAttributes, chooseParams, pageContext } = useDyRequestContext(
    route,
    options?.syncContextWithRoute,
  );
  const loadExperiences = async (requestOptions?: {
    expandingConfig?: string;
    newExperiences?: string | string[];
    realtimeRules?: Record<string, RealtimeRule[]>;
    skipLoadedExperiences?: boolean;
  }) => {
    let { experienceNames } = selectors.value;
    try {
      if (requestOptions?.newExperiences) {
        normalizeStringArray(requestOptions.newExperiences).forEach((name) => {
          if (choicesByName[name]) return;
          choicesByName[name] = undefined;
        });
      }
      experienceNames = requestOptions?.skipLoadedExperiences
        ? selectors.value.pendingExperiences
        : selectors.value.experienceNames;
      if (requestOptions?.expandingConfig) {
        experienceNames = experienceNames.filter((name) => name !== requestOptions.expandingConfig);
      }
      if (!experienceNames.length) return;
      isPending.value = true;
      const { choices } = await fetchDyChooseResults<T>(experienceNames, {
        ...chooseParams.value,
        realtimeRules: requestOptions?.realtimeRules,
      });
      filterDuplicateDyRecommendations(choices).forEach((choice) => {
        choicesByName[choice.name] = choice;

        const analyticsMetadata = choice.variations[0]?.analyticsMetadata;

        if (analyticsMetadata && choice.type !== 'RECS_DECISION') {
          sendExperimentViewedEvent(
            {
              campaign_id: `${analyticsMetadata.campaignId}`,
              campaign_name: analyticsMetadata.campaignName,
              experiment_id: `${analyticsMetadata.experienceId}`,
              experiment_name: analyticsMetadata.experienceName,
              variation_id: `${analyticsMetadata.variationId}`,
              variation_name: analyticsMetadata.variationName,
            },
            'Dynamic Yield',
          );
        }
      });
    } catch (error) {
      reportError(error, `Failed to fetch DY choose results for [${experienceNames.join(', ')}]`);
    }
    isPending.value = false;
  };

  return {
    additionalCustomAttributes,
    choicesByName,
    isPending: readonly(isPending),
    loadExperiences,
    pageContext,
  };
};
