import { AsyncLocalStorage } from 'async_hooks';

import { RudderstackEventFromServer } from '@/utils/analytics/rudderstack';

export interface SsrState {
  cookie?: string;
  dataLayer?: any[];
  rudderstackEvents?: RudderstackEventFromServer[];
  userAgent?: string;
}

export type SsrStorage = AsyncLocalStorage<SsrState>;

let ssrStorage: SsrStorage | undefined;

export function getSsrState() {
  const store = ssrStorage?.getStore();
  if (!store) throw new Error('Missing required `AsyncLocalStorage`');
  return store;
}

export function setSsrStorage(asyncLocalStorage: SsrStorage) {
  ssrStorage = asyncLocalStorage;
}
