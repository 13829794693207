import { NutsLineItem } from '@/lib/cart/lineItem';
import { productRemoved } from '@/rudder-typer';
import { formatStandardProductsItem } from '@/utils/analytics/rudderstack';
import { reportError } from '@/utils/reportError';

export function sendProductRemovedEvent(lineItem: NutsLineItem, cartId?: string): void {
  if (!cartId) {
    reportError('Missing cart ID when removing line item');
    return;
  }

  productRemoved({
    ...formatStandardProductsItem(lineItem),
    cart_id: cartId,
  });
}
