<script setup lang="ts">
import { useVModel } from '@vueuse/core';

const emit = defineEmits<{ (e: 'update:modelValue', val: boolean): void }>();
const props = defineProps<{
  disabled?: boolean;
  id?: string;
  modelValue?: boolean;
}>();

const checked = useVModel(props, 'modelValue', emit);
</script>

<template>
  <label
    class="flex items-center my-0 leading-none align-middle"
    data-test="checkbox-label"
    :class="{ 'cursor-pointer': !disabled }"
  >
    <input
      v-model="checked"
      class="inline-block w-4 h-4 my-0 ml-0 align-middle base-checkbox accent-bg-nuts-lime-800 accent-nuts-lime-800"
      :class="{ 'mr-2': $slots.default, 'cursor-pointer': !disabled }"
      :disabled="disabled"
      :id="id ?? 'checkbox-input'"
      type="checkbox"
    />
    <slot />
  </label>
</template>
