import { useEventListener } from '@vueuse/core';
import { onMounted } from 'vue';

import { gtag } from '@/utils/analytics';
import { ClickEvent } from '@/utils/browser';

/**
 * Adds an event listener that checks for `data-promo="1"` attributes on the page.
 *
 * Sends a `select_content` event when the element is clicked.
 *
 * **Important**: this composable should not be consumed multiple times down a component tree.
 * - For SPA pages: already handled by SPA root component (`App.vue`).
 * - For Surrogate pages: recommended to call this composable once on the root component (e.g `EasyReorder.vue`)
 */
export const useDataPromo = () => {
  const sentPromoContentEvents = new Set<string>();

  const trackPromoContent = (element: Element, eventName: Parameters<typeof gtag>[1]) => {
    const name = element.getAttribute('data-promo-creative');
    const slot = element.getAttribute('data-promo-name');
    const sentKey = `${eventName}|${name}|${slot}`;

    if (!sentPromoContentEvents.has(sentKey)) {
      const creativeParams = {
        creative_name: name,
        creative_slot: slot,
      };
      gtag('event', eventName, creativeParams);
      sentPromoContentEvents.add(sentKey);
    }
  };

  onMounted(() => {
    useEventListener<ClickEvent>(document, 'click', (e) => {
      const promotionalContent = e.target?.closest('[data-promo="1"]');
      if (!promotionalContent) return;
      trackPromoContent(promotionalContent, 'select_content');
    });
  });
};
