<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';
import { CmsRegisteredComponent } from '@/utils/cms';

const Header2 = defineComponent({
  name: 'Header2',
  props: {
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'h2' },
  },
});

export const Header2Registration: CmsRegisteredComponent = {
  component: Header2,
  name: 'Header2',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Header 2 text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default Header2;
</script>

<template>
  <component :is="headerTag" class="font-semibold header-2 font-gelica">
    <slot />
  </component>
</template>

<style scoped>
.header-2 {
  @apply mb-0;
  font-size: 28px;
  line-height: 32px;
}
@media (min-width: 1024px) {
  .header-2 {
    font-size: 40px;
    line-height: 44px;
  }
}
</style>
