<script setup lang="ts">
// eslint-disable-next-line simple-import-sort/imports
import { SearchIndex } from 'algoliasearch';
import { computed, onMounted, onServerPrefetch, ref } from 'vue';
import { useRoute } from 'vue-router';

import { AlgoliaVariant, browseProducts, getIndex, init as initAlgolia } from '@/api/algolia';
import Slider from '@/components/base/Slider.vue';
import ProductCardWithOptions from '@/components/product-card/ProductCardWithOptions.vue';
import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import { RecommendationsChoice, RecommendationsSlot } from '@/lib/personalization/dynamicYield';
import { ProductCard } from '@/utils/productCard';
// eslint-disable-next-line import/no-duplicates
import { SpacingDirection, SpacingLevel, useSpacing } from '@/composables/useSpacing';
import { useCriticalData } from '@/stores/criticalData';
import {
  formatAnalyticsMetadata,
  ListSourceAttribution,
} from '@/utils/analytics/listSourceAttribution';
import {
  analyticsMetadata,
  productSlotsFromChoice,
} from '@/utils/dynamic-yield/recommendationsRow';

interface Props {
  analyticsList?: string;
  clickTrackable?: boolean;
  controlsColor?: string;
  enableAddToCart?: boolean;
  flushRightOnMobile?: boolean;
  impressionTrackable?: boolean;
  link?: string;
  prefetch?: boolean;
  selector: string;
  scrollButtonsOnSides?: boolean;
  spacingDirection: SpacingDirection;
  spacingLevel: SpacingLevel;
  subtitle?: string;
  title?: string;
  trackingEventLocation?: string;
}

const props = withDefaults(defineProps<Props>(), {
  analyticsList: '',
  clickTrackable: true,
  controlsColor: 'black',
  impressionTrackable: true,
  prefetch: false,
  scrollButtonsOnSides: false,
  trackingEventLocation: '',
});

const { spacing } = useSpacing(props);

interface CriticalData {
  choicesByName?: Record<string, RecommendationsChoice | undefined>;
}
const critical = useCriticalData<CriticalData>(props.selector);
const { choicesByName, loadExperiences } = useDyChooseResults<RecommendationsChoice>(
  props.selector,
  useRoute(),
  { preloadedChoices: critical.data.choicesByName },
);
const choice = computed(() => choicesByName[props.selector]);
const listSourceAttribution = computed<ListSourceAttribution | undefined>(() => {
  const metadata = analyticsMetadata(choice.value);
  if (metadata) {
    return {
      ...formatAnalyticsMetadata(metadata),
      listSource: 'Dynamic Yield',
      listTitle: props.title,
    };
  }
  return undefined;
});
const productsList = computed<RecommendationsSlot[]>(() => productSlotsFromChoice(choice.value));

const productsVariants = ref<AlgoliaVariant[]>();
const loadVariants = async (productsIndex: SearchIndex) => {
  const productKeys = productsList.value?.map((s) => `Product.key:${s.productData.product_key}`);
  productsVariants.value = await browseProducts(productsIndex, {
    filters: productKeys?.join(' OR '),
    facetFilters: ['shortVariantName:-sample', 'shortVariantName:-mini box'],
  });
};

const getVariantsByProductKey = (productKey: string) =>
  productsVariants.value
    ?.filter((variant) => variant.Product.key === productKey)
    .map(ProductCard.fromAlgolia);

onMounted(async () => {
  const algoliaClient = initAlgolia();
  const productsIndex = getIndex(algoliaClient, 'Products');

  await loadExperiences({ skipLoadedExperiences: !!critical.data.choicesByName });
  delete critical.data.choicesByName;
  await loadVariants(productsIndex);
});
if (props.prefetch)
  onServerPrefetch(async () => {
    await loadExperiences();
    critical.data.choicesByName = choicesByName;
  });
</script>

<script lang="ts">
// eslint-disable-next-line import/first, import/no-duplicates
import { spacingInputRegistration } from '@/composables/useSpacing';
// eslint-disable-next-line import/first, import/no-duplicates
import { BuilderComponent, TWColorsText } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const ProductRecommendationsWithOptionsRegistration: BuilderComponent = {
  name: 'ProductRecommendationsWithOptions',
  inputs: [
    {
      name: 'controlsColor',
      friendlyName: '(Optional) Controls Color',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
    {
      name: 'enableAddToCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'selector',
      friendlyName: 'Experience Selector',
      type: 'string',
      defaultValue: '[TEST] Default Recommendations',
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Recommendations',
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: '(Optional) Recommendations Subtitle',
    },
    ...spacingInputRegistration,
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      name: 'prefetch',
      type: 'boolean',
      helperText:
        'Load prominent recommendations at the top of the page; otherwise loads after page is rendered',
    },
    {
      advanced: true,
      defaultValue: true,
      friendlyName: 'Mobile: flush with right',
      helperText: 'Scroll entries to be flush with the right side of mobile devices',
      name: 'flushRightOnMobile',
      type: 'boolean',
    },
    {
      name: 'analyticsList',
      friendlyName: 'Analytics: List Name',
      type: 'string',
      helperText:
        'Helps filter clicks/impressions in reporting; e.g. "GLP Seasonal Recommendations"',
      advanced: true,
    },
    {
      name: 'trackingEventLocation',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "PDP"',
      advanced: true,
    },
  ],
};
</script>

<template>
  <div
    v-if="productsList?.length"
    class="flex items-end"
    :class="spacing"
    data-test="recommendations-with-options"
  >
    <Slider
      :controlsColor="controlsColor"
      :flushRightOnMobile="flushRightOnMobile"
      justifyContent="start"
      :link="link"
      :list="productsList"
      :responsiveGaps="{ mobile: 8, tablet: 12 }"
      :scrollButtonsOnSides="scrollButtonsOnSides"
      :subtitle="subtitle"
      :title="title"
      useResponsiveGaps
    >
      <ProductCardWithOptions
        v-for="(slot, index) in productsList"
        :key="slot.sku"
        :analyticsList="analyticsList"
        compareSkus
        :decisionId="choice?.decisionId"
        :enableAddToCart="enableAddToCart"
        :impressionTrackable="impressionTrackable"
        :listSourceAttribution
        :position="index"
        :product="ProductCard.fromDY(slot)"
        showVariants
        :slotId="slot.slotId"
        :trackingEventLocation="trackingEventLocation"
        :variants="getVariantsByProductKey(slot.productData.product_key)"
      />
    </Slider>
  </div>
</template>
