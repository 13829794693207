<script setup lang="ts">
import { ProposedDelivery, Subscription } from '@nuts/auto-delivery-sdk';
import dayjs from 'dayjs';
import { ref } from 'vue';
import { useStore } from 'vuex';

import RecommendationsActionBlock from '@/components/auto-delivery/added-to-auto-delivery/RecommendationsActionBlock.vue';
import SubscriptionInformation from '@/components/auto-delivery/added-to-auto-delivery/SubscriptionInformation.vue';
import Modal from '@/components/base/layout/Modal.vue';
import CloseModalButton from '@/components/base/layout/modal/CloseModalButton.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ToastNotification from '@/components/base/ToastNotification.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import ShippingMessage from '@/components/cart/ShippingMessage.vue';
import ProductAddedBanner from '@/components/product-added/ProductAddedBanner.vue';
import ProductAddedContainer from '@/components/product-added/ProductAddedContainer.vue';
import ProductAddedHeader from '@/components/product-added/ProductAddedHeader.vue';
import ProductAddedRecommendations from '@/components/product-added/ProductAddedRecommendations.vue';
import { useDelivery } from '@/composables/useDelivery';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import money from '@/filters/money';
import { getAutoDeliveryDetailsPath } from '@/utils/autoDelivery';

defineProps<{
  delivery?: ProposedDelivery;
  isOpen: boolean;
  subscription?: Subscription;
}>();
const emit = defineEmits<{ 'handle-close': [] }>();

const notificationMessage = ref<string>();

const store = useStore();

const { freeShippingThreshold } = useDelivery(store);

const { flags } = useFeatureFlags();

function getDataPromo(name: string) {
  return {
    'data-promo': '1',
    'data-promo-creative': 'Added to auto-delivery modal',
    'data-promo-name': name,
  };
}

function handleClose() {
  emit('handle-close');
}
</script>

<template>
  <Modal
    anchor="right"
    aria-label="Added to auto delivery"
    data-test="added-to-auto-delivery-modal"
    :hasControls="false"
    :hasDefaultPadding="false"
    :isOpen
    width="md:max-w-xl"
    @handle-close="handleClose"
  >
    <template #close-button>
      <CloseModalButton
        class="absolute right-5 top-11 md:top-12"
        v-bind="getDataPromo('Closed by X')"
        @click="handleClose"
      />
    </template>
    <template v-if="delivery && subscription" #body>
      <ProductAddedContainer data-test="modal-content">
        <template #header>
          <ProductAddedBanner>
            Will ship <b>{{ dayjs(delivery.shipOn).format('dddd, MMM D') }}</b>
          </ProductAddedBanner>
          <div class="relative flex items-center justify-center w-full md:fixed">
            <ToastNotification
              class="md:right-8 md:w-full md:max-w-lg md:top-8"
              :centeredOnViewport="false"
              :show="!!notificationMessage"
              @hide="notificationMessage = ''"
            >
              <SmallBodyText class="center-block">{{ notificationMessage }}</SmallBodyText>
            </ToastNotification>
          </div>
          <ProductAddedHeader theme="blue">Added to Auto-Delivery</ProductAddedHeader>
        </template>

        <template #body>
          <SubscriptionInformation class="px-4" :delivery :subscription />

          <ShippingMessage
            class="mt-4 text-center"
            :class="{ 'p-4': flags.cartLayoutUpdates }"
            :freeShippingThreshold
            hideTooltip
            stackVertically
            :totalPrice="delivery.totals.subtotal"
          >
            <template #message="{ qualified, remaining }">
              <SmallBodyText v-if="qualified">
                YAY! You qualify for <b>FREE</b> shipping on your next Auto-Delivery Order!
              </SmallBodyText>
              <SmallBodyText v-else>
                Add <b>{{ money(remaining) }} more</b> for <b>FREE</b> shipping on your next
                Auto-Delivery Order!
              </SmallBodyText>
            </template>
          </ShippingMessage>

          <div class="flex flex-col gap-2 px-4 my-4 md:gap-3 md:hidden">
            <ThemedButton
              class="h-11"
              fullWidth
              theme="gray"
              v-bind="getDataPromo('Continue shopping')"
              @click="handleClose"
            >
              Continue Shopping
            </ThemedButton>
            <RouterLink
              class="w-full hover:no-underline focus:no-underline"
              :to="getAutoDeliveryDetailsPath(delivery)"
              v-bind="getDataPromo('Manage auto-delivery')"
            >
              <ThemedButton class="h-11" fullWidth tabindex="-1" theme="white" @click="handleClose">
                Manage Auto-Delivery
              </ThemedButton>
            </RouterLink>
          </div>

          <ProductAddedRecommendations
            dySelector="[CONFIG] Added to Auto-Delivery Modal - Rows"
            :dyPageContext="{
              type: 'PRODUCT',
              data: delivery.subscriptions.map((sub) => `0${sub.sku}`),
            }"
            pageSection="Added to Auto-Delivery Modal"
            @handle-close="handleClose"
          >
            <template #button="{ product }">
              <RecommendationsActionBlock
                class="mt-auto md:mt-0"
                :delivery
                :mode="subscription.interval ? 'recurring' : 'one-time'"
                :product
                @added="
                  notificationMessage = `${product.name} has been set to begin Auto-delivery.`
                "
                @removed="
                  notificationMessage = `${product.name} has been removed from your Auto-delivery order.`
                "
              />
            </template>
          </ProductAddedRecommendations>
        </template>

        <template #footer>
          <div class="flex w-full gap-3">
            <RouterLink
              class="hover:no-underline focus:no-underline"
              :to="getAutoDeliveryDetailsPath(delivery)"
              v-bind="getDataPromo('Manage auto-delivery')"
            >
              <ThemedButton class="w-auto h-12" tabindex="-1" theme="white">
                Manage Auto-Delivery
              </ThemedButton>
            </RouterLink>
            <ThemedButton
              class="w-auto h-12 grow"
              theme="gray"
              v-bind="getDataPromo('Continue shopping')"
              @click="handleClose"
            >
              Continue Shopping
            </ThemedButton>
          </div>
        </template>
      </ProductAddedContainer>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
:deep(.recommendations-slide) {
  min-width: 12rem;
}
</style>
