import { computed, reactive, ref, watch } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import { ChooseParams } from '@/api/dynamic-yield/experiences';
import { deriveDyContext } from '@/router/createRouter';
import { useCustomer } from '@/stores/customer';
import { useInitialRequest } from '@/stores/initialRequest';
import { useSession } from '@/stores/session';

/**
 * Vue-Router definition of client-side History state
 *
 * Unfortunately not exported by the library, so we have to define it ourselves. Seems to have no
 * effect when declared in our `index.d.ts` file, so we have to cast it here.
 * @see https://github.com/vuejs/router/blob/v4.2.5/packages/router/src/history/html5.ts#L24-L31
 */
export interface StateEntry {
  back: string | null;
  current: string;
  forward: string | null;
  position: number;
  replaced: boolean;
  scroll: { left: number; top: number } | null;
}

function deriveInitialReferer(referer?: string) {
  let previousPage = referer;
  if (typeof window !== 'undefined' && window.history.state) {
    const { back, forward } = window.history.state as StateEntry;
    if (back) previousPage = back;
    if (forward) previousPage = forward;
  }
  return previousPage;
}

function derivePageContext(route?: RouteLocationNormalized): ChooseParams['pageContext'] {
  if (!route) return { data: [], type: 'OTHER' };
  return deriveDyContext(route);
}

export const useDyRequestContext = (
  route?: RouteLocationNormalized,
  syncContextWithRoute?: boolean,
) => {
  const initialRequest = useInitialRequest();
  const { origin } = new URL(initialRequest.url);
  const location = computed(() => {
    if (typeof window === 'undefined' || !route) {
      return initialRequest.url;
    }
    return `${origin}${route.fullPath}`;
  });

  const referer = ref(deriveInitialReferer(initialRequest.referer));
  const normalizedReferer = computed(() => {
    let previousPage = referer.value;
    if (previousPage && !previousPage.startsWith('http')) {
      previousPage = `${origin}${previousPage}`;
    }
    return previousPage;
  });

  const additionalCustomAttributes = ref<ChooseParams['additionalCustomAttributes']>();
  const pageContext = reactive(derivePageContext(route));
  if (syncContextWithRoute) {
    watch(
      () => route?.path,
      (_, previousPath) => {
        const { data, type } = derivePageContext(route);
        pageContext.data = data;
        pageContext.type = type;
        referer.value = previousPath;
      },
    );
  }

  const customerStore = useCustomer();
  const sessionStore = useSession();
  const chooseParams = computed<ChooseParams>(() => ({
    additionalCustomAttributes: additionalCustomAttributes.value,
    customerContext: {
      businessIndustry: customerStore.businessIndustry,
      hasBusinessAccount: customerStore.hasBusinessAccount,
      isB2bContact: customerStore.contact?.isB2b ?? false,
      userHasBusinessAccount: sessionStore.userHasBusinessAccount ?? false,
    },
    initialRequest,
    location: location.value,
    newSession: sessionStore.newDySession,
    pageContext,
    referrer: normalizedReferer.value,
  }));

  return {
    additionalCustomAttributes,
    chooseParams,
    pageContext,
  };
};
