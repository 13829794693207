import { Router, useRoute } from 'vue-router';

export const useRouteChange = (router?: Router) => {
  const isSpa = !!router;

  const navigateTo = async (path: string) => {
    if (isSpa) {
      return router.push(path);
    }
    return window.location.assign(path);
  };

  return {
    navigateTo,
    route: isSpa ? useRoute() : undefined,
  };
};
