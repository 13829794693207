import { MaybeRefOrGetter, toValue } from 'vue';
import { Router } from 'vue-router';

import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { sendProductsSearchedEvent } from '@/utils/analytics/rudderstack';

export function useSearch(router?: Router) {
  const { navigateTo } = useRouteChange(router);

  async function handleSearch(query: MaybeRefOrGetter<string | undefined>, sendEvent = true) {
    const value = toValue(query);
    let href = '/search/instant';
    if (value) {
      if (sendEvent) sendProductsSearchedEvent(value);
      href += `?query=${value}`;
    }
    await navigateTo(href);
  }

  return { handleSearch };
}
