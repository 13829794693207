<script setup lang="ts">
import { email, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { ref } from 'vue';

import { subscribe } from '@/api/contacts';
import FormInput from '@/components/base/form/FormInput.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ToastNotification from '@/components/base/ToastNotification.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useCallback } from '@/composables/useCallback';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';
import { useCustomer } from '@/stores/customer';

const subscribed = ref(false);
const notificationMessage = ref<string>();
const emailInput = ref(useCustomer().customer?.email ?? '');
const firstNameInput = ref(useCustomer().customer?.firstName ?? '');
const { errorMessages, validatorFailed } = useForm();

const rules: Record<'email', FlexibleMessageValidator<string>> = {
  email: (value) => {
    if (!required(value)) return errorMessages.email.required;
    if (!email(value)) return errorMessages.email.email;
    return true;
  },
};

const onSubmit = useCallback(async () => {
  try {
    await subscribe(emailInput.value, firstNameInput.value);
    notificationMessage.value =
      'Thank you for subscribing! Stay tuned for exciting updates and exclusive content!';
  } catch (error) {
    notificationMessage.value = 'Oops... Something went wrong. Please try again.';
  }
});
</script>

<template>
  <div>
    <Form
      v-if="!subscribed"
      v-slot="{ meta: formEmailMeta }"
      @submit="onSubmit.execute()"
      class="grid gap-5"
    >
      <FormInput
        v-model="emailInput"
        aria-label="email"
        dataTest="footer-subscribe"
        class="lg:w-full"
        :inputAttributes="{
          autocomplete: 'email',
          name: 'email',
          placeholder: 'Your Email',
          type: 'email',
        }"
        showLabel
        showPlaceholder
        theme="dark"
        :validator="rules.email"
      />
      <FormInput
        v-if="emailInput"
        v-model="firstNameInput"
        aria-label="firt name"
        dataTest="footer-subscribe"
        class="lg:w-full"
        :inputAttributes="{
          autocomplete: 'name',
          name: 'name',
          placeholder: 'Your Name (optional)',
          type: 'text',
        }"
        showLabel
        showPlaceholder
      />
      <ThemedButton
        class="w-full h-12"
        data-test="footer-subscribe-button"
        :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
        :isLoading="onSubmit.isPending"
        size="default"
        theme="dark-yellow"
        type="submit"
      >
        Subscribe
      </ThemedButton>
      <ToastNotification :show="!!notificationMessage" @hide="notificationMessage = ''">
        <SmallBodyText>{{ notificationMessage }}</SmallBodyText>
      </ToastNotification>
    </Form>
    <BaseBodyText v-if="emailInput" class="mt-3 font-semibold text-stone-200">
      We hate junk mail and spam just like you and will never give your email address to anyone. You
      can unsubscribe at any time.
    </BaseBodyText>
  </div>
</template>
