<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import SearchBarStaticContent from '@/components/layout/header/SearchBarStaticContent.vue';
import { useSearch } from '@/composables/useSearch';
import { CmsRegisteredComponent } from '@/utils/cms';

const SearchMobile = defineComponent({
  name: 'SearchMobile',
  components: {
    SearchBarStaticContent,
  },
  setup() {
    const router = useRouter();

    const searchQuery = ref('');

    const search = async () => {
      await useSearch(router).handleSearch(searchQuery);
    };

    return {
      search,
      searchQuery,
    };
  },
});

export const SearchMobileRegistration: CmsRegisteredComponent = {
  component: SearchMobile,
  name: 'Mobile Search Bar',
};

export default SearchMobile;
</script>

<template>
  <div class="visible-xs section masthead-alternative">
    <div class="ptn-search-box">
      <form class="relative ws-sm:static" @submit.prevent="search">
        <input
          type="search"
          class="box-border w-full h-10 p-3 border border-solid rounded search-field"
          data-test="search-field-mobile"
          placeholder="Search for a product"
          name="query"
          title="Search"
          aria-label="Search"
          v-model="searchQuery"
        />
        <SearchBarStaticContent />
      </form>
    </div>
  </div>
</template>
