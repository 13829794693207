import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import pick from 'lodash/pick';

import { productListViewed, ProductListViewedProductsItem } from '@/rudder-typer';
import { gtag } from '@/utils/analytics';
import { PageSection } from '@/utils/analytics/PageSection';
import { Money } from '@/utils/money';
import { reportError } from '@/utils/reportError';

export interface NutsViewItemListItem
  extends Omit<ProductListViewedProductsItem, 'cost' | 'price'> {
  cost?: Money;
  discount?: Money;
  indexName?: string;
  price: Money;
  priceBeforeDiscount?: Money;
  searchQueryID?: string;
  weight?: string;
}

export interface ProductListViewedEventData {
  listItems: NutsViewItemListItem[];
  pageSection?: PageSection<
    | 'Above-the-Fold Recommendations'
    | 'Added to Cart Modal'
    | 'Buy Block'
    | 'Product List'
    | 'Product Recommendations'
  >;
}

interface GTagViewItemListPayload {
  autodelivery_interval: undefined;
  cost?: string;
  coupon?: string;
  discount?: number;
  index: number;
  indexName?: string;
  item_id: string;
  item_list_name: string;
  item_name: string;
  item_variant: string;
  item_variant_name?: string;
  price: number;
  price_before_discount?: number;
  quantity: number;
  reporting_category?: string;
  searchQueryID?: string;
  weight?: string;
}

export function isListPageSection(
  pageSection?: string,
): pageSection is ProductListViewedEventData['pageSection'] {
  if (!pageSection) return true;
  return [
    'Above-the-Fold Recommendations',
    'Added to Cart Modal',
    'Buy Block',
    'Product List',
    'Product Recommendations',
  ].includes(pageSection);
}

export function formatGTagViewItemListPayload(
  itemListName: string,
  item: NutsViewItemListItem,
): GTagViewItemListPayload {
  return {
    autodelivery_interval: undefined,
    cost: String(dollars(item.cost)),
    coupon: item.coupon,
    discount: dollars(item.discount),
    index: item.position,
    indexName: item.indexName,
    item_id: item.product_id,
    item_list_name: itemListName,
    item_name: item.name,
    item_variant: item.sku,
    item_variant_name: item.variant,
    price: dollars(item.price),
    price_before_discount: dollars(item.priceBeforeDiscount),
    quantity: item.quantity,
    reporting_category: item.reporting_category,
    searchQueryID: item.searchQueryID,
    weight: item.weight,
  };
}
function pickProductListViewedProductsItem(
  nutsAddToCartItem: NutsViewItemListItem,
): ProductListViewedProductsItem {
  const properties = pick(nutsAddToCartItem, [
    'brand',
    'coupon',
    'name',
    'position',
    'price',
    'product_id',
    'quantity',
    'reporting_category',
    'sku',
    'variant',
  ]);
  return {
    ...properties,
    price: dollars(nutsAddToCartItem.price),
  };
}

function sendGtagEvent(listName: string, listItems: NutsViewItemListItem[]) {
  const items = listItems.map((item) => formatGTagViewItemListPayload(listName, item));
  gtag('event', 'view_item_list', {
    item_list_name: listName,
    items,
  });
}

function sendProductListViewed(
  listName: string,
  { listItems, pageSection }: ProductListViewedEventData,
) {
  try {
    const products = listItems.map(pickProductListViewedProductsItem);
    productListViewed({
      list_id: listName,
      page_section: pageSection,
      products,
    });
  } catch (e) {
    reportError(e);
  }
}

const queueByItemListName = new Map<string, ProductListViewedEventData>();
let viewItemListTimeout: ReturnType<typeof setTimeout> | undefined;

export function sendProductListViewedEvent() {
  if (viewItemListTimeout) {
    clearTimeout(viewItemListTimeout);
    viewItemListTimeout = undefined;
  }

  queueByItemListName.forEach((eventData, listName) => {
    sendGtagEvent(listName, eventData.listItems);
    sendProductListViewed(listName, eventData);
  });
  queueByItemListName.clear();
}

if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', sendProductListViewedEvent, { once: true });
}

export const pushProductListViewed = (
  listName: string,
  item: NutsViewItemListItem,
  pageSection?: ProductListViewedEventData['pageSection'],
) => {
  const entry = queueByItemListName.get(listName);
  if (entry) {
    entry.listItems.push(item);
  } else {
    queueByItemListName.set(listName, { listItems: [item], pageSection });
  }

  if (viewItemListTimeout) {
    clearTimeout(viewItemListTimeout);
  }
  viewItemListTimeout = setTimeout(sendProductListViewedEvent, 2000);
};
