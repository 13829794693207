import { Order, TypedMoney } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import mapValues from 'lodash/mapValues';

import type { PaymentItem } from '@/composables/usePayment';
import { NutsLineItem } from '@/lib/cart/lineItem';
import { OrderPlaced, orderPlaced } from '@/rudder-typer';
import { formatStandardProductsItem } from '@/utils/analytics/rudderstack';
import { isGiftLineItem } from '@/utils/cart';
import { Money } from '@/utils/money';

export interface OrderPlacedPayload {
  isFirstOrder?: boolean;
  order: Order;
  orderCount?: number;
  paymentItems: PaymentItem[];
  paymentMethod?: string;
}

export function sendOrderPlacedEvent({
  isFirstOrder,
  order,
  orderCount,
  paymentItems,
  paymentMethod,
}: OrderPlacedPayload): void {
  orderPlaced(formatOrderPlaced({ isFirstOrder, order, orderCount, paymentItems, paymentMethod }));
}

export function formatOrderPlaced(context: OrderPlacedPayload): OrderPlaced {
  const { order } = context;

  // augment (Nuts)LineItems with extendedListPrice for convenience
  const lineItems: (NutsLineItem & { extendedListPrice: Money })[] = order.lineItems.map((li) => ({
    ...NutsLineItem.fromCt(li),
    extendedListPrice: Money.multiply(
      li.variant.prices?.find((p) => !p.channel)?.value ?? li.price.value,
      li.quantity,
    ),
  }));

  // key payment items amounts by label
  const paymentItems = Object.fromEntries(
    context.paymentItems.map(({ label, amount }) => [label, amount]),
  ) as Record<PaymentItem['label'], TypedMoney | undefined>;

  const coupon = order.discountCodes?.[0]?.discountCode.obj?.code;

  const digitalGiftAdjustment = Money.sumBy(
    order.customLineItems.filter((cli) => cli.slug.match(/GIFT_ADJ_.*-amount/)),
    (cli) => cli.totalPrice,
  );

  const giftWithPurchase = Money.sumBy(
    lineItems.filter(isGiftLineItem),
    (li) => li.extendedListPrice,
  );

  const lineLevelSavings = Money.sumBy(lineItems, (li) => li.totalSavings?.value);

  const dollarAmounts = {
    discount: Money.negate(
      Money.sum([
        digitalGiftAdjustment,
        paymentItems.Adjustment,
        paymentItems.Discount,
        Money.negate(giftWithPurchase),
        Money.negate(lineLevelSavings),
      ]),
    ),
    revenue: Money.sumBy(lineItems, (li) => li.extendedListPrice),
    shipping:
      paymentItems.Shipping &&
      Money.sum([paymentItems['Heat-Resistant Packaging'], paymentItems.Shipping]),
    subtotal: Money.sum([
      digitalGiftAdjustment,
      paymentItems.Adjustment,
      paymentItems.Discount,
      paymentItems.Subtotal,
    ]),
    tax: Money.sum([paymentItems.Duties, paymentItems['GST/HST'], paymentItems.Tax]),
    total: order.taxedPrice?.totalGross,
  };

  return {
    checkout_id: order.cart?.id,
    coupon,
    currency: 'USD',
    is_first_order: context.isFirstOrder,
    order_id: order.orderNumber!, // We are confident that placeOrder always sets this
    order_count: context.orderCount,
    payment_method: context.paymentMethod,
    products: lineItems.map(formatStandardProductsItem),
    ...mapValues(dollarAmounts, (amount) => (amount ? dollars(amount) : undefined)),
  };
}
