import {
  Cart,
  CartAddItemShippingAddressAction,
  CartUpdateAction,
  ItemShippingDetailsDraft,
  Order,
  Product,
} from '@commercetools/platform-sdk';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import createDebug from 'debug';
import { compact } from 'lodash';
import omit from 'lodash/omit';
import { storeToRefs } from 'pinia';
import { computed, Ref, ref } from 'vue';
import { Store } from 'vuex';

import { FromNutsJsonOptions, KeyedError, webstore } from '@/api';
import { NewCustomer } from '@/api/customer';
import { clearCopiedOrder } from '@/api/order';
import { placeOrder, PlaceOrderRequest } from '@/api/placeOrder';
import productsByKeysAndAttributes from '@/api/productsByKeysAndAttributes';
import { createApi, getOrderByToken } from '@/api/proxiedCommercetools';
import { getRestrictedCountrySkus, RestrictedCountrySkusItem } from '@/api/restrictedCountrySkus';
import { useAuth } from '@/composables/useAuth';
import { useCart } from '@/composables/useCart';
import { useDelivery } from '@/composables/useDelivery';
import { useState } from '@/composables/useState';
import { NutsLineItem, volumetricIngredients } from '@/lib/cart/lineItem';
import { MIX_PRODUCT_KEY } from '@/lib/customizers/mixes';
import {
  LARGE_CUSTOM_TRAY_PRODUCT_KEY,
  MEDIUM_CUSTOM_TRAY_PRODUCT_KEY,
} from '@/lib/customizers/trays';
import { useCustomer } from '@/stores/customer';
import { Notification, useNotifications } from '@/stores/notifications';
import { NutsAddress } from '@/utils/address';
import { CheckoutEvents } from '@/utils/analytics';
import {
  buildPresetShipmentActions,
  buildRemoveItemShippingAddressCustomTypeAction,
  buildRemoveItemShippingAddressPhysicalAddressFieldsAction,
  buildSetShippingAddressActions,
  buildSetSignedTrustedMatchkeyItemShippingAddressAction,
  buildUpdateSignedTrustedMatchkeyItemShippingAddressAction,
  isGiftCertificateLineItem,
  isPresetDelivery,
  isPresetDeliveryLineItem,
  lineItemAndChildren,
  needEmailAddress,
  needPhysicalAddress,
  Shipment,
} from '@/utils/cart';
import { requiresCustomizer } from '@/utils/customizers';
import { NutsRedirect } from '@/utils/exceptions';
import { getCookie } from '@/utils/isomorphic/cookie';
import { isActive, isAvailableInMixes, isAvailableInTrays, isInStock } from '@/utils/product';
import { reportError } from '@/utils/reportError';

// Temporarily add field that is missing from package
declare module '@commercetools/platform-sdk' {
  interface CartAddCustomLineItemAction {
    shippingDetails?: ItemShippingDetailsDraft;
  }
}

export interface BaseStep {
  complete: boolean;
  enabled: boolean;
  key: 'address' | 'delivery' | 'payment';
  title: string;
}

const debug = createDebug('nuts:useCheckout');

export function useCheckout(store: Store<any>, alternativeCart?: Ref<Cart | undefined>) {
  const { addAddress, loadCreditAccounts, loadCustomerInfo, setCustomerInfo } = useCustomer();
  const { contact } = storeToRefs(useCustomer());
  const { cxMode, isB2B, restrictedCountrySkus } = useState<{
    cxMode: boolean;
    isB2B: boolean;
    restrictedCountrySkus: RestrictedCountrySkusItem;
  }>('checkout', () => ({
    cxMode: true,
    isB2B: contact.value?.isB2b ?? false,
    restrictedCountrySkus: {},
  }));

  const order = ref<Order>();

  const altCart = alternativeCart ?? ref<Cart | undefined>();
  const orderOrAlternativeCart = computed({
    get() {
      return order.value ?? alternativeCart?.value;
    },
    set(value: Order | Cart | undefined) {
      if (value && 'orderState' in value) {
        order.value = value;
      } else {
        altCart.value = value;
      }
    },
  });

  const { createCustomer, permissions } = useAuth(store);
  const {
    cartCopiedFromOrder,
    createdFromOrderUpload,
    customerEmail,
    customLineItems,
    loadCart,
    loadImagesForNonPrimaryVariants,
    loadSafcToken,
    giftCertificate,
    lineItems,
    cart,
    safcToken,
    setCopiedFromOrderNumber,
    setGiftCertificate,
    setCart,
    shippingAddress,
    unassignedLineItems,
    updateCart,
  } = useCart(store, orderOrAlternativeCart);
  const { addresses, customer, storeCredit } = storeToRefs(useCustomer());
  const { hasMultipleShipments, isPickup, shipments, standardShipments } = useDelivery(
    store,
    orderOrAlternativeCart,
  );
  const { addNotifications } = useNotifications();

  if (cxMode.value && !permissions.value.checkOutAsCustomer) {
    cxMode.value = false;
  }

  // completion indicators
  const addressComplete = computed<boolean>(() => {
    if (!shipments.value.length || unassignedLineItems.value.length) return false;
    if (isPickup.value) return true;
    const countriesBySku = Object.entries(restrictedCountrySkus.value);
    return standardShipments.value.every((shipment) => {
      if (shipment.needEmailAddress) {
        if (!shipment.address.email) return false;
        if (!shipment.needPhysicalAddress) return true;
      }
      if (shipment.needPhysicalAddress) {
        const shipmentSkus = shipment.lineItems.map((l) => l.variant.sku);
        if (
          countriesBySku.some(
            ([sku, restrictedCountries]) =>
              shipmentSkus.includes(sku) && restrictedCountries.includes(shipment.address.country),
          )
        ) {
          return false;
        }
        return !!shipment.address.street1;
      }
      debug('shipment not flagged as `needEmailAddress` or `needPhysicalAddress`: %o', shipment);
      return !!(shipment.address.street1 || shipment.address.email);
    });
  });
  const deliveryMethodComplete = computed<boolean>(
    () =>
      shipments.value.length > 0 &&
      shipments.value.filter((s) => s.needPhysicalAddress).every((s) => !!s.customFields),
  );

  const steps = computed<BaseStep[]>(() => {
    const baseSteps: BaseStep[] = [
      {
        complete: addressComplete.value,
        enabled: true,
        key: 'address',
        title: 'Email & Shipping Info',
      },
      {
        complete: deliveryMethodComplete.value,
        enabled: addressComplete.value,
        key: 'delivery',
        title: 'Expected Delivery',
      },
      {
        complete: false,
        enabled: addressComplete.value && deliveryMethodComplete.value,
        key: 'payment',
        title: 'Payment Method',
      },
    ];
    if (!isPresetDelivery(lineItems.value)) return baseSteps;
    return baseSteps.filter((step) => {
      if (step.key === 'address') return !customer.value;
      if (step.key === 'delivery') return false;
      return true;
    });
  });

  const resetCheckout = async (resetCustomer = false) => {
    await updateCart(() => {
      const actions: CartUpdateAction[] = [];

      const targetedLineItems = lineItems.value.filter(
        (l) => !isPresetDeliveryLineItem(l) && l.shippingDetails?.targets.length,
      );
      actions.push(
        ...targetedLineItems.map(
          (lineItem): CartUpdateAction => ({
            action: 'setLineItemShippingDetails',
            lineItemId: lineItem.id,
            shippingDetails: {
              targets: [],
            },
          }),
        ),
      );
      const flattenedChildLineItems = targetedLineItems.flatMap((parent) => parent.children ?? []);
      actions.push(
        ...flattenedChildLineItems.map(
          (childLineItem: NutsLineItem): CartUpdateAction => ({
            action: 'setLineItemShippingDetails',
            lineItemId: childLineItem.id,
            shippingDetails: {
              targets: [],
            },
          }),
        ),
      );

      actions.push(
        ...customLineItems.value.map(
          (lineItem): CartUpdateAction => ({
            action: 'removeCustomLineItem',
            customLineItemId: lineItem.id,
          }),
        ),
      );

      actions.push(
        ...standardShipments.value.map(
          (shipment): CartUpdateAction => ({
            action: 'removeItemShippingAddress',
            addressKey: shipment.key,
          }),
        ),
      );

      if (shippingAddress.value && standardShipments.value.length) {
        if (cart.value?.shippingInfo) {
          actions.push({ action: 'setShippingMethod' });
        }
        // also unsets taxes
        actions.push({ action: 'setShippingAddress' });
      } else if (cart.value?.shippingMode === 'Multiple') {
        actions.push(
          ...standardShipments.value.map(
            (shipment): CartUpdateAction => ({
              action: 'removeShippingMethod',
              shippingKey: shipment.key,
            }),
          ),
        );
      }

      if (resetCustomer) {
        actions.push({ action: 'setCustomerEmail', email: undefined as unknown as string });
      }

      if (cart.value?.custom?.fields.orderTags) {
        actions.push({ action: 'setCustomField', name: 'orderTags' });
      }

      return actions;
    });
  };

  const toggleCXMode = () => {
    cxMode.value = !cxMode.value;
  };

  const tryCreateCustomerOrSignIn = async (
    customerToAdd: Omit<NewCustomer, 'discardCustomerCart'> & {
      onMessages?: Exclude<FromNutsJsonOptions['onMessages'], 'default'>;
    },
    stepName: string,
  ) => {
    const { triggerSetPasswordFlow } = customerToAdd;
    if (customer.value) return;
    if (
      !customerToAdd.email ||
      !customerToAdd.name ||
      (!customerToAdd.password && !triggerSetPasswordFlow)
    ) {
      return;
    }

    const logKeyedMessage = (action: string, key: string) =>
      CheckoutEvents.event({
        action,
        category: 'Account',
        label: `Checkout: ${stepName} - ${key}`,
      });
    const messageTap: Exclude<FromNutsJsonOptions['onMessages'], 'default'> = (messages) => {
      const keyedNotice = messages.notices?.find(({ key }) => !!key);
      if (keyedNotice) logKeyedMessage('Create', keyedNotice.key!);
      customerToAdd.onMessages?.(messages);
    };

    try {
      const newCustomer = {
        ...customerToAdd,
        discardCustomerCart: true,
        password: triggerSetPasswordFlow ? '' : customerToAdd.password,
        stepName,
        triggerSetPasswordFlow,
      };
      await createCustomer(newCustomer, messageTap);
    } catch (error) {
      reportError(error);
      if (error instanceof KeyedError) {
        const { key, message } = error;
        addNotifications({ warnings: [{ key, message }] });
        logKeyedMessage('Creation Failure', key);
      }
    }

    const addressToAdd =
      cart.value && !hasMultipleShipments.value && standardShipments.value[0]?.address;
    if (customer.value && addressToAdd && !cxMode.value) {
      try {
        await addAddress(addressToAdd);
      } catch (error) {
        reportError(error);
      }
    }
  };

  const isDigitalGiftRedemption = computed(() =>
    customLineItems.value.find((item) => item.slug.endsWith('-amount')),
  );

  const getCustomizersContent = async (customizerLineItems: NutsLineItem[]) => {
    const productByKeysPromises = customizerLineItems.map((li) => {
      const contents = volumetricIngredients(li);
      const volumetricChildrenKeys = Object.keys(contents ?? {});
      return productsByKeysAndAttributes(volumetricChildrenKeys, volumetricChildrenKeys.length);
    });
    return compact(await Promise.all(productByKeysPromises)).flatMap((products) => products);
  };

  const nonPurchaseableCustomizersContent = (products: Product[]) =>
    products.filter((product) => {
      const {
        masterData: {
          current: { masterVariant },
        },
      } = product;
      const inactive = !isActive(masterVariant);
      const backordered = !isInStock(masterVariant);
      return inactive || backordered;
    });

  const nonPurchasableForCustomMixes = (products: Product[]) =>
    products.filter((product) => !isAvailableInMixes(product.masterData.current.masterVariant));

  const nonPurchasableForCustomTrays = (products: Product[]) =>
    products.filter((product) => !isAvailableInTrays(product.masterData.current.masterVariant));

  const containsIngredients = (lineItem: NutsLineItem, ingrdientKeys: string[]) => {
    const contents = volumetricIngredients(lineItem) ?? {};
    return Object.keys(contents).filter((key) => ingrdientKeys.includes(key));
  };

  const shouldRemoveRequestedShippingDate = (shipmentItem: Shipment) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const requestedDeliveryOn = shipmentItem.customFields?.requestedDeliveryOn;
    const requestedDeliveryIsPast = requestedDeliveryOn && new Date(requestedDeliveryOn) < now;
    return !!requestedDeliveryIsPast;
  };

  const basePlaceOrder = async (payload: Omit<PlaceOrderRequest, 'cartId' | 'version'>) => {
    if (lineItems.value.some((l) => !l.shippingDetails?.valid)) {
      throw new NutsRedirect('/cart');
    }

    const customerToAdd = {
      email: customerEmail.value ?? '',
      name: payload.billingAddress?.name ?? '',
      password: payload.triggerSetPasswordFlow && cxMode.value ? '' : payload.newPassword ?? '',
      onMessages: addNotifications,
      isB2b: payload.isB2b,
      triggerSetPasswordFlow: payload.triggerSetPasswordFlow && cxMode.value,
    };

    await tryCreateCustomerOrSignIn(customerToAdd, 'Payment');

    const { id: cartId, version } = cart.value!;

    let customerId;
    if (cxMode.value) customerId = customer.value?.id ?? null;

    let { paymentNonces } = payload;
    if (giftCertificate.value?.remainingValue.centAmount) {
      paymentNonces = [giftCertificate.value.nonce, ...paymentNonces];
    }
    if (storeCredit.value?.balance.centAmount) {
      paymentNonces = [storeCredit.value.nonce, ...paymentNonces];
    }

    const { data, errors, notices, warnings } = await placeOrder({
      ...payload,
      billingAddress: payload.billingAddress ? omit(payload.billingAddress, 'email') : undefined,
      cartId,
      customerId,
      newPassword: undefined, // TODO: Remove once the lambda isn't looking for it
      paymentNonces,
      safcToken: safcToken.value,
      version,
    }).catch(async (error) => {
      reportError(error);
      if (error?.response?.status === 409) {
        console.error('CME when trying to place order; redirecting to /cart');
        console.error('local cart', cart.value);
        const ct = createApi(webstore.sessionSpecific);
        const { body } = await ct.carts().withId({ ID: cartId }).get().execute();
        console.error('latest cart', body);
        throw new NutsRedirect('/cart');
      } else {
        throw error;
      }
    });

    if ('shopPayPaymentRequestSessionSubmit' in data) {
      return data;
    }

    if ('cart' in data) {
      setCart(data.cart);
    }

    const toThrow = errors?.shift();
    addNotifications({ errors, notices, warnings });
    if (toThrow) {
      throw new Error(toThrow.message);
    }

    if ('order' in data) {
      loadCreditAccounts();
      setGiftCertificate();
      return data;
    }
    throw new Error('No order but no errors?');
  };

  return {
    addressComplete,
    cxMode,
    deliveryMethodComplete,
    isB2B,
    isDigitalGiftRedemption,
    order,
    resetCheckout,
    restrictedCountrySkus,
    steps,
    toggleCXMode,
    tryCreateCustomerOrSignIn,

    async clearCheckout() {
      setCustomerInfo();
      setCopiedFromOrderNumber(null);
      await clearCopiedOrder();
      await resetCheckout(true);
    },

    determineEntryLocation(options?: { oneClickCheckout?: boolean }) {
      if (createdFromOrderUpload.value) {
        return '/checkout/payment';
      }
      if (cxMode.value && !cartCopiedFromOrder.value) {
        return '/checkout/signin';
      }

      let step = steps.value[0].key;
      if (!customerEmail.value) {
        return `/checkout/${step}`;
      }

      if (steps.value.some((s) => s.complete)) {
        const lastCompletedStep = steps.value.filter((s) => s.complete).pop()!;
        const latestIncompleteStep = steps.value.find((s) => {
          if (!s.enabled || s.complete) return false;
          // standard shipments require the UI to actually set shipping offers, for now
          if (s.key === 'payment' && !isPresetDelivery(lineItems.value)) return false;
          return true;
        });
        step = (latestIncompleteStep ?? lastCompletedStep).key;
      }

      if (step === 'address' && hasMultipleShipments.value) {
        return `/checkout/${step}/multi`;
      }

      if (step === 'delivery' && options?.oneClickCheckout) {
        return '/checkout/payment';
      }

      return `/checkout/${step.replace('delivery', 'shipping')}`;
    },

    async loadOrder(token: string) {
      const api = createApi(webstore);
      order.value = await getOrderByToken(api, token);
    },

    async prepareCartForCheckout() {
      isB2B.value = contact.value?.isB2b ?? false;

      const promises = [loadCart()];

      if (cxMode.value) {
        if ((createdFromOrderUpload.value || cartCopiedFromOrder.value) && customer.value) {
          promises.push(loadCustomerInfo(customer.value.email));
          promises.push(loadSafcToken(customer.value.email));
        } else {
          setCustomerInfo();
        }
      } else if (permissions.value.checkOutAsCustomer || customer.value) {
        promises.push(
          // maybe session expired? but info has been cleared so we can continue
          loadCustomerInfo().catch(() => {}),
        );
      }

      await Promise.all(promises);

      if (!lineItems.value.length) {
        throw new NutsRedirect('/cart');
      }

      // No need to wait for this to finish, and doing it after the others is nicer to the server
      loadSafcToken();

      order.value = undefined;

      await loadImagesForNonPrimaryVariants();
      const actions: CartUpdateAction[] = [];
      if (customer.value) {
        actions.push({
          action: 'setCustomerEmail',
          email: customer.value.email,
        });
      }

      const defaultAddress: NutsAddress = { email: customer.value?.email ?? '', country: 'US' };
      const need = {
        emailAddress: needEmailAddress(lineItems.value),
        physicalAddress: needPhysicalAddress(lineItems.value),
        specialDelivery: lineItems.value.every(isPresetDeliveryLineItem),
      };
      const [shipment] = standardShipments.value;
      if ((need.physicalAddress || need.emailAddress) && !hasMultipleShipments.value && !shipment) {
        const address =
          need.physicalAddress && addresses.value.length ? addresses.value[0] : defaultAddress;

        const existingShipments = {
          standardShipments: standardShipments.value,
          allShipments: shipments.value,
        };
        actions.push(
          ...buildSetShippingAddressActions(
            address,
            existingShipments,
            lineItems.value.filter(
              (l) => !isPresetDeliveryLineItem(l) && !isGiftCertificateLineItem(l),
            ),
            cart.value?.shippingMode,
          ),
        );
      }

      standardShipments.value.forEach((shipmentItem) => {
        const shouldRemoveOffer = shouldRemoveRequestedShippingDate(shipmentItem);
        const address = addresses.value.find(
          (customerAddress) => customerAddress.id === shipmentItem.address.id,
        );
        if (address) {
          if (shipmentItem.address.signedTrustedMatchkey && !address.signedTrustedMatchkey) {
            if (shouldRemoveOffer) {
              actions.push(buildRemoveItemShippingAddressCustomTypeAction(shipmentItem.key));
            } else {
              actions.push(buildUpdateSignedTrustedMatchkeyItemShippingAddressAction(shipmentItem));
            }
            return;
          }
          if (address.signedTrustedMatchkey) {
            // we always need to update the matchkey if it exists, the one in the cart might be expired
            if (shouldRemoveOffer) {
              actions.push(
                buildSetSignedTrustedMatchkeyItemShippingAddressAction(
                  shipmentItem.key,
                  address.signedTrustedMatchkey,
                ),
              );
            } else {
              actions.push(
                buildUpdateSignedTrustedMatchkeyItemShippingAddressAction(
                  shipmentItem,
                  address.signedTrustedMatchkey,
                ),
              );
            }
            return;
          }
        }
        if (shouldRemoveOffer) {
          actions.push(buildRemoveItemShippingAddressCustomTypeAction(shipmentItem.key));
        }
      });

      shipments.value.forEach((ship) => {
        if (!ship.needPhysicalAddress && ship.needEmailAddress) {
          actions.push(buildRemoveItemShippingAddressPhysicalAddressFieldsAction(ship.address));
          actions.push(buildRemoveItemShippingAddressCustomTypeAction(ship.key));
        }
      });
      const addItemShippingAddressAction = (
        update: CartUpdateAction,
      ): update is CartAddItemShippingAddressAction => update.action === 'addItemShippingAddress';
      actions.push(
        ...buildPresetShipmentActions(
          unassignedLineItems.value.filter(isPresetDeliveryLineItem),
          shipments.value,
          actions
            .filter(addItemShippingAddressAction)
            .filter((a) => !!a.address.key)
            .map((a) => a.address.key!),
          cart.value?.shippingMode,
        ),
      );
      if (need.specialDelivery) {
        if (cart.value?.shippingMode === 'Single') {
          actions.push(
            {
              action: 'setShippingAddress',
              address: defaultAddress,
            },
            {
              action: 'setCustomShippingMethod',
              shippingMethodName: 'Nuts.com Shipping',
              shippingRate: {
                price: from(0),
              },
            },
          );
        }
        actions.push(
          ...standardShipments.value
            .filter((s) => !s.lineItems.length)
            .map<CartUpdateAction>((emptyShipment) => ({
              action: 'removeItemShippingAddress',
              addressKey: emptyShipment.key,
            })),
        );
        if (cart.value?.shippingMode === 'Multiple') {
          actions.push(
            ...standardShipments.value
              .filter((s) => !s.lineItems.length)
              .map<CartUpdateAction>((emptyShipment) => ({
                action: 'removeShippingMethod',
                shippingKey: emptyShipment.key,
              })),
          );
        }
      }

      const iterableEmailCampaignId = getCookie('iterableEmailCampaignId', false);
      const iterableTemplateId = getCookie('iterableTemplateId', false);

      if (iterableEmailCampaignId) {
        actions.push({
          action: 'setCustomField',
          name: 'iterableEmailCampaignId',
          value: Number(iterableEmailCampaignId),
        });
      }

      if (iterableTemplateId) {
        actions.push({
          action: 'setCustomField',
          name: 'iterableTemplateId',
          value: Number(iterableTemplateId),
        });
      }

      const existingSurchargeLineItem = customLineItems.value.find((l) => l.slug === 'surcharge');
      if (existingSurchargeLineItem) {
        actions.push({
          action: 'removeCustomLineItem',
          customLineItemId: existingSurchargeLineItem.id,
        });
      }

      if (standardShipments.value.length === 1 && unassignedLineItems.value.length) {
        unassignedLineItems.value
          .filter((l) => !isPresetDeliveryLineItem(l))
          .forEach((lineItem) => {
            actions.push({
              action: 'setLineItemShippingDetails',
              lineItemId: lineItem.id,
              shippingDetails: {
                targets: [
                  {
                    addressKey: standardShipments.value[0].key,
                    quantity: lineItem.quantity,
                  },
                ],
              },
            });
          });
      }

      let flattenedLineItems = lineItems.value.flatMap(lineItemAndChildren);
      const customizerLineItems = lineItems.value.filter((li) =>
        requiresCustomizer(li.productKey!),
      );
      const customizersContent = (await getCustomizersContent(customizerLineItems)).filter((p) => {
        const {
          masterData: {
            current: {
              masterVariant: { attributes },
            },
          },
        } = p;
        const isParent = !!attributes?.find((a) => a.name === 'externalId');
        const isChild = !!attributes?.find((a) => a.name === 'parentExternalId');
        return !isParent && !isChild;
      });

      const initializeRestrictedCountrySkus = async () => {
        const skus = [
          // standard line items including parent/children line items (flattened)
          ...flattenedLineItems.map((li) => li.variant.sku),
          // custom trays and custom mixes content
          ...customizersContent.map((p) => p.masterData.current.masterVariant.sku!),
        ];
        restrictedCountrySkus.value = await getRestrictedCountrySkus(skus);
      };
      await Promise.all([
        initializeRestrictedCountrySkus(),
        updateCart(() => [
          {
            action: 'recalculate',
            updateProductData: true,
          },
          !cart.value!.custom && {
            action: 'setCustomType',
            type: { typeId: 'type', key: 'cart' },
          },
          ...actions,
        ]),
      ]);
      flattenedLineItems = lineItems.value.flatMap(lineItemAndChildren);

      const nonPurchasableContentKeys = nonPurchaseableCustomizersContent(customizersContent).map(
        (p) => p.key!,
      );
      const nonPurchasableForCustomMixesContentKeys = nonPurchasableForCustomMixes(
        customizersContent,
      ).map((p) => p.key!);
      const nonPurchasableForCustomTraysContentKeys = nonPurchasableForCustomTrays(
        customizersContent,
      ).map((p) => p.key!);

      const nonPurchasableLines = flattenedLineItems.filter(
        (l) =>
          l.variant.backordered ||
          !l.variant.active ||
          containsIngredients(l, nonPurchasableContentKeys).length > 0 ||
          (l.productKey === MIX_PRODUCT_KEY &&
            containsIngredients(l, nonPurchasableForCustomMixesContentKeys).length > 0) ||
          ([LARGE_CUSTOM_TRAY_PRODUCT_KEY, MEDIUM_CUSTOM_TRAY_PRODUCT_KEY].includes(l.productKey) &&
            containsIngredients(l, nonPurchasableForCustomTraysContentKeys).length > 0),
      );
      if (nonPurchasableLines.length) {
        const errors: Notification[] = [];
        const message = (lineItemName: string, nonPurchasableContentNames: string[]) => {
          if (nonPurchasableContentNames.length === 0) {
            return `${lineItemName}: Sorry, this item is out of stock and has been removed from your cart.`;
          }
          const plural = nonPurchasableContentNames.length > 1;
          return `${plural ? 'The' : 'An'} ingredient${
            plural ? 's' : ''
          } (${nonPurchasableContentNames.join(', ')}) you added to the ${lineItemName} ${
            plural ? 'are' : 'is'
          } currently unavailable. Please return to the ${lineItemName} page and rebuild it using an alternative ingredient.`;
        };
        const removeActions: CartUpdateAction[] = nonPurchasableLines.flatMap((lineItem) => {
          // BYOB Child Line Item - remove it, its siblings, and its parent (the BYOB product)
          const parent = flattenedLineItems.find(
            (li) =>
              lineItem.custom?.fields.parentExternalId &&
              lineItem.custom?.fields.parentExternalId === li.custom?.fields.externalId,
          );
          if (parent) {
            errors.push({ message: message(parent.name.en, [lineItem.name.en]) });
            return lineItemAndChildren(parent).map((rli) => ({
              action: 'removeLineItem',
              lineItemId: rli.id,
            }));
          }

          // BYOB Parent Line Item - remove it and its children
          if (lineItem.custom?.fields.externalId) {
            return lineItemAndChildren(lineItem).map((rli) => ({
              action: 'removeLineItem',
              lineItemId: rli.id,
            }));
          }

          if (customizerLineItems.some((l) => l.id === lineItem.id)) {
            const nonPurchaseables = containsIngredients(lineItem, nonPurchasableContentKeys);
            if (lineItem.productKey === MIX_PRODUCT_KEY) {
              nonPurchaseables.push(
                ...containsIngredients(lineItem, nonPurchasableForCustomMixesContentKeys),
              );
            }
            if (
              [LARGE_CUSTOM_TRAY_PRODUCT_KEY, MEDIUM_CUSTOM_TRAY_PRODUCT_KEY].includes(
                lineItem.productKey,
              )
            ) {
              nonPurchaseables.push(
                ...containsIngredients(lineItem, nonPurchasableForCustomTraysContentKeys),
              );
            }
            if (nonPurchaseables.length) {
              const ingredients = volumetricIngredients(lineItem) ?? {};
              const uniqueNonPurchaseables = [...new Set(nonPurchaseables)];
              const productNames = compact(
                uniqueNonPurchaseables.map((key) =>
                  key in ingredients ? ingredients[key] : undefined,
                ),
              );
              errors.push({ message: message(lineItem.name.en, productNames) });
            }
          } else {
            errors.push({ message: message(lineItem.name.en, []) });
          }
          return {
            action: 'removeLineItem',
            lineItemId: lineItem.id,
          };
        });

        await updateCart(() => removeActions);
        throw new NutsRedirect('/cart', { errors });
      }
    },

    async placeOrder(
      payload: Omit<PlaceOrderRequest, 'cartId' | 'version' | 'shopPayAction'> & {
        shopPayAction?: Exclude<PlaceOrderRequest['shopPayAction'], 'validate-and-authorize'>;
      },
    ) {
      const response = await basePlaceOrder(payload);

      if (!('receiptToken' in response)) {
        throw new Error('receiptToken not found in response');
      }

      return response;
    },

    async purgeGiftOptions() {
      await updateCart(() => [
        ...customLineItems.value.map(
          (lineItem): CartUpdateAction => ({
            action: 'removeCustomLineItem',
            customLineItemId: lineItem.id,
          }),
        ),
      ]);
    },

    async validateAndAuthorizeShopPay(
      payload: Pick<
        PlaceOrderRequest,
        'g-recaptcha-response' | 'shopPayPlaceOrderRequest' | 'paymentNonces'
      >,
    ) {
      const response = await basePlaceOrder({
        ...payload,
        shopPayAction: 'validate-and-authorize',
      });

      if (!('shopPayPaymentRequestSessionSubmit' in response)) {
        throw new Error('Shop Pay failed to validate and authorize');
      }

      return response;
    },
  };
}
