import { Order } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { NutsLineItem } from '@/lib/cart/lineItem';

interface BasketVisitPayload {
  lines: {
    category?: string;
    id: string;
    price?: number;
    quantity: number;
  }[];
}

interface CategoryVisitPayload {
  category?: string;
  items: { id: string; oos: boolean }[];
}

interface ProductVisitPayload {
  category?: string;
  id: string;
  name?: string;
  oos: boolean;
  price: number;
}

interface AddToBagPayload {
  id: string;
  quantity: number;
}

interface AddToWishlistPayload {
  id: string;
  quantity: number;
}

export const Taggstar = {
  pushEvent(eventName: string, payload: Record<string, any>) {
    if (window.taggstar?.pushEvent) {
      window.taggstar.pushEvent(eventName, payload);
    } else {
      document.addEventListener('taggstar:pushEventReady', () => {
        Taggstar.pushEvent(eventName, payload);
      });
    }
  },

  addToWishlist(data: AddToWishlistPayload) {
    Taggstar.pushEvent('addToWishlist', data);
  },

  basketVisit(data: BasketVisitPayload) {
    Taggstar.pushEvent('basketVisit', data);
  },

  /**
   * Sent on pages that display a list of products, such as Search or PLP.
   */
  categoryVisit(data: CategoryVisitPayload) {
    Taggstar.pushEvent('categoryVisit', data);
  },

  productVisit(data: ProductVisitPayload) {
    Taggstar.pushEvent('productVisit', {
      ...data,
      currency: 'USD',
    });
  },

  addToBag(data: AddToBagPayload) {
    Taggstar.pushEvent('addToBag', data);
  },

  conversion(order: Order) {
    const lineItems = order.lineItems.map(NutsLineItem.fromCt);
    Taggstar.pushEvent('conversion', {
      currency: 'USD',
      orders: lineItems.map((item) => ({
        category: item.variant.attributes?.find(({ name }) => name === 'reportingCategory')?.value
          .key,
        id: item.productKey,
        price: dollars(item.price.value),
        quantity: item.quantity,
      })),
      purchaseId: order.orderNumber!,
      revenue: dollars(order.totalPrice),
    });
  },
};
