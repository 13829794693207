import { buttonClicked } from '@/rudder-typer';
import { PageSection } from '@/utils/analytics/PageSection';

interface ButtonClickedPayload {
  buttonName: string;
  content?: string;
  pageSection?: PageSection<'Quiz Modal'>;
}

export function sendButtonClicked({ buttonName, content, pageSection }: ButtonClickedPayload) {
  buttonClicked({
    button_name: buttonName,
    content,
    page_section: pageSection,
  });
}
