import { ApiRoot, ClientRequest, ClientResponse } from '@commercetools/platform-sdk';
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const toAxiosRequest = ({
  uri: url,
  method,
  headers,
  body: data,
}: ClientRequest): AxiosRequestConfig => ({
  url,
  headers,
  method: method as any,
  data,
});

// This attempts to resemble the error format of @commercetools/sdk-middleware-http
const fromAxiosError = (error: AxiosError) => {
  if (!error.response) {
    throw error;
  }
  const { data, headers, status, statusText } = error.response;
  throw Object.assign(new Error(statusText), {
    name: 'HttpError',
    code: status,
    status,
    statusCode: status,
    originalRequest: error.request,
    body: data,
    headers,
  });
};

const fromAxiosResponse = ({
  data: body,
  status: statusCode,
  headers,
}: AxiosResponse): ClientResponse => ({ body, statusCode, headers });

export const createApi = (webstoreSessionSpecific: AxiosInstance) =>
  new ApiRoot({
    executeRequest: async (request) =>
      fromAxiosResponse(
        await webstoreSessionSpecific.request(toAxiosRequest(request)).catch(fromAxiosError),
      ),
  }).withProjectKey({ projectKey: 'api/ct' });

export const getOrderByToken = async (api: ReturnType<typeof createApi>, token: string) => {
  const { body } = await api
    .orders()
    .withOrderNumber({ orderNumber: token }) // our proxy will verify and parse
    .get()
    .execute();
  return body;
};
