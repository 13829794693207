<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, onMounted, watchEffect } from 'vue';

import InfoIcon from '@/components/base/assets/InfoIcon.vue';
import TruckIcon from '@/components/base/assets/TruckIcon.vue';
import PopperWrapper from '@/components/base/layout/PopperWrapper.vue';
import ProgressBar from '@/components/base/ProgressBar.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import ShippingMessageToolTip from '@/components/cart/ShippingMessageToolTip.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import money from '@/filters/money';
import { Money } from '@/utils/money';

const props = defineProps<{
  freeShippingThreshold: Money;
  hideTooltip?: boolean;
  stackVertically?: boolean;
  totalPrice: Money;
}>();

const emit = defineEmits({
  'free-shipping-qualified': (_: boolean) => true,
});

const { flags } = useFeatureFlags();

const remaining = computed(() =>
  Money.max(Money.subtract(props.freeShippingThreshold, props.totalPrice), from(0)),
);

const percentage = computed(
  () => 100 - (dollars(remaining.value) / dollars(props.freeShippingThreshold)) * 100,
);

const qualified = computed(() => dollars(remaining.value) === 0);

onMounted(() => {
  watchEffect(() => {
    emit('free-shipping-qualified', qualified.value);
  });
});
</script>

<template>
  <div
    class="flex flex-col py-2 md:py-3 shipping-message-section"
    :class="{
      'px-0 ws-lg:flex-row ws-lg:gap-4 flex-wrap': !stackVertically,
      'px-4 bg-nuts-stone-100': !flags.cartLayoutUpdates,
    }"
  >
    <div
      class="flex shipping-message"
      data-test="shipping-message"
      :class="stackVertically ? 'mb-1 w-full' : 'shrink-0 justify-center pb-1 md:pb-0'"
    >
      <div class="flex items-center">
        <slot name="message" :qualified :remaining>
          <SmallBodyText v-if="qualified">
            YAY! You qualify for <strong>FREE</strong> shipping!
          </SmallBodyText>
          <SmallBodyText v-else>
            <template v-if="flags.cartLayoutUpdates">
              You are only <strong>{{ money(remaining) }}</strong> away from FREE shipping!
            </template>
            <template v-else>
              Add <strong>{{ money(remaining) }} more</strong> for FREE shipping!
            </template>
          </SmallBodyText>
        </slot>
      </div>
      <div
        v-if="!hideTooltip"
        class="flex align-middle tiny-note popover-container"
        data-test="shipping-popover-icon"
      >
        <PopperWrapper arrow class="legacy-max-width" locked offsetDistance="10" placement="bottom">
          <template #content="{ close }">
            <div class="shipping-message" data-test="shipping-message-popper">
              <a
                aria-label="Close tooltip"
                class="close-popper"
                data-test="close-popper"
                href="#"
                role="button"
                @click.prevent="close()"
              >
                ✕
              </a>
              <ShippingMessageToolTip :freeShippingThreshold />
            </div>
          </template>
          <UnstyledButton
            aria-label="shipping message details are shown in a tooltip"
            class="flex items-center justify-center w-6 h-6 p-1 shrink-0"
          >
            <InfoIcon :size="16" />
          </UnstyledButton>
        </PopperWrapper>
      </div>
    </div>
    <div class="flex items-center grow" data-test="shipping-progress-bar">
      <div
        class="w-full bg-neutral-300"
        :class="flags.cartLayoutUpdates ? 'relative h-2 rounded-l-xl' : 'h-4 rounded-xl'"
      >
        <ProgressBar
          class="h-full bg-black"
          :class="flags.cartLayoutUpdates ? 'rounded-l-xl' : 'rounded-xl'"
          :percentage
        />
      </div>
      <div
        v-if="flags.cartLayoutUpdates"
        class="relative w-6 h-6 md:w-8 md:h-8 -ml-0.5 rounded-full z-10 transition-colors duration-500"
        :class="qualified ? 'bg-black text-white delay-150' : 'bg-neutral-300 text-black'"
      >
        <TruckIcon class="absolute top-1 left-1 md:top-[6px] md:left-[6px]" :size="[16, 20]" />
      </div>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.tooltip {
  :deep(.details-content-wrapper) {
    @apply p-3;
    .popup-main {
      span.content {
        @apply text-nuts-neutral-700 text-xs;
      }
    }
  }
}

.shipping-message {
  @apply font-proxima-nova text-sm text-black z-[1];
}
</style>
