import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { ProductClicked, productClicked } from '@/rudder-typer';
import {
  formatListSourceAttribution,
  ListSourceAttribution,
} from '@/utils/analytics/listSourceAttribution';
import { PageSection } from '@/utils/analytics/PageSection';
import { ProductCardData } from '@/utils/productCard';

export type ProductClickedPageSection = PageSection<
  | 'Above-the-Fold Recommendations'
  | 'Added to Cart Modal'
  | 'Buy Block'
  | 'Product List'
  | 'Product Recommendations'
>;

interface ProductClickedPayload {
  listSourceAttribution?: ListSourceAttribution;
  pageSection?: ProductClickedPageSection;
  position?: number;
  productCard: ProductCardData;
}

export const formatProductClicked = ({
  listSourceAttribution,
  pageSection,
  position,
  productCard,
}: ProductClickedPayload): ProductClicked => ({
  ...formatListSourceAttribution(listSourceAttribution),
  coupon: productCard.totalSavings?.description?.en,
  name: productCard.name,
  page_section: pageSection,
  position,
  price: dollars(productCard.piecePrice),
  product_id: productCard.productKey,
  quantity: 1,
  reporting_category: productCard.reportingCategory,
  sku: productCard.sku,
  variant: productCard.unitName,
});

export function isProductClickedPageSection(
  pageSection?: string,
): pageSection is ProductClickedPageSection {
  if (!pageSection) return true;
  return [
    'Above-the-Fold Recommendations',
    'Added to Cart Modal',
    'Buy Block',
    'Product List',
    'Product Recommendations',
  ].includes(pageSection);
}

export const sendProductClickedEvent = (payload: ProductClickedPayload): void => {
  try {
    productClicked(formatProductClicked(payload));
  } catch (error) {
    reportError(error);
  }
};
