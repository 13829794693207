import { Pinia } from 'pinia';
import { Router } from 'vue-router';
import { Store } from 'vuex';

import { FromNutsJsonOptions } from '@/api';
import { createSurveySubmissions } from '@/api/contacts';
import { applyDiscountCodeToken as applyDiscountCodeTokenApi } from '@/api/discounts';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCart } from '@/composables/useCart';
import { USER_EMAIL_COOKIE } from '@/lib/personalization/common';
import { useNotifications } from '@/stores/notifications';
import { sendButtonClicked } from '@/utils/analytics/buttonClicked';
import { sendContactSubscribed } from '@/utils/analytics/contactSubscribed';
import { sendExperimentViewedEvent as sendExperimentViewed } from '@/utils/analytics/experimentViewedEvent';
import { sendPromotionClickedEvent as sendPromotionClicked } from '@/utils/analytics/promotionClickedEvent';
import { setCookie } from '@/utils/isomorphic/cookie';

interface Plugins {
  pinia: Pinia;
  router?: Router;
  store: Store<any>;
}

export function useWindowHooks({ pinia, router, store }: Plugins) {
  const { setCart } = useCart(store);
  const { navigateTo } = useRouteChange(router);

  async function applyDiscountCodeToken(
    token: string,
    email?: string,
    onMessages?: FromNutsJsonOptions['onMessages'],
  ): Promise<void> {
    const handleMessages = onMessages ?? useNotifications(pinia).addNotifications;
    const response = await applyDiscountCodeTokenApi(token, handleMessages);

    if (response.cart) setCart(response.cart);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (email) setCookie(USER_EMAIL_COOKIE, email);
  }

  return {
    webfront: {
      analytics: {
        sendButtonClicked,
        sendContactSubscribed,
        sendExperimentViewed,
        sendPromotionClicked,
      },
      applyDiscountCodeToken,
      createSurveySubmissions,
      navigateTo,
    },
  };
}
