<script lang="ts">
import { defineComponent } from 'vue';

import useButtonStyle, { buttonStyleProps } from '@/composables/useButtonStyles';
import { CmsRegisteredComponent } from '@/utils/cms';

const ThemedButton = defineComponent({
  name: 'ThemedButton',
  props: {
    ...buttonStyleProps,
  },
  emits: ['click'],
  setup(props) {
    const buttonStyle = useButtonStyle(props);
    return {
      ...buttonStyle,
    };
  },
});

export const ButtonRegistration: CmsRegisteredComponent = {
  component: ThemedButton,
  name: 'Button',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Button text here' } },
    },
  ],
  inputs: [
    {
      name: 'theme',
      type: 'string',
      defaultValue: 'gray',
      enum: ['green', 'gray', 'red', 'white', 'yellow', 'dark-yellow'],
    },
    {
      name: 'size',
      type: 'string',
      defaultValue: 'default',
      enum: ['small', 'default', 'large'],
    },
    {
      name: 'fullWidth',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'disabled',
      type: 'boolean',
      defaultValue: false,
    },
  ],
};

export default ThemedButton;
</script>

<template>
  <button
    class="flex items-center justify-center tracking-wide font-proxima-nova"
    :class="[
      borderUtilities,
      cursorUtilities,
      colorUtilities,
      fontSizeUtilities,
      heightUtilities,
      widthUtilities,
    ]"
    :disabled="disabled || isLoading"
    @click="$emit('click', $event)"
  >
    <img
      v-if="isLoading"
      class="mr-2 animate-spin"
      :class="{
        spinner: theme === 'white',
        'w-3 h-3': size === 'small',
        'w-3.5 h-3.5': size === 'default',
        'w-4 h-4': size === 'large',
      }"
      src="@/assets/spinner.svg"
      alt="spinner"
    />
    <slot />
  </button>
</template>

<style scoped>
img.spinner {
  filter: invert(1);
}
</style>
