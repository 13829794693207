import { Money } from '@commercetools/platform-sdk';
import { ProposedDelivery, Subscription } from '@nuts/auto-delivery-sdk';
import dayjs from 'dayjs';

import { ShippingCalculatorRequest, ShippingOffer } from '@/api/shippingCalculator';
import { TaxCalculatorRequest } from '@/api/taxCalculator';
import brokenPeanut from '@/assets/broken-peanut.png';
import { useFeatureFlags } from '@/stores/featureFlags';
import { findListingImage } from '@/utils/cart';
import { DateString } from '@/utils/dateTime';
import { ImgSize } from '@/utils/image';
import proxiedImageUtil from '@/utils/imageProxied';

export type Prices =
  | {
      listPrice: Money;
      channelPrice: Money;
      impulsePrice?: Money;
    }
  | {
      listPrice: Money;
      channelPrice?: Money;
      impulsePrice: Money;
    };

export const getAutoDeliveryOrderId = (shippingDate: string, shippingAddressId: string) =>
  `${shippingDate.replace(/-/g, '')}${shippingAddressId}`;

export const buildTaxCalculatorRequest = (
  order: ProposedDelivery,
  shippingOffer: ShippingOffer,
): TaxCalculatorRequest | null => {
  if (!order.shippingAddress) return null;

  return {
    addressKey: order.shippingAddressId,
    address: {
      city: order.shippingAddress.city,
      country: order.shippingAddress.countryCode,
      postalCode: order.shippingAddress.postalCode,
      residential: Boolean(order.shippingAddress.residential),
      state: order.shippingAddress.stateProv,
    },
    estimatedCarrierCharge: shippingOffer.shipmentPickup.estimatedCarrierCharge,
    shippingMethod: {
      carrier: shippingOffer.carrier,
      carrierCode: shippingOffer.carrierCode,
    },
    warehouse:
      shippingOffer.shipmentPickup.warehouse.toUpperCase() as TaxCalculatorRequest['warehouse'],
    shippingCharge: shippingOffer.price,
    lines: order.subscriptions.map((s) => ({
      id: s.id,
      quantity: s.quantity,
      sku: s.sku,
      subtotal: s.discountedExtendedPrice,
    })),
  };
};

export const buildShippingCalculatorRequest = (
  order: ProposedDelivery,
  { regionalCarriersAllowed = true } = {},
): ShippingCalculatorRequest | null => {
  if (!order.shippingAddress?.countryCode) return null;
  return {
    shipments: [
      {
        address: {
          city: order.shippingAddress.city,
          company: order.shippingAddress.company,
          country: order.shippingAddress.countryCode,
          postalCode: order.shippingAddress.postalCode,
          state: order.shippingAddress.stateProv,
          street1: order.shippingAddress.street1,
          street2: order.shippingAddress.street2,
        },
        key: order.shippingAddressId,
        lines: order.subscriptions.map((s) => ({
          quantity: s.quantity,
          sku: s.sku,
          weight: s.variant.weight,
        })),
        shipmentValue: order.totals.subtotal,
      },
    ],
    allowBlueStreak: Boolean(regionalCarriersAllowed),
    allowCdl: Boolean(regionalCarriersAllowed),
    allowGrandHusky: Boolean(regionalCarriersAllowed),
    allowLasership: Boolean(regionalCarriersAllowed),
    allowOntrac: Boolean(regionalCarriersAllowed),
    allowTforce: Boolean(regionalCarriersAllowed),
    allowUds: Boolean(regionalCarriersAllowed),
    requestedShipDate: dayjs(order.shipOn).format('ddd, MMM D') as DateString,
  };
};

/**
 * Sorts the subscriptions by `nextShipOn` and by `addedAt` date respectively, from newest to oldest.
 * @param {Subscription[]} subscriptions - The subscriptions to sort.
 * @returns {Subscription[]} The sorted subscriptions. It does not modify the original array.
 */
export const sortSubscriptionsByDate = (subscriptions: Subscription[]) =>
  [...subscriptions].sort((a, b) => {
    const MAX_DATE = 8640000000000000;
    const dateA = a.nextShipOn ? new Date(a.nextShipOn) : new Date(MAX_DATE);
    const dateB = b.nextShipOn ? new Date(b.nextShipOn) : new Date(MAX_DATE);

    if (dateA.getTime() !== dateB.getTime()) {
      return dateA < dateB ? -1 : 1;
    }

    const addedDateA = new Date(a.addedAt);
    const addedDateB = new Date(b.addedAt);

    return addedDateA < addedDateB ? -1 : 1;
  });

/**
 * Get the most recent subscription with the given sku
 * @param {ProposedDelivery} delivery The auto-delivery order
 * @param {string} sku The sku to find
 * @returns {Subscription | undefined} The most recent (see {@link sortSubscriptionsByDate}) subscription with the given sku, `undefined` if not found.
 */
export const getDeliverySubscriptionBySku = (delivery: ProposedDelivery, sku: string) =>
  sortSubscriptionsByDate(delivery.subscriptions).find((s) => s.sku === sku);

/**
 * Retrieves the subscription image URL in the specified size.
 * @param {Subscription} subscription - The subscription object containing image data.
 * @param {ImgSize} [size='small'] - The desired image size. Defaults to `small`.
 * @returns {string} The URL of the subscription image in the specified size, or a fallback image if not available.
 */
export const getSubscriptionImage = (subscription: Subscription, size: ImgSize = 'small') => {
  const { images } = subscription;
  const url = images && findListingImage(images)?.url;
  const variants = url ? proxiedImageUtil.getVariants([{ url }]) : [];
  return variants.length ? variants[0][size] : brokenPeanut;
};

/**
 * Generates the URL path for the auto-delivery details page.
 * @param delivery - The delivery or subscription object containing the delivery details.
 * @returns {string} The URL path for the delivery details page. There is a negligible chance the delivery ID being `NaN`. In that case, it returns the default auto-delivery path; otherwise, the path includes the ID.
 */
export const getAutoDeliveryDetailsPath = (delivery: ProposedDelivery | Subscription) => {
  let id: number | string | undefined;

  const isRedesign = useFeatureFlags().flags.autoDeliveryPortal;
  const basePath = isRedesign ? '/account/auto-delivery-redesign' : '/account/auto-delivery';

  if ('subscriptions' in delivery) {
    id = delivery.id;
  } else if (delivery.nextShipOn) {
    id = getAutoDeliveryOrderId(delivery.nextShipOn, delivery.shippingAddressId);
    if (isRedesign && delivery.interval) {
      id = `${delivery.interval}${id}`;
    }
  }

  return Number(id) ? `${basePath}/details/${id}` : basePath;
};
