<script setup lang="ts">
import '@typeform/embed/build/css/popup.css';

import { createPopup } from '@typeform/embed';
import { useLocalStorage } from '@vueuse/core';
import { onMounted, ref } from 'vue';

const { storageKey, surveyId } = defineProps<{ storageKey?: string; surveyId: string }>();

const canBeHidden = ref(!!storageKey);
const isVisible = ref(!canBeHidden.value);
const storage = useLocalStorage(`${storageKey}.done`, false, { writeDefaults: canBeHidden.value });

const onClose = () => {
  if (canBeHidden.value) {
    storage.value = true;
    isVisible.value = false;
  }
};

const onClick = () => {
  createPopup(surveyId, { onSubmit: onClose }).toggle();
};

onMounted(() => {
  isVisible.value = !canBeHidden.value || !storage.value;
});
</script>

<template>
  <transition name="fade">
    <div v-if="isVisible">
      <slot :onClick="onClick" :onClose="onClose" />
    </div>
  </transition>
</template>
