<script setup lang="ts">
import { computed } from 'vue';

import Caption from '@/components/base/typography/Caption.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

export type Size = 'xs' | 'sm';

const props = withDefaults(
  defineProps<{
    discountDisplayValue?: string;
    discountType?: 'product' | 'bulkOrAutoDelivery';
    size?: Size;
  }>(),
  {
    size: 'xs',
  },
);

const colorClasses = computed(() => {
  switch (props.discountType) {
    case 'product':
      return 'bg-nuts-red-200 text-nuts-red-800';
    case 'bulkOrAutoDelivery':
      return 'bg-nuts-sky-100 text-nuts-sky-800';
    default:
      return 'bg-nuts-amber-400';
  }
});

const componentType = computed(() => (props.size === 'sm' ? SmallBodyText : Caption));
</script>

<template>
  <component
    :is="componentType"
    class="font-bold px-0.5 py-0.25 text-black"
    :class="colorClasses"
    data-test="discount-badge"
  >
    {{ discountType ? `${discountDisplayValue} off` : `SAVE ${discountDisplayValue}` }}
  </component>
</template>
