import { RouteLocationNormalized, Router } from 'vue-router';

import { deriveDyContext } from '@/router/createRouter';
import { gtag } from '@/utils/analytics';
import { setTrackPageContext } from '@/utils/analytics/rudderstack';

export function installPageEvents(router: Router) {
  let firstRoute = true;

  function sendSpaStart(to: RouteLocationNormalized) {
    if (window.DY?.API && !firstRoute) {
      window.DY.API('spa_start', {
        context: deriveDyContext(to),
        url: to.path,
        countAsPageview: true,
      });
    }
  }

  function sendPageEvent(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (window.DY?.API && !firstRoute) {
      window.DY.API('spa_end', {
        context: deriveDyContext(to),
        url: to.path,
        countAsPageview: true,
      });
    }

    if (to.meta.gtagPageType) gtag('set', 'content_group', `${to.meta.gtagPageType}`);

    const pushChangeEvent = new CustomEvent<{
      from?: RouteLocationNormalized;
      to: RouteLocationNormalized;
    }>('onRoutingComplete', {
      detail: { to, from },
    });
    document.dispatchEvent(pushChangeEvent);

    if (to.path !== from?.path || (to.path === '/' && from?.path === '/')) {
      const category = to.meta.gtagPageType;
      const payload: { path: string; url: string; referrer?: string } = {
        path: to.path,
        url: `${window.location.origin}${to.fullPath}`,
      };

      // manually set for all spa navigation, otherwise let RS take care of it
      if (!firstRoute) {
        payload.referrer = `${window.location.origin}${from.fullPath}`;
      }

      setTrackPageContext({ category, referrer: payload.referrer });

      window.rudderanalytics?.page(category ?? '', '', payload);

      firstRoute = false;
    }
  }

  router.beforeResolve(sendSpaStart);
  router.afterEach(sendPageEvent);
}
