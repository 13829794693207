import { getConfigEntry } from '@/api/config';
import { reportError } from '@/utils/reportError';

export const generateRecaptcha = async () => {
  if (!window.grecaptcha) {
    reportError('Captcha did not load');
    return '';
  }
  try {
    return await window.grecaptcha.enterprise.execute(
      getConfigEntry('google').recaptcha.v3.invisible.sitekey,
      {
        action: 'checkout',
      },
    );
  } catch (error) {
    reportError(error, 'Captcha execution failed');
    return '';
  }
};

// The VueRecaptcha component has a bug where it loads even if the required methods are not available,
// it seems to only be looking for the window.grecaptcha variable,
// which sometimes is present because of the enterprise captcha, but the regular captcha did not load yet.
export const waitRecaptcha =
  typeof window !== 'undefined'
    ? new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (window.grecaptcha && window.grecaptcha.render) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      })
    : undefined;
