<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';
import { CmsRegisteredComponent } from '@/utils/cms';

const Header5 = defineComponent({
  name: 'Header5',
  props: {
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'h5' },
  },
});

export const Header5Registration: CmsRegisteredComponent = {
  component: Header5,
  name: 'Header5',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Header 5 text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default Header5;
</script>

<template>
  <component
    :is="headerTag"
    class="text-base font-semibold leading-5 header-5 font-sofia-pro lg:text-xl lg:leading-6"
  >
    <slot />
  </component>
</template>

<style scoped>
.header-5 {
  @apply mb-0;
}
</style>
