import { MaybeElementRef, whenever } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, toValue } from 'vue';

import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useCustomer } from '@/stores/customer';
import { useSession } from '@/stores/session';
import { Extole } from '@/utils/referral';

type Creatives =
  | 'account_page'
  | 'confirmation'
  | 'global_footer'
  | 'global_header_rebrandv1'
  | 'global_header_redesignv1'
  | 'global_header'
  | 'landing_page_cta'
  | 'mobile_menu_redesignv1'
  | 'mobile_menu';

type ProgramLabel = 'refer-a-friend' | 'refer-a-business';

export const useReferralElement = (
  el: MaybeElementRef,
  name: Creatives,
  label: ProgramLabel = 'refer-a-friend',
) => {
  const { flags } = useFeatureFlags();
  const { userFirstName } = storeToRefs(useSession());

  const showReferralElement = computed(() => !flags.disableReferral);

  whenever(
    () => toValue(el),
    (element) => {
      if (flags.disableReferral) return;

      if (!(element instanceof HTMLElement)) return;
      Extole.createZone({
        name,
        element,
        data: {
          email: useCustomer().customer?.email,
          first_name: userFirstName.value,
          partner_user_id: useCustomer().customer?.id,
          required_labels: label,
        },
      });
    },
    { immediate: true },
  );

  return {
    showReferralElement,
  };
};
