import { MobileNav } from '@/utils/navMenu';

export const staticMobileMenu: MobileNav = {
  menuGroups: [
    {
      headerUrl: '/tag/new%20products',
      headerText: 'New',
    },
    {
      linkGroups: [
        {
          group: {
            headerText: '',
            links: [
              {
                link: {
                  url: '/gift-guide',
                  text: 'Gift Guide',
                },
                emphasized: true,
              },
              {
                link: {
                  text: 'Best Sellers',
                  url: '/gifts/best-sellers/',
                },
                emphasized: true,
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Custom Mixes',
                  url: '/custom/mixes/',
                },
              },
              {
                link: {
                  text: 'Custom Trays',
                  url: '/custom/trays/',
                },
              },
            ],
            headerText: 'Custom Gifts',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/gifts/baskets/',
                  text: 'Basket & Boxes',
                },
              },
              {
                link: {
                  url: '/gifts/gifttins/',
                  text: 'Tins',
                },
              },
              {
                link: {
                  url: '/gifts/trays/',
                  text: 'Trays',
                },
              },
              {
                link: {
                  url: '/gifts/gift-certificate.html',
                  text: 'Gift Certificates',
                },
              },
            ],
            headerText: 'By Type',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Gifts under $40',
                  url: '/gifts/under-40/',
                },
              },
              {
                link: {
                  text: 'Gifts under $70',
                  url: '/gifts/30-50/',
                },
              },
              {
                link: {
                  url: '/gifts/tag/premium/',
                  text: 'Premium Gifts',
                },
              },
              {
                link: {
                  url: '/gifts/tag/charity/',
                  text: 'Gifts that Give Back',
                },
              },
            ],
            headerText: 'By Price',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/gifts/tag/anniversary/',
                  text: 'Anniversaries',
                },
              },
              {
                link: {
                  text: 'Birthdays',
                  url: '/gifts/tag/birthday/',
                },
              },
              {
                link: {
                  text: 'Congrats',
                  url: '/gifts/congratulations/',
                },
              },
              {
                link: {
                  url: '/corporate-gifts',
                  text: 'Corporate Gifting',
                },
              },
              {
                link: {
                  text: 'Get Well',
                  url: '/gifts/get-well/',
                },
              },
              {
                link: {
                  text: 'Sympathy',
                  url: '/gifts/sympathy/',
                },
              },
              {
                link: {
                  url: '/gifts/thank-you/',
                  text: 'Thank You',
                },
              },
              {
                link: {
                  url: '/gifts/all',
                  text: 'Shop All Gifts',
                },
              },
            ],
            headerText: 'By Occasion',
          },
        },
        {
          group: {
            headerText: 'By Flavor',
            links: [
              {
                link: {
                  url: '/gifts/chocolate-gifts/',
                  text: 'Chocolate',
                },
              },
              {
                link: {
                  url: '/gifts/fruity-gifts/',
                  text: 'Fruity',
                },
              },
              {
                link: {
                  text: 'Savory',
                  url: '/gifts/savory-gifts/',
                },
              },
              {
                link: {
                  url: '/gifts/salty-gifts/',
                  text: 'Salty',
                },
              },
            ],
            headerUrl: '',
          },
        },
        {
          group: {
            headerText: '',
            headerUrl: '',
            links: [
              {
                link: {
                  text: 'Gifts that Give Back',
                  url: '/gifts/tag/charity/',
                },
                emphasized: true,
              },
            ],
          },
        },
      ],
      headerText: 'Gifts',
    },
    {
      headerText: 'Shop by diet',
      linkGroups: [
        {
          group: {
            headerUrl: '',
            headerText: '',
            links: [
              {
                link: {
                  url: '/organic/',
                  text: 'Organic',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/gluten-free/',
                  text: 'Gluten Free',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  url: '/tag/sugar-free/',
                  text: 'Sugar Free',
                },
              },
              {
                link: {
                  url: '/tag/vegan/',
                  text: 'Vegan',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/tag/sprouted/',
                  text: 'Sprouted',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  url: '/kosher/',
                  text: 'Kosher',
                },
              },
              {
                emphasized: true,
                link: {
                  text: 'Paleo',
                  url: '/tag/paleo/',
                },
              },
              {
                emphasized: true,
                link: {
                  url: '/tag/keto/',
                  text: 'Keto',
                },
              },
            ],
          },
        },
      ],
    },
    {
      linkGroups: [
        {
          group: {
            links: [
              {
                emphasized: true,
                link: {
                  url: '/nuts-seeds/best-sellers/',
                  text: 'Best Sellers',
                },
              },
              {
                emphasized: true,
                link: {
                  url: '/nuts-seeds/raw-nuts-seeds/',
                  text: 'Raw',
                },
              },
              {
                link: {
                  text: 'Organic',
                  url: '/organic/nuts/',
                },
                emphasized: true,
              },
            ],
          },
        },
        {
          group: {
            headerUrl: '/nuts/roasted/',
            links: [
              {
                link: {
                  text: 'Salted',
                  url: '/nuts/roasted-salted/',
                },
              },
              {
                link: {
                  url: '/nuts-seeds/less-salt/',
                  text: '50% Less Salt',
                },
              },
              {
                link: {
                  url: '/nuts/roasted-unsalted/',
                  text: 'Unsalted',
                },
              },
            ],
            headerText: 'Roasted',
          },
        },
        {
          group: {
            headerUrl: '/nuts/',
            headerText: 'All Nuts',
            links: [
              {
                link: {
                  text: 'Almonds',
                  url: '/nuts/almonds/',
                },
              },
              {
                link: {
                  url: '/nuts/brazilnuts/',
                  text: 'Brazil Nuts',
                },
              },
              {
                link: {
                  url: '/nuts/baru-nuts/',
                  text: 'Baru Nuts',
                },
              },
              {
                link: {
                  url: '/nuts/cacao/',
                  text: 'Cacao',
                },
              },
              {
                link: {
                  text: 'Cashews',
                  url: '/nuts/cashews/',
                },
              },
              {
                link: {
                  text: 'Chestnuts',
                  url: '/nuts/chestnuts/',
                },
              },
              {
                link: {
                  url: '/nuts/coconut/',
                  text: 'Coconut',
                },
              },
              {
                link: {
                  url: '/nuts/hazelnuts/',
                  text: 'Hazelnuts (Filberts)',
                },
              },
              {
                link: {
                  url: '/nuts/in-shell/',
                  text: 'In Shell Nuts',
                },
              },
              {
                link: {
                  text: 'Macadamia Nuts',
                  url: '/nuts/macadamianuts/',
                },
              },
              {
                link: {
                  url: '/nuts/mixednuts/',
                  text: 'Mixed Nuts',
                },
              },
              {
                link: {
                  text: 'Peanuts',
                  url: '/nuts/peanuts/',
                },
              },
              {
                link: {
                  text: 'Pecans',
                  url: '/nuts/pecans/',
                },
              },
              {
                link: {
                  text: 'Pili Nuts',
                  url: '/nuts/pili',
                },
              },
              {
                link: {
                  text: 'Pine Nuts',
                  url: '/nuts/pinenuts/',
                },
              },
              {
                link: {
                  text: 'Pistachios',
                  url: '/nuts/pistachios/',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/toppings/nuts/chopped/',
                  text: 'Sliced & Chopped Nuts',
                },
              },
              {
                link: {
                  url: '/nuts/tiger-nuts/',
                  text: 'Tiger Nuts',
                },
              },
              {
                link: {
                  text: 'Walnuts',
                  url: '/nuts/walnuts/',
                },
              },
              {
                link: {
                  url: '/nuts/',
                  text: 'Shop All Nuts',
                },
              },
            ],
          },
        },
        {
          group: {
            headerUrl: '/cookingbaking/seeds/',
            headerText: 'All Seeds',
            links: [
              {
                link: {
                  url: '/snacks/chiaseeds/',
                  text: 'Chia Seeds',
                },
              },
              {
                link: {
                  url: '/cookingbaking/seeds/flax/',
                  text: 'Flax Seeds',
                },
              },
              {
                link: {
                  text: 'Hemp Seeds',
                  url: '/snacks/hemp-seeds/',
                },
              },
              {
                link: {
                  text: 'Poppy Seeds',
                  url: '/cookingbaking/seeds/poppy/',
                },
              },
              {
                link: {
                  text: 'Pumpkin (Pepitas)',
                  url: '/snacks/pumpkinseeds',
                },
              },
              {
                link: {
                  url: '/cookingbaking/seeds/sesame/',
                  text: 'Sesame Seeds',
                },
              },
              {
                link: {
                  text: 'Squash Seeds',
                  url: '/nuts-seeds/squash/',
                },
              },
              {
                link: {
                  url: '/nuts/sunflower-seeds/',
                  text: 'Sunflower Seeds',
                },
              },
              {
                link: {
                  url: '/snacks/watermelon-seeds/regular.html',
                  text: 'Watermelon Seeds',
                },
              },
              {
                link: {
                  text: 'Shop All Seeds',
                  url: '/cookingbaking/seeds/',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/nuts-seeds/candied-flavored/',
                  text: 'Candied & Flavored',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/nuts/chocolate-covered/',
                  text: 'Chocolate Covered',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/nuts-seeds/flour-meal/',
                  text: 'Flour & Meal',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/cookingbaking/spreads/nut-butters/',
                  text: 'Nut & Seed Butter',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/nuts/nut-milks/',
                  text: 'Nut Milk',
                },
                emphasized: true,
              },
            ],
          },
        },
      ],
      headerText: 'Nuts & Seeds',
    },
    {
      linkGroups: [
        {
          group: {
            links: [
              {
                link: {
                  text: 'Best Sellers',
                  url: '/driedfruit/best-sellers/',
                },
                emphasized: true,
              },
              {
                link: {
                  text: 'Organic',
                  url: '/driedfruit/organic/"',
                },
                emphasized: true,
              },
              {
                link: {
                  text: 'No Sugar Added',
                  url: '/driedfruit/no-sugar-added/',
                },
                emphasized: true,
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/driedfruit/apricots/',
                  text: 'Apricots',
                },
              },
              {
                link: {
                  url: '/driedfruit/cherries/',
                  text: 'Cherries',
                },
              },
              {
                link: {
                  url: '/driedfruit/coconut/',
                  text: 'Coconut',
                },
              },
              {
                link: {
                  url: '/driedfruit/dates/',
                  text: 'Dates',
                },
              },
              {
                link: {
                  url: '/driedfruit/figs/',
                  text: 'Figs',
                },
              },
              {
                link: {
                  text: 'Mango',
                  url: '/driedfruit/mango/',
                },
              },
              {
                link: {
                  url: '/driedfruit/pineapple/',
                  text: 'Pineapple',
                },
              },
              {
                link: {
                  url: '/driedfruit/raisins/',
                  text: 'Raisins',
                },
              },
              {
                link: {
                  text: 'Shop All Dried Fruit',
                  url: '/driedfruit/',
                },
              },
            ],
            headerText: 'Dried Fruit',
            headerUrl: '/driedfruit/',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Blueberries',
                  url: '/driedfruit/blueberries/',
                },
              },
              {
                link: {
                  text: 'Cranberries',
                  url: '/driedfruit/cranberries/',
                },
              },
              {
                link: {
                  url: '/driedfruit/goji-berries/',
                  text: 'Goji Berries',
                },
              },
              {
                link: {
                  text: 'Mulberries',
                  url: '/driedfruit/mulberries/',
                },
              },
              {
                link: {
                  text: 'Raspberries',
                  url: '/driedfruit/red-raspberries/',
                },
              },
              {
                link: {
                  url: '/driedfruit/strawberries/',
                  text: 'Strawberries',
                },
              },
              {
                link: {
                  url: '/driedfruit/berries/',
                  text: 'Shop All Berries',
                },
              },
            ],
            headerText: 'Berries',
            headerUrl: '/driedfruit/berries/',
          },
        },
        {
          group: {
            headerText: 'Fruity Snacks',
            headerUrl: '/driedfruit/fruit-snacks/',
            links: [
              {
                link: {
                  url: '/driedfruit/flavored/',
                  text: 'Flavored Dried Fruit',
                },
              },
              {
                link: {
                  url: '/driedfruit/fruit-chips/',
                  text: 'Fruit Chips',
                },
              },
              {
                link: {
                  text: 'Fruit Leather',
                  url: '/snacks/fruit-leather/premium/',
                },
              },
              {
                link: {
                  text: 'Fruit Snacks',
                  url: '/driedfruit/fruit-snacks/',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Mixed Fruit',
                  url: '/driedfruit/mixed-fruit/',
                },
                emphasized: true,
              },
              {
                link: {
                  text: 'Natural Dried Fruit',
                  url: '/driedfruit/natural/',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/driedfruit/freeze-dried/',
                  text: 'Freeze Dried',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  text: 'Chocolate Covered',
                  url: '/driedfruit/chocolate-covered/',
                },
              },
            ],
          },
        },
      ],
      headerText: 'Dried Fruit',
    },
    {
      headerText: 'Superfood Powders',
      linkGroups: [
        {
          group: {
            links: [
              {
                link: {
                  text: 'Best Sellers',
                  url: '/powders/best-sellers/',
                },
                emphasized: true,
              },
            ],
          },
        },
        {
          group: {
            headerText: 'Powders',
            headerUrl: '/cookingbaking/powders/',
            links: [
              {
                link: {
                  url: '/cookingbaking/powders/adaptogenic-powders/',
                  text: 'Adaptogenic Powders',
                },
              },
              {
                link: {
                  url: '/cookingbaking/powders/fruit-powders/',
                  text: 'Fruit Powders',
                },
              },
              {
                link: {
                  url: '/cookingbaking/powders/herb-root-powders/',
                  text: 'Herb & Root Powders',
                },
              },
              {
                link: {
                  url: '/powders/milk-cheese-powders/',
                  text: 'Milk & Cheese Powders',
                },
              },
              {
                link: {
                  url: '/cookingbaking/powders/plant-powders/',
                  text: 'Plant Powders',
                },
              },
              {
                link: {
                  text: 'Protein Powders',
                  url: '/cookingbaking/powders/protein-powders/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/powders/vegetable-powders/',
                  text: 'Vegetable Powders',
                },
              },
              {
                link: {
                  text: 'Shop All Powders',
                  url: '/cookingbaking/powders/',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/cookingbaking/organic-powders/adaptogenic-powders/',
                  text: 'Adaptogenic Powders',
                },
              },
              {
                link: {
                  text: 'Fruit Powders',
                  url: '/cookingbaking/organic-powders/fruit-powders/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/organic-powders/herb-root-powders/',
                  text: 'Herb & Root Powders',
                },
              },
              {
                link: {
                  url: '/cookingbaking/organic-powders/plant-powders/',
                  text: 'Plant Powders',
                },
              },
              {
                link: {
                  text: 'Protein Powders',
                  url: '/cookingbaking/organic-powders/protein-powders/',
                },
              },
              {
                link: {
                  text: 'Vegetable Powders',
                  url: '/cookingbaking/organic-powders/vegetable-powders/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/powders/organic-powders/',
                  text: 'Shop All Organic Powders',
                },
              },
            ],
            headerText: 'Organic Powders',
            headerUrl: '/cookingbaking/powders/organic-powders/',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/powders/beauty/',
                  text: 'Beauty',
                },
              },
              {
                link: {
                  url: '/powders/immunity-boosting/',
                  text: 'Immunity Boosting',
                },
              },
              {
                link: {
                  url: '/powders/mood-boosting/',
                  text: 'Mood Boosting',
                },
              },
              {
                link: {
                  text: 'Pantry Essentials',
                  url: '/powders/pantry-essentials/',
                },
              },
              {
                link: {
                  url: '/powders/performance-boosting/',
                  text: 'Performance Boosting',
                },
              },
              {
                link: {
                  text: 'Smoothie Supplies',
                  url: '/cookingbaking/powders/smoothies/',
                },
              },
              {
                link: {
                  text: 'Stress Reducing',
                  url: '/powders/stress-reducing/',
                },
              },
            ],
            headerText: 'Shop By Use',
          },
        },
      ],
    },
    {
      linkGroups: [
        {
          group: {
            links: [
              {
                emphasized: true,
                link: {
                  url: '/chocolatessweets/best-sellers/',
                  text: 'Best Sellers',
                },
              },
              {
                emphasized: true,
                link: {
                  url: '/chocolatessweets/organic/',
                  text: 'Organic',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/sugar-free-candy/',
                  text: 'Sugar Free',
                },
                emphasized: true,
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/chocolatessweets/dried-fruit/',
                  text: 'Dried Fruit',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/premium-chocolates/espresso-beans/',
                  text: 'Espresso Beans',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/malted-milk-balls/',
                  text: 'Malted Milk Balls',
                },
              },
              {
                link: {
                  url: '/nuts/chocolatessweets/',
                  text: 'Nuts',
                },
              },
              {
                link: {
                  text: 'Pretzels',
                  url: '/chocolatessweets/pretzels/',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/color-chocolates/sunflower-seeds/',
                  text: 'Seeds',
                },
              },
              {
                link: {
                  url: '/search/instant?query=chocolate+covered/',
                  text: 'Shop All',
                },
              },
            ],
            headerText: 'Chocolate Covered',
            headerUrl: '/search/instant?query=chocolate+covered/',
          },
        },
        {
          group: {
            headerText: 'Classic Candy',
            headerUrl: '/chocolatessweets/classic-candies/',
            links: [
              {
                link: {
                  url: '/chocolatessweets/jelly-candy/gourmet-fruit-slices/',
                  text: 'Fruit Slices',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/gummies/',
                  text: 'Gummies',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/hard-candy/',
                  text: 'Hard Candy',
                },
              },
              {
                link: {
                  text: 'Jelly Beans',
                  url: '/chocolatessweets/jelly-beans/',
                },
              },
              {
                link: {
                  text: 'Jordan Almonds',
                  url: '/jordan-almonds/',
                },
              },
              {
                link: {
                  text: 'Licorice',
                  url: '/chocolatessweets/licorice/',
                },
              },
              {
                link: {
                  text: 'M&Ms',
                  url: '/chocolatessweets/old-time-candy/m-m/',
                },
              },
              {
                link: {
                  text: 'Nonpareils',
                  url: '/chocolatessweets/nonpareils/"',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/classic-candies/',
                  text: 'Shop All',
                },
              },
            ],
          },
        },
        {
          group: {
            headerText: 'Specialty Candy',
            links: [
              {
                link: {
                  url: '/chocolatessweets/old-time-candy/candy-sticks/',
                  text: 'Candy Sticks',
                },
              },
              {
                link: {
                  text: 'Chocolate Bark',
                  url: '/chocolatessweets/chocolate-bark/',
                },
              },
              {
                link: {
                  text: 'Chocolate Rocks',
                  url: '/chocolatessweets/toppings/candy-chocolates/chocolate-rocks/',
                },
              },
              {
                link: {
                  url: '/nuts/chocolatessweets/fudge/',
                  text: 'Fudge',
                },
              },
              {
                link: {
                  text: 'Ice Cream Toppings',
                  url: '/chocolatessweets/toppings/',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/novelty/',
                  text: 'Novelty Candy',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/old-time-candy/',
                  text: 'Old Time Candy',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/old-time-candy/rock-candy/',
                  text: 'Rock Candy',
                },
              },
              {
                link: {
                  text: 'Salt Water Taffy',
                  url: '/chocolatessweets/old-time-candy/salt-water-taffy/',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/chocolatessweets/vegan-chocolates-and-sweets/',
                  text: 'Vegan',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  text: 'Candy by Color',
                  url: '/colors/',
                },
              },
              {
                emphasized: true,
                link: {
                  url: '/chocolatessweets/by-flavor/',
                  text: 'Candy by Flavor',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/wrapped/',
                  text: 'Wrapped Candy',
                },
                emphasized: true,
              },
              {
                link: {
                  url: '/chocolatessweets/premium-chocolates/',
                  text: 'Premium Chocolates',
                },
                emphasized: true,
              },
            ],
          },
        },
      ],
      headerText: 'Chocolate & Sweets',
    },
    {
      headerText: 'Snacks',
      linkGroups: [
        {
          group: {
            links: [
              {
                link: {
                  url: '/snacks/trail-mix/',
                  text: 'Trail Mix',
                },
                emphasized: true,
              },
              {
                link: {
                  text: 'Organic',
                  url: '/snacks/organic-snacks/',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  url: '/snacks/gluten-free/',
                  text: 'Gluten-Free',
                },
              },
            ],
            headerText: '',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/custom/mixes/',
                  text: 'Custom Trail Mix',
                },
              },
              {
                link: {
                  url: '/snacks/dried-fruit/',
                  text: 'Dried Fruit',
                },
              },
              {
                link: {
                  text: 'Energy Squares',
                  url: '/snacks/squares-energy/',
                },
              },
              {
                link: {
                  url: '/snacks/fruit-veggie-chips/',
                  text: 'Fruit & Veggie Chips',
                },
              },
              {
                link: {
                  url: '/snacks/granola/',
                  text: 'Granola & Muesli',
                },
              },
              {
                link: {
                  text: 'Nut Bars',
                  url: '/snacks/nut-bars/',
                },
              },
              {
                link: {
                  text: 'Nuts & Seeds',
                  url: '/snacks/nuts-seeds/',
                },
              },
              {
                link: {
                  text: 'Whole Wheat Bars',
                  url: '/snacks/whole-wheat-bars/',
                },
              },
            ],
            headerText: 'Feel Good Snacks',
          },
        },
        {
          group: {
            headerText: 'Salty Snacks',
            headerUrl: '/snacks/by-flavor/salty/',
            links: [
              {
                link: {
                  url: '/snacks/corn-nuts/',
                  text: 'Corn Nuts',
                },
              },
              {
                link: {
                  text: 'Crackers',
                  url: '/snacks/crackers/',
                },
              },
              {
                link: {
                  url: '/snacks/half-popped/',
                  text: 'Half Popped Popcorn',
                },
              },
              {
                link: {
                  url: '/snacks/olives-cured-meat/',
                  text: 'Olives & Cured Meat',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/snacks/popcorn/',
                  text: 'Popcorn',
                },
              },
              {
                link: {
                  url: '/snacks/pretzels/',
                  text: 'Pretzels',
                },
              },
              {
                link: {
                  text: 'Sesame Sticks',
                  url: '/snacks/sesamesticks/',
                },
              },
              {
                link: {
                  text: 'Soy Beans & Edamame',
                  url: '/snacks/soy-beans-edamame/',
                },
              },
              {
                link: {
                  url: '/snacks/by-flavor/salty/',
                  text: 'Shop All Salty Snacks',
                },
              },
            ],
          },
        },
        {
          group: {
            headerText: 'Savory Snacks',
            links: [
              {
                link: {
                  text: 'Cheesy Snacks',
                  url: '/snacks/cheese/',
                },
              },
              {
                link: {
                  url: '/snacks/jerky/',
                  text: 'Jerky',
                },
              },
              {
                link: {
                  url: '/snacks/hot-spicy/',
                  text: 'Spicy Snacks',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/snacks/asian/',
                  text: 'Asian Snacks',
                },
              },
              {
                link: {
                  url: '/snacks/indian/',
                  text: 'Indian Snacks',
                },
              },
            ],
            headerText: 'Ethnic Snacks',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Vegan',
                  url: '/snacks/vegan/',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  text: 'Sprouted',
                  url: '/cookingbaking/sprouted/snacks/',
                },
              },
              {
                link: {
                  url: '/snacks/raw/',
                  text: 'Raw',
                },
                emphasized: true,
              },
            ],
          },
        },
      ],
    },
    {
      headerText: 'Coffee & Tea',
      linkGroups: [
        {
          group: {
            headerText: 'Coffee',
            headerUrl: '/coffeesteas/coffees/',
            links: [
              {
                link: {
                  text: 'Light Roast',
                  url: '/coffeesteas/coffees/light-roast/',
                },
              },
              {
                link: {
                  text: 'Medium Roast',
                  url: '/coffeesteas/medium-roast/',
                },
              },
              {
                link: {
                  text: 'Dark Roast',
                  url: '/coffeesteas/coffees/dark-roast/',
                },
              },
              {
                link: {
                  url: '/coffeesteas/decaf-coffees/',
                  text: 'Decaf',
                },
              },
              {
                link: {
                  url: '/coffeesteas/flavoredcoffees/',
                  text: 'Flavored Coffee',
                },
              },
              {
                link: {
                  text: 'House Blend',
                  url: '/coffeesteas/house-blend/',
                },
              },
              {
                link: {
                  text: 'Turkish Coffee',
                  url: '/coffeesteas/coffees/light-roast/deluxe-house.html/',
                },
              },
              {
                link: {
                  url: '/coffeesteas/coffees/',
                  text: 'Shop All Coffee',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/coffeesteas/black-chai/',
                  text: 'Black & Chai Tea',
                },
              },
              {
                link: {
                  url: '/coffeesteas/bubble-tea/',
                  text: 'Bubble Tea',
                },
              },
              {
                link: {
                  url: '/coffeesteas/decaf-tea/',
                  text: 'Decaf Tea',
                },
              },
              {
                link: {
                  text: 'Green & Matcha',
                  url: '/coffeesteas/greenteas/',
                },
              },
              {
                link: {
                  url: '/coffeesteas/herbal-flavored/',
                  text: 'Herbal & Flavored',
                },
              },
              {
                link: {
                  text: 'Iced Tea',
                  url: '/coffeesteas/iced-teas/',
                },
              },
              {
                link: {
                  url: '/coffeesteas/oolong-tea/',
                  text: 'Oolong',
                },
              },
              {
                link: {
                  url: '/coffeesteas/rooibos/',
                  text: 'Rooibos',
                },
              },
            ],
            headerText: 'Tea',
          },
        },
        {
          group: {
            headerText: 'Specialty',
            links: [
              {
                link: {
                  text: 'Chicory',
                  url: '/coffeesteas/chicory/',
                },
              },
              {
                link: {
                  text: 'Coffee Pods',
                  url: '/coffeesteas/coffee-pods/',
                },
              },
              {
                link: {
                  url: '/coffeesteas/hot-chocolate/',
                  text: 'Hot Chocolate',
                },
              },
              {
                link: {
                  url: '/nuts/nut-milks/',
                  text: 'Nut Milks',
                },
              },
              {
                link: {
                  url: '/coffeesteas/coffees-teas-beverages-sweeteners/',
                  text: 'Sweeteners',
                },
              },
            ],
          },
        },
      ],
    },
    {
      linkGroups: [
        {
          group: {
            headerText: '',
            headerUrl: '',
            links: [
              {
                link: {
                  url: '/cooking/organic/',
                  text: 'Organic',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  url: '/cooking/gluten-free/',
                  text: 'Gluten-Free',
                },
              },
              {
                emphasized: true,
                link: {
                  text: 'Sprouted',
                  url: '/cookingbaking/sprouted/',
                },
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Artisanal Pastas',
                  url: '/cookingbaking/pasta/artisanal/',
                },
              },
              {
                link: {
                  text: 'Beans, Peas & Lentils',
                  url: '/cookingbaking/beans/',
                },
              },
              {
                link: {
                  text: 'Bread Crumbs & Stuffing',
                  url: '/cookingbaking/beans/',
                },
              },
              {
                link: {
                  text: 'Condiments',
                  url: '/cookingbaking/condiments/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/flours/',
                  text: 'Flours',
                },
              },
              {
                link: {
                  text: 'Mushrooms',
                  url: '/cookingbaking/mushrooms/',
                },
              },
              {
                link: {
                  url: '/snacks/simply-veggies/',
                  text: 'Freeze Dried Vegetables',
                },
              },
              {
                link: {
                  url: '/cookingbaking/grains/',
                  text: 'Grains',
                },
              },
              {
                link: {
                  text: 'Herbs, Spices & Salts',
                  url: '/cookingbaking/herbsspices/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/spreads/',
                  text: 'Jams & Jellies',
                },
              },
              {
                link: {
                  text: 'Leaveners & Thickeners',
                  url: '/cookingbaking/leavenerthickener/',
                },
              },
              {
                link: {
                  text: 'Noodles',
                  url: '/cooking/noodles/',
                },
              },
              {
                link: {
                  url: '/nuts/nut-butters-oils/',
                  text: 'Nut Butters',
                },
              },
              {
                link: {
                  text: 'Nut Milks',
                  url: '/nuts/nut-milks/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/oil-and-vinegar/',
                  text: 'Oils & Vinegars',
                },
              },
              {
                link: {
                  text: 'Sauces',
                  url: '/cooking/sauces/',
                },
              },
              {
                link: {
                  url: '/cooking/soup-base/',
                  text: 'Soup Bases',
                },
              },
            ],
            headerText: 'Pantry Essentials',
          },
        },
        {
          group: {
            headerText: 'Shop By Lifestyle',
            links: [
              {
                link: {
                  url: '/cooking/keto/',
                  text: 'Keto',
                },
              },
              {
                link: {
                  text: 'Kid-friendly',
                  url: '/cooking/kid-friendly/',
                },
              },
              {
                link: {
                  url: '/cooking/paleo/',
                  text: 'Paleo',
                },
              },
              {
                link: {
                  text: 'Vegan',
                  url: '/cooking/vegan/',
                },
              },
              {
                link: {
                  url: '/cooking/whole-thirty/',
                  text: 'Whole 30',
                },
              },
            ],
          },
        },
      ],
      headerText: 'Cooking',
    },
    {
      linkGroups: [
        {
          group: {
            links: [
              {
                link: {
                  text: 'Organic',
                  url: '/baking/organic/',
                },
                emphasized: true,
              },
              {
                emphasized: true,
                link: {
                  text: 'Gluten-Free',
                  url: '/baking/gluten-free-baking/',
                },
              },
              {
                link: {
                  text: 'Vegan',
                  url: '/baking/vegan/',
                },
                emphasized: true,
              },
            ],
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/cookingbaking/mixes/',
                  text: 'Baking Mixes',
                },
              },
              {
                link: {
                  url: '/cookingbaking/gluten-free-flour/',
                  text: 'Gluten-Free Flours',
                },
              },
              {
                link: {
                  url: '/baking/grain-flour/',
                  text: 'Grain Flours',
                },
              },
              {
                link: {
                  text: 'Nut Flours',
                  url: '/baking/nut-flour/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/flours/',
                  text: 'Shop All Flours',
                },
              },
            ],
            headerText: 'Flours & Baking Mixes',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  text: 'Chips & Wafers',
                  url: '/baking/chips-wafers/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/cocoa-powders-baking-chocolate/',
                  text: 'Cocoa Powder',
                },
              },
              {
                link: {
                  url: '/chocolatessweets/nonpareils/',
                  text: 'Nonpareils',
                },
              },
              {
                link: {
                  text: 'Pearls',
                  url: '/cookingbaking/decorations-toppings/pearls/',
                },
              },
              {
                link: {
                  text: 'Sanding Sugar',
                  url: '/cookingbaking/decorations-toppings/sanding-sugar/',
                },
              },
              {
                link: {
                  url: '/cookingbaking/decorations-toppings/sprinkles/',
                  text: 'Sprinkles',
                },
              },
            ],
            headerText: 'Baking Chocolate & Toppings',
          },
        },
        {
          group: {
            links: [
              {
                link: {
                  url: '/baking/baking-spices/',
                  text: 'Baking Spices',
                },
              },
              {
                link: {
                  url: '/cookingbaking/extracts-flavorings/',
                  text: 'Extracts & Flavorings',
                },
              },
              {
                link: {
                  url: '/cookingbaking/fillings/',
                  text: 'Fillings & Pastes',
                },
              },
              {
                link: {
                  text: 'Leaveners & Thickeners',
                  url: '/cookingbaking/leavenerthickener/',
                },
              },
              {
                link: {
                  url: '/coffeesteas/coffees-teas-beverages-sweeteners/',
                  text: 'Sweeteners',
                },
              },
            ],
            headerText: 'Baking Supplies',
          },
        },
        {
          group: {
            links: [
              {
                emphasized: true,
                link: {
                  url: '/baking/coconut/',
                  text: 'Coconut',
                },
              },
              {
                link: {
                  text: 'Dried Fruit for Baking',
                  url: '/baking/dried-fruit/',
                },
                emphasized: true,
              },
              {
                link: {
                  text: 'Sliced and Chopped Nuts',
                  url: '/chocolatessweets/toppings/nuts/chopped/',
                },
                emphasized: true,
              },
            ],
          },
        },
      ],
      headerText: 'Baking',
    },
    {
      headerText: 'Vitamins',
      headerUrl: '/vitamins/',
    },
    {
      headerUrl: '/nuts/single-serve/',
      headerText: 'Variety Packs',
    },
  ],
  utilityMenu: [
    {
      dotColor: '#f68b3d',
      link: {
        url: '/account',
        text: 'My Account',
      },
    },
    {
      link: {
        text: 'Easy Reorder',
        url: '/account/easy-reorder',
      },
      dotColor: 'rgba(146, 197, 95, 1)',
    },
    {
      link: {
        text: 'My Favorites',
        url: '/account/favorites',
      },
      dotColor: 'rgba(88, 164, 239, 1)',
    },
    {
      link: {
        text: 'My Orders',
        url: '/account/order-history',
      },
      dotColor: 'rgba(246, 126, 196, 1)',
    },
    {
      link: {
        url: '/gifts/gift-certificate.html',
        text: 'Gift Cards',
      },
      dotColor: 'rgba(216, 171, 80, 1)',
    },
    {
      link: {
        url: 'https://help.nuts.com',
        text: 'Help',
      },
      dotColor: 'rgba(242, 102, 95, 1)',
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets%2Feffc3d0f04d349b0a5da8c78825f92a5%2Fd77d872ae73046838cabd1b883389baa',
      link: {
        text: 'Nuts For Business',
        url: '/business',
      },
    },
  ],
};
