<script lang="ts">
import { Field } from 'vee-validate';
import { useId } from 'vue';

import { BuilderComponent } from '@/utils/cms';

interface Props {
  name: string;
  value: string;
}
export const CheckboxRegistration: BuilderComponent = {
  name: 'Checkbox',
  canHaveChildren: true,
  inputs: [
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'value',
      type: 'string',
    },
  ],
};
</script>

<script setup lang="ts">
defineProps<Props>();

const id = useId();
</script>

<template>
  <div class="flex items-center gap-2">
    <Field :name type="checkbox" :value v-slot="{ field }">
      <input
        v-bind="field"
        class="inline-block w-4 h-4 my-0 ml-0 align-middle accent-bg-nuts-lime-800 accent-nuts-lime-800"
        :name
        :id
        type="checkbox"
        :value
      />
      <slot />
    </Field>
  </div>
</template>
