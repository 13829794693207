<script setup lang="ts">
import { SearchIndex } from 'algoliasearch';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { getIndex, init as initAlgolia, searchProducts } from '@/api/algolia';
import Checkbox from '@/components/base/form/Checkbox.vue';
import HorizontalProductCard from '@/components/base/layout/HorizontalProductCard.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useCart } from '@/composables/useCart';
import money from '@/filters/money';
import { displayPrice, ProductCard, ProductCardData } from '@/utils/productCard';

interface SkuField {
  sku: ProductCardData['sku'];
}

const props = defineProps<{ skus: SkuField[] }>();

const router = useRouter();
const store = useStore();
const { addToCart } = useCart(store);

const isButtonDisabled = ref(false);
const checkedProducts = ref<ProductCardData[]>([]);
const products = ref<ProductCardData[]>([]);

const addAllToCart = async () => {
  isButtonDisabled.value = true;
  await Promise.all(
    checkedProducts.value.map((product) =>
      addToCart(
        {
          cart_sku: {
            sku_external_id: product.sku,
            quantity: 1,
          },
        },
        {
          postAddToCartCallback: () => null,
          router,
        },
      ),
    ),
  );
};

const handleCheckbox = (checked: boolean, product: ProductCardData) => {
  if (checked) checkedProducts.value.push(product);
  else checkedProducts.value = checkedProducts.value.filter((item) => item.sku !== product.sku);
};

const loadProducts = async (productsIndex: SearchIndex) => {
  const algoliaProducts = await searchProducts(productsIndex, {
    filters: 'outOfStock:false AND Product_allVariantsOutOfStock:false',
    facetFilters: [props.skus.map((skuField) => `sku:${skuField.sku}`)],
  });
  const productCardsInStock = algoliaProducts.map(ProductCard.fromAlgolia);
  products.value = productCardsInStock;
  checkedProducts.value = productCardsInStock;
};

onMounted(async () => {
  const algoliaClient = initAlgolia();
  const productsIndex = getIndex(algoliaClient, 'Products');

  try {
    await loadProducts(productsIndex);
  } catch (error) {
    console.error('failed to get Algolia results', error);
    products.value = [];
  }
});
</script>

<script lang="ts">
// eslint-disable-next-line import/first, import/no-duplicates
import { BuilderComponent } from '@/utils/cms';

export const ShopableIngredientsRegistration: BuilderComponent = {
  name: 'Shopable Ingredients',
  inputs: [
    {
      name: 'skus',
      type: 'list',
      defaultValue: [{ sku: '7557-01' }, { sku: '8420-01' }, { sku: '3103-01' }],
      subFields: [
        {
          name: 'sku',
          type: 'string',
        },
      ],
    },
  ],
};
</script>

<template>
  <div
    v-for="(product, index) in products"
    :key="product.sku"
    class="z-10 flex items-center px-4 py-3 border-solid shrink-0 md:py-2 border-neutral-300"
    :class="{ 'border-t': index !== 0 }"
  >
    <Checkbox
      class="mr-2"
      :modelValue="checkedProducts.some((p) => p.sku === product.sku)"
      @update:modelValue="handleCheckbox($event, product)"
    />
    <HorizontalProductCard imageSize="small" :product>
      <template v-slot:price>
        <span v-if="product.totalSavings" class="sr-only">Price reduced from </span>
        <BaseBodyText
          :class="product.totalSavings?.onSale ? 'text-nuts-red-800' : 'text-neutral-500'"
        >
          {{ product.hidePrice ? 'Build your own' : displayPrice(product) }}
        </BaseBodyText>
        <span v-if="product.totalSavings" class="sr-only"> to </span>
        <SmallBodyText v-if="product.totalSavings" class="ml-1.5 line-through text-neutral-500">
          {{ money(product.totalSavings?.comparisonPrice) }}
        </SmallBodyText>
      </template>
    </HorizontalProductCard>
  </div>
  <ThemedButton
    class="w-full mt-2 mb-2"
    @click="addAllToCart"
    :disabled="isButtonDisabled || checkedProducts.length === 0"
    size="small"
    theme="gray"
  >
    <span class="py-1 text-sm font-semibold leading-4 sm:leading-tight">
      Add {{ checkedProducts.length }} items to Cart
    </span>
  </ThemedButton>
</template>
