import { CONTACT_EMAIL_COOKIE, CUSTOMER_ORDER_COUNT_COOKIE } from '@/lib/personalization/common';
import {
  BaseChoice,
  DY_SESSION_ID_COOKIE,
  DY_USER_ID_COOKIE,
  DyPageType,
  JsonChoice,
  RecommendationsChoice,
} from '@/lib/personalization/dynamicYield';
import { InitialRequestContext } from '@/stores/initialRequest';
import { getCookie } from '@/utils/isomorphic/cookie';
import { retry } from '@/utils/retry';

/**
 * Interface for real-time rules to filter recommendations
 *
 * Based on Dynamic Yield documentation at https://dy.dev/docs/return-real-time-filter-data
 */
export interface RealtimeRule {
  /** The unique ID for the rule (client-side API only) */
  id?: number;
  type: 'exclude' | 'include';
  /** Position in widget (empty array for all slots) */
  slots: number[];
  query: {
    conditions: {
      /** The DY feed field to filter on */
      field: string;
      arguments: {
        /**
         * Condition types for filtering recommendations include:
         * - IS: string or boolean ('In stock' - true/false) only
         * - IS_NOT: string only
         * - CONTAINS: Up to 10 of this type per filter
         * - EQ, GT, GTE, LT, LTE: numbers only
         */
        action: 'IS' | 'IS_NOT' | 'CONTAINS' | 'EQ' | 'GT' | 'GTE' | 'LT' | 'LTE';
        value: string | number | boolean;
      }[];
    }[];
  };
  /** Optional priority between 0 and 29 (client-side API only) */
  priority?: number;
}

export interface ChooseParams {
  additionalCustomAttributes?: Record<string, string>;
  customerContext: {
    businessIndustry?: string;
    hasBusinessAccount: boolean;
    isB2bContact: boolean;
    userHasBusinessAccount: boolean;
  };
  implicitPageview?: boolean;
  initialRequest: InitialRequestContext;
  location: string;
  newSession: boolean;
  pageContext: {
    data: string[];
    type: DyPageType;
  };
  realtimeRules?: Record<string, RealtimeRule[]>;
  referrer?: string;
}

export interface ChooseResponse<T = JsonChoice | RecommendationsChoice> {
  choices: T[];
  cookies: {
    maxAge: string;
    name: string;
    value: string;
  }[];
}

const isBrowser = typeof window !== 'undefined';
const apiKey = import.meta.env.SSR
  ? import.meta.env.VITE_DY_SERVER_SIDE_KEY
  : import.meta.env.VITE_DY_CLIENT_SIDE_KEY;
const baseUrl = isBrowser
  ? import.meta.env.VITE_DY_CLIENT_SIDE_BASE_URI
  : import.meta.env.VITE_DY_SERVER_SIDE_BASE_URI;
const requestTimeoutLimit = Number(
  isBrowser
    ? import.meta.env.VITE_DY_CLIENT_SIDE_TIMEOUT
    : import.meta.env.VITE_DY_SERVER_SIDE_TIMEOUT,
);

function buildRequestBody(experienceNames: string[], params: ChooseParams) {
  const isContact = String(!!getCookie(CONTACT_EMAIL_COOKIE, false));
  const isCustomer = String(!!getCookie(CUSTOMER_ORDER_COUNT_COOKIE));
  const sessionId = getCookie(DY_SESSION_ID_COOKIE, false);
  const userId = getCookie(DY_USER_ID_COOKIE, false);

  const previewIds =
    new URL(params.initialRequest.url).searchParams.get('dyApiPreview') ?? undefined;

  let args: Record<string, { realtimeRules: RealtimeRule[] }> | undefined;
  if (params.realtimeRules) {
    args = Object.fromEntries(
      Object.entries(params.realtimeRules).map(([selector, rules]) => [
        selector,
        { realtimeRules: rules },
      ]),
    );
  }

  return {
    context: {
      device: {
        ip: params.initialRequest.ip,
        userAgent: params.initialRequest.userAgent,
      },
      page: {
        ...params.pageContext,
        locale: 'en_US',
        location: params.location,
        referrer: params.referrer ?? null,
      },
      pageAttributes: {
        ...params.additionalCustomAttributes,
        businessIndustry: params.customerContext.businessIndustry ?? 'null',
        hasBusinessAccount: String(params.customerContext.hasBusinessAccount),
        hasBusinessIndustry: String(!!params.customerContext?.businessIndustry),
        isB2bContact: String(params.customerContext.isB2bContact),
        isContact,
        isCustomer,
        landingPage: params.initialRequest.url,
        location: params.location,
        newSession: String(params.newSession),
        userHasBusinessAccount: String(params.customerContext.userHasBusinessAccount),
      },
    },
    options: {
      isImplicitPageview: params.implicitPageview ?? true,
      returnAnalyticsMetadata: true,
    },
    selector: {
      names: experienceNames,
      ...(previewIds && { preview: { ids: [previewIds] } }),
      ...(args && { args }),
    },
    session: {
      dy: sessionId,
    },
    user: {
      dyid: userId,
      dyid_server: userId,
    },
  };
}

export async function fetchDyChooseResults<
  T extends BaseChoice = JsonChoice | RecommendationsChoice,
>(experienceNames: string[], params: ChooseParams) {
  const retries = isBrowser ? 3 : 0;
  return retry(
    async () => {
      const response = await fetch(`${baseUrl}/serve/user/choose`, {
        body: JSON.stringify(buildRequestBody(experienceNames, params)),
        headers: {
          'Content-Type': 'application/json',
          'DY-API-Key': apiKey,
        },
        method: 'POST',
        signal: AbortSignal.timeout(requestTimeoutLimit),
      });

      if (!response.ok) {
        const message = (await response.text()) ?? `${response.status} ${response.statusText}`;
        throw new Error(`Failed to fetch DY choose results: ${message}`);
      }

      const data: ChooseResponse<T> = await response.json();
      return data;
    },
    {
      onError: (error) =>
        error.constructor === DOMException && ['AbortError', 'TimeoutError'].includes(error.name),
      retries,
    },
  );
}
