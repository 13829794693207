<!-- eslint-disable import/no-duplicates -->
<script setup lang="ts">
import { computed } from 'vue';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import { TWColorsText } from '@/utils/cms';

const props = defineProps<{
  listItems: { text: string }[];
  textColor?: string;
}>();

const color = computed(() => TWColorsText[props.textColor ?? '#333333']);
</script>

<script lang="ts">
// eslint-disable-next-line import/first, import/no-duplicates
import { BuilderComponent } from '@/utils/cms';

export const BulletedListRegistration: BuilderComponent = {
  name: 'Bulleted List',
  inputs: [
    {
      name: 'listItems',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'longText',
        },
      ],
    },
  ],
};
</script>

<template>
  <ul class="list-disc">
    <li v-for="(item, index) in listItems" :key="index">
      <BaseBodyText :class="color">
        {{ item.text }}
      </BaseBodyText>
    </li>
  </ul>
</template>
