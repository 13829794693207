/* eslint-disable import/prefer-default-export */

import { sentry } from '@/sentry';
import { gtag } from '@/utils/analytics';
/**
 * Helper to (a) log error to console and our server logs, (b) return a string
 * form of error for easy use without type guards.
 *
 * @param error Error object or message
 * @param message Optional message
 * @param otherAttributes to send
 * @returns string form of `error`, prefixed if `message` provided
 */

export function reportError(
  error: any,
  message?: string,
  otherAttributes?: Record<string, string | number>,
): string {
  // send to console
  if (message) {
    console.error(`${message}:`, error, otherAttributes);
  } else {
    console.error(error, otherAttributes);
  }

  // standardize error message and stack
  const errorMessage = error instanceof Error ? error.message : String(error ?? 'Unknown error');
  let stack = error instanceof Error ? error.stack : undefined;
  if (stack?.startsWith(`Error: ${errorMessage}\n`)) {
    stack = stack.slice(errorMessage.length + 8);
  }

  if (typeof window !== 'undefined') {
    // send to GA4
    gtag('event', 'exception', { description: errorMessage, fatal: false });

    // send to our logs
    window.nutsServerLog?.('report-error', {
      error: errorMessage,
      message,
      stack,
      ...otherAttributes,
    });
  }

  // send to Sentry
  let severity = String(otherAttributes?.severity);

  severity = ['debug', 'info', 'log', 'warning', 'error', 'fatal'].includes(severity)
    ? severity
    : 'warning';

  if (['error', 'fatal'].includes(String(severity))) {
    const exception =
      error instanceof Error ? { values: [{ type: error.name, value: error.message }] } : undefined;
    sentry?.captureEvent({
      message: errorMessage ?? message,
      level: 'error',
      exception,
      logentry: {
        message: message ?? errorMessage,
      },
      extra: otherAttributes,
    });
  }

  // return string form for easy use elsewhere
  return errorMessage;
}
