import { Subscription } from '@nuts/auto-delivery-sdk';
import { pivotAttributeValues } from '@nuts/auto-delivery-sdk/dist/utils/helpers';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { AnalyticsMetadata } from '@/lib/personalization/dynamicYield';
import { ProductSubscriptionCreated, productSubscriptionCreated } from '@/rudder-typer';
import { PageSection } from '@/utils/analytics/PageSection';

export interface NutsProductSubscriptionCreated {
  /**
   * Dynamic Yield's analyticsMetadata object from any test _directly_ involved
   * in getting the customer to subscribe to this product
   */
  analyticsMetadata?: AnalyticsMetadata;
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   * `Added to Auto-Delivery Modal` - User added a product to auto-delivery from the recommendations in added to auto delivery modal (using the flag `autoDeliveryUpsell`)
   * `Auto-Delivery Sign-Up Modal` - User signed up for auto-delivery from the impulse upsell flow
   * `Auto-Delivery Sign-Up Modal - Easy Reorder` - User signed up for auto-delivery from the easy reorder page
   * `Auto-Delivery Sign-Up Modal - Portal` - User signed up for auto-delivery from the impulse upsell flow in auto-delivery page
   */
  pageSection: PageSection<
    | 'Added to Auto-Delivery Modal'
    | 'Auto-Delivery Sign-Up Modal'
    | 'Auto-Delivery Sign-Up Modal - Easy Reorder'
    | 'Auto-Delivery Sign-Up Modal - Portal'
  >;
  /**
   * Product key (4 digits, e.g. 4003)
   *
   * Note: Unfortunately, this is not available synchronously in the
   * Subscription object. The cleanest would probably be for
   * formatProductSubscriptionCreated to be async and use
   * `(await subscription.product()).id` to get this.
   */
  productKey: string;
  /**
   * The actual Subscription which was just created
   */
  subscription: Subscription;
}

export function formatProductSubscriptionCreated({
  analyticsMetadata,
  pageSection,
  productKey,
  subscription,
}: NutsProductSubscriptionCreated): ProductSubscriptionCreated {
  return {
    currency: 'USD',
    dy_campaign_id: analyticsMetadata?.campaignId,
    dy_campaign_name: analyticsMetadata?.campaignName,
    dy_experience_id: analyticsMetadata?.experienceId,
    dy_experience_name: analyticsMetadata?.experienceName,
    dy_variation_id: analyticsMetadata?.variationId,
    dy_variation_name: analyticsMetadata?.variationName,
    interval: subscription.interval ?? 0,
    name: subscription.name.en,
    page_section: pageSection,
    price: dollars(subscription.currentPiecePrice),
    product_id: productKey, // FIXME: really ought to be able to get this from the Subscription...
    product_subscription_id: subscription.id,
    quantity: subscription.quantity,
    reporting_category: pivotAttributeValues(subscription.variant.attributes!).product
      .reportingCategory?.key,
    sku: subscription.sku,
  };
}

export function sendProductSubscriptionCreatedEvent(
  nutsProductSubscriptionCreated: NutsProductSubscriptionCreated,
) {
  productSubscriptionCreated(formatProductSubscriptionCreated(nutsProductSubscriptionCreated));
}
