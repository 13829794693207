import {
  ExpandedRecommendationsReference,
  ExpandedWebstoreRecommendationsReference,
} from '@/composables/dynamic-yield/recommendationsFromConfig';
import { RecommendationsChoice, RecommendationsSlot } from '@/lib/personalization/dynamicYield';

export type MixedSource =
  | ExpandedRecommendationsReference
  | ExpandedWebstoreRecommendationsReference
  | RecommendationsChoice;

export function isExpandedRecommendationsReference(
  source?: MixedSource,
): source is ExpandedRecommendationsReference {
  return source?.type === 'ExpandedRecommendationsReference';
}
function isExpandedWebstoreRecommendationsReference(
  source?: MixedSource,
): source is ExpandedWebstoreRecommendationsReference {
  return source?.type === 'ExpandedWebstoreRecommendationsReference';
}
function isRecommendationsChoice(source?: MixedSource): source is RecommendationsChoice {
  return source?.type === 'RECS_DECISION';
}

export function analyticsMetadata(source?: MixedSource) {
  if (isExpandedRecommendationsReference(source)) return source.analyticsMetadata;
  if (isRecommendationsChoice(source)) return source.variations[0]?.analyticsMetadata;
  return undefined;
}

export function decisionId(source?: MixedSource) {
  if (isExpandedRecommendationsReference(source) || isRecommendationsChoice(source))
    return source.decisionId;
  return undefined;
}

export function productSlotsFromChoice(source?: RecommendationsChoice): RecommendationsSlot[] {
  return source?.variations[0]?.payload.data.slots ?? [];
}

export function productSlotsFromExpandedReference(source?: ExpandedRecommendationsReference) {
  return source?.payload.data.slots ?? [];
}

export function recommendations(source: MixedSource) {
  if (isExpandedRecommendationsReference(source)) return productSlotsFromExpandedReference(source);
  if (isExpandedWebstoreRecommendationsReference(source)) return source.recommendations;
  if (isRecommendationsChoice(source)) return productSlotsFromChoice(source);
  return undefined;
}

export function title(source?: MixedSource) {
  if (isExpandedRecommendationsReference(source)) {
    return source.payload.data.custom?.title || source.title;
  }
  if (isExpandedWebstoreRecommendationsReference(source)) return source.title;
  if (isRecommendationsChoice(source)) return source.variations[0]?.payload.data.custom?.title;
  return undefined;
}
