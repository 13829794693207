/**
 * This client was automatically generated by RudderTyper. ** Do Not Edit **
 */

import type {
  RudderAnalytics,
  RudderAnalyticsPreloader,
  ApiOptions,
  ApiObject,
} from '@rudderstack/analytics-js'
/**
 * The analytics instance should be available via window.rudderanalytics.
 * You can install it by following instructions at: https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/installation/
 */
declare global {
  interface Window {
    rudderanalytics: RudderAnalytics | RudderAnalyticsPreloader | undefined
  }
}

type apiCallback = (data?: any) => void

/** The Schema object which is being used by Ajv to validate the message */
export interface Schema {
  $schema?: string
  description?: string
  properties?: object
  title?: string
  type?: string
}

export interface ButtonClicked {
  /**
   * Name of the button being clicked
   */
  button_name: string
  /**
   * Text content of the button
   */
  content?: string
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   */
  page_section?: string
}

export interface CartViewedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface CartViewed {
  /**
   * Cart ID of the cart being viewed (a UUID)
   */
  cart_id?: string
  /**
   * List of products displayed in the product list
   */
  products?: CartViewedProductsItem[]
  /**
   * Gross Total
   */
  total: number
}

export interface CheckoutStartedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Mame of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface CheckoutStarted {
  /**
   * Store or affiliation details from where the transaction occured
   */
  affiliation?: string
  /**
   * Coupon redeemed with the transaction
   */
  coupon?: string
  /**
   * Currency code associated with an order or transaction
   */
  currency?: string
  /**
   * Total discount associated with the transaction
   */
  discount?: number
  /**
   * Order ID or transaction ID, whichever is applicable
   */
  order_id?: string
  /**
   * List of products in the order or transaction
   */
  products?: CheckoutStartedProductsItem[]
  /**
   * Revenue associated with the transaction, excluding the shipping and tax details
   */
  revenue?: number
  /**
   * Shipping cost associated with the order or transaction
   */
  shipping?: number
  /**
   * Total tax associated with the order or the transaction
   */
  tax?: number
  /**
   * Revenue with discount and coupons factored in
   */
  value?: number
}

export interface CheckoutStepCompletedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Mame of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface CheckoutStepCompleted {
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Payment method
   */
  payment_method?: string
  /**
   * List of products in the order or transaction
   */
  products: CheckoutStepCompletedProductsItem[]
  /**
   * Chosen shipping method
   */
  shipping_method?: string
  /**
   * Chosen shipping option as presented to the user (e.g. "Ground", "Free Shipping", or "Two Business Days")
   */
  shipping_option?: string
  /**
   * Checkout process step identifier.  ('address' | 'delivery')
   */
  step: string
  /**
   * Gross Total
   */
  total: number
}

export interface CheckoutStepViewed {
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Payment method
   */
  payment_method?: string
  /**
   * Chosen shipping method
   */
  shipping_method?: string
  /**
   * Checkout process step number
   */
  step?: number
}

export interface ContactSubscribed {
  /**
   * Email address of the visitor
   */
  email?: string
  /**
   * Phone number of the visitor
   */
  phone?: string
  /**
   * Reason for subscribing to the contact form, examples could be an offer, signing up for a newsletter, etc.
   */
  reason?: string
  /**
   * Source of the contact form, examples could be a newsletter sign up, a quiz, a promotion offer, etc.
   */
  source?: string
}

export interface CouponApplied {
  /**
   * Cart ID the coupon was applied to
   */
  cart_id: string
  /**
   * Coupon code applied
   */
  coupon: string
  /**
   * Order ID or transaction ID, if applicable
   */
  order_id?: string
}

export interface ExperimentViewed {
  /**
   * Identifier of the testing platform top-level grouping; optional because the CMS and search engine do not use such a grouping and just serve a single experiment for the requested content.
   */
  campaign_id?: string
  /**
   * Name of the testing platform top-level grouping.
   */
  campaign_name?: string
  /**
   * Identifier of the direct test being run. For DY this would be the group that contains variations and declares general targeting criteria; in the CMS it will reflect the ID of the content entry; and search engine will reflect the ID of the test applied to results.
   */
  experiment_id: string
  /**
   * Name of the direct test being run. For the CMS it will reflect the name of the content entry; while the search engine will reflect the name of the test as displayed in the dashboard.
   */
  experiment_name?: string
  /**
   * Identifier of the variation served. May point to the control; for the CMS the control would match the experiment_id value.
   */
  variation_id: string
  /**
   * Name of the variation served. May point to the control; for the CMS the control may either match the experiment_name value or state Default.
   */
  variation_name?: string
}

export interface MenuLinkClicked {
  /**
   * Text displayed to users of the link that was clicked
   */
  link_text: string
  /**
   * Name of the current menu, defaulting to Main for the initial menu; otherwise reflecting the text displayed to users at the top of the sub-menu such as "Nuts & Seeds"
   */
  menu_name: string
  /**
   * Type of menu that the user is currently viewing, distinguishing between desktop and mobile.
   */
  source: string
}

export interface MenuViewed {
  /**
   * Name of the current menu, defaulting to Main for the initial menu; otherwise reflecting the text displayed to users at the top of the sub-menu such as "Nuts & Seeds"
   */
  menu_name: string
  /**
   * Type of menu that the user is currently viewing, distinguishing between desktop and mobile.
   */
  source: string
}

export interface OrderCancelledProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface OrderCancelled {
  /**
   * Store or affiliation details from where the transaction was started
   */
  affiliation?: string
  /**
   * Coupon which can be redeemed with the transaction
   */
  coupon?: string
  /**
   * Currency code associated with an order or transaction
   */
  currency?: string
  /**
   * Total discount associated with the transaction
   */
  discount?: number
  /**
   * Order ID or transaction ID, whichever is applicable
   */
  order_id: string
  /**
   * List of products in the order or transaction
   */
  products?: OrderCancelledProductsItem[]
  /**
   * Revenue associated with the transaction
   */
  revenue?: number
  /**
   * Shipping cost associated with the order or transaction
   */
  shipping?: number
  /**
   * Total tax associated with the order or the transaction
   */
  tax?: number
  /**
   * Revenue with the discount and coupons factored in
   */
  total?: number
}

export interface OrderPlacedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface OrderPlaced {
  /**
   * Store or affiliation details from where the transaction was started
   */
  affiliation?: string
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Coupon redeemed with the transaction
   */
  coupon?: string
  /**
   * Currency code associated with an order or transaction
   */
  currency?: string
  /**
   * Total discount associated with the transaction
   */
  discount?: number
  /**
   * Denotes if the order is the first order for the user
   */
  is_first_order?: boolean
  /**
   * Number of previous orders for an identified user. Omitted for anonymous users. Set to 0 for identified users with no orders. For users with purchase history, shows actual order count. Historical events maintain count accurate at event time.
   */
  order_count?: number
  /**
   * Order ID or transaction ID
   */
  order_id: string
  /**
   * The primary payment method used for the order (e.g. "American Express", "PayPal", "Gift Certificate", etc). When multiple payment types are used, the method covering the largest portion of the total cost is recorded.
   */
  payment_method?: string
  /**
   * List of products in the order or transaction
   */
  products?: OrderPlacedProductsItem[]
  /**
   * Revenue associated with the transaction
   */
  revenue?: number
  /**
   * Shipping cost associated with the order or transaction
   */
  shipping?: number
  /**
   * Order total after discounts but not including the taxes and shipping charges
   */
  subtotal?: number
  /**
   * Total tax associated with the order or the transaction
   */
  tax?: number
  /**
   * Revenue with the discount and coupons factored in
   */
  total?: number
}

export interface PaymentInfoEntered {
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Order ID, optionsal
   */
  order_id?: string
  /**
   * Payment method
   */
  payment_method?: string
  /**
   * Chosen shipping method
   */
  shipping_method?: string
  /**
   * Checkout process step number
   */
  step?: number
}

export interface ProductAdded {
  /**
   * Cart ID the product was added to (a UUID)
   */
  cart_id?: string
  /**
   * Coupon code associated with the variant
   */
  coupon?: string
  /**
   * Algolia search ID that delivered the product recommendations from which the product was added
   */
  list_algolia_search_id?: string
  /**
   * DY campaign ID that delivered the product recommendations from which the product was added
   */
  list_dy_campaign_id?: number
  /**
   * DY campaign name that delivered the product recommendations from which the product was added
   */
  list_dy_campaign_name?: string
  /**
   * DY experience ID that delivered the product recommendations from which the product was added
   */
  list_dy_experience_id?: number
  /**
   * DY experience name that delivered the product recommendations from which the product was added
   */
  list_dy_experience_name?: string
  /**
   * DY variation ID that delivered the product recommendations from which the product was added
   */
  list_dy_variation_id?: number
  /**
   * DY variation name that delivered the product recommendations from which the product was added
   */
  list_dy_variation_name?: string
  /**
   * Indicates which data feed was the source of the product
   */
  list_source?: string
  /**
   * The header seen by users if the product is in a list (e.g. `You may also like`, `Frequently Bought Together`, ...)
   */
  list_title?: string
  /**
   * Name of the product
   */
  name: string
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   */
  page_section?: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Single-piece price of variant
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Number of pieces added in this operation
   */
  quantity: number
  /**
   * Query searched by the user
   */
  query?: string
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface ProductAddedToWishlist {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with the product
   */
  coupon?: string
  /**
   * Name of the product
   */
  name?: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product (in USD)
   */
  price?: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id?: string
  /**
   * Quantity of the product
   */
  quantity?: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
  /**
   * Wishlist ID
   */
  wishlist_id?: string
  /**
   * Name of the wishlist the product was added to
   */
  wishlist_name?: string
}

export interface ProductClicked {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Algolia search ID that delivered the product recommendations from which the product was added
   */
  list_algolia_search_id?: string
  /**
   * DY campaign ID that delivered the product recommendations from which the product was added
   */
  list_dy_campaign_id?: number
  /**
   * DY campaign name that delivered the product recommendations from which the product was added
   */
  list_dy_campaign_name?: string
  /**
   * DY experience ID that delivered the product recommendations from which the product was added
   */
  list_dy_experience_id?: number
  /**
   * DY experience name that delivered the product recommendations from which the product was added
   */
  list_dy_experience_name?: string
  /**
   * DY variation ID that delivered the product recommendations from which the product was added
   */
  list_dy_variation_id?: number
  /**
   * DY variation name that delivered the product recommendations from which the product was added
   */
  list_dy_variation_name?: string
  /**
   * Indicates which data feed was the source of the product
   */
  list_source?: string
  /**
   * The header seen by users if the product is in a list (e.g. `You may also like`, `Frequently Bought Together`, ...)
   */
  list_title?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   */
  page_section?: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity?: number
  /**
   * Query searched by the user
   */
  query?: string
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface ProductListFilteredFiltersItem {
  /**
   * ID of the filter type that the customer is using
   */
  type?: string
  /**
   * ID of the selection chosen by the customer
   */
  value?: string
}

export interface ProductListFilteredProductsItem {
  /**
   * Brand name associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Name of the product being viewed by the user
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position: number
  /**
   * Price of the product being viewed (in USD)
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of a product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface ProductListFilteredSortsItem {
  /**
   * ID of the sort type used by the customer
   */
  type?: string
  /**
   * ID of the selection-type the customer is using
   */
  value?: string
}

export interface ProductListFiltered {
  /**
   * Product filters that the customer has applied
   */
  filters?: ProductListFilteredFiltersItem[]
  /**
   * Name of the product list being viewed
   */
  list_id?: string
  /**
   * Products displayed in the product list
   */
  products?: ProductListFilteredProductsItem[]
  /**
   * Product sorting used by the customer
   */
  sorts?: ProductListFilteredSortsItem[]
}

export interface ProductListViewedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position: number
  /**
   * Price of the product being viewed (in USD)
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface ProductListViewed {
  /**
   * Name of the product list being viewed
   */
  list_id?: string
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   */
  page_section?: string
  /**
   * Array of products displayed in the product list
   */
  products?: ProductListViewedProductsItem[]
}

export interface ProductPurchased {
  /**
   * Brand of the product
   */
  brand?: string
  /**
   * Cart ID associated with the product displayed
   */
  cart_id?: string
  /**
   * Product category being viewed
   */
  category?: string
  /**
   * Coupon code associated with the product
   */
  coupon?: string
  /**
   * Image url of the product
   */
  image_url?: string
  /**
   * Name of the product
   */
  name?: string
  /**
   * Position of product in the list
   */
  position?: number
  /**
   * Price of the product
   */
  price: number
  /**
   * Database ID of the product
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity?: number
  /**
   * Sku of the product
   */
  sku?: string
  /**
   * URL of the product page for product
   */
  url?: string
  /**
   * Variant of the product
   */
  variant?: string
}

export interface ProductRemoved {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Cart ID the product was removed from (a UUID)
   */
  cart_id?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface ProductsSearched {
  /**
   * Query searched by the user
   */
  query?: string
}

export interface ProductSubscriptionCreated {
  /**
   * Expected value: `USD`
   */
  currency?: string
  /**
   * DY campaign ID that either served a feature flag or Auto-Delivery recommendations to drive this subscription
   */
  dy_campaign_id?: number
  /**
   * DY campaign name that either served a feature flag or Auto-Delivery recommendations to drive this subscription
   */
  dy_campaign_name?: string
  /**
   * DY experience ID that either served a feature flag or Auto-Delvery recommendations to drive this subscription
   */
  dy_experience_id?: number
  /**
   * DY experience name that either served a feature flag or Auto-Delivery recommendations to drive this subscription
   */
  dy_experience_name?: string
  /**
   * DY variation ID that either served a feature flag or Auto-Delivery recommendations to drive this subscription
   */
  dy_variation_id?: number
  /**
   * DY variation name that either served a feature flag or Auto-Delivery recommendations to drive this subscription
   */
  dy_variation_name?: string
  /**
   * Interval between auto-deliveries (in days)
   */
  interval: number
  /**
   * Name of the product
   */
  name: string
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   */
  page_section: string
  /**
   * Single-piece price of variant
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * CT Shopping List Line Item ID (a UUID)
   */
  product_subscription_id: string
  /**
   * Number of pieces added in this operation
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface ProductViewed {
  /**
   * Cart ID the product was added to (a UUID)
   */
  cart_id?: string
  /**
   * (TBD)
   */
  coupon?: string
  /**
   * (TBD)
   */
  currency?: string
  /**
   * Name of the product
   */
  name: string
  /**
   * Single-piece price of variant
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  quantity?: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}

export interface PromotionClicked {
  /**
   * The text or image alt text or image filename the visitor saw/clicked
   */
  content: string
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Global Campaign Carousel`)
   */
  page_section: string
  /**
   * Slide/panel/promotion number if the promotion is in a list of promotions; index value starts at 1, not 0.
   */
  position?: number
  /**
   * Internal but human-meaningful identifier for a single promotion.
   */
  promotion_name: string
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: any[]
) => void

let analytics: () => any | undefined = () => {
  return window.rudderanalytics
}

/** Options to customize the runtime behavior of a RudderTyper client. */
export interface RudderTyperOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.rudderanalytics.
   */
  analytics?: any
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this RudderTyper client.
 *
 * @param {RudderTyperOptions} options - the options to upsert
 *
 * @typedef {Object} RudderTyperOptions
 * @property {Rudder.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setRudderTyperOptions(options: RudderTyperOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.rudderanalytics
    : analytics
}

/**
 * Helper to attach metadata on RudderTyper to outbound requests.
 * This is used for attribution and debugging by the RudderStack team.
 */
function withRudderTyperContext(message: ApiOptions = {}): ApiOptions {
  return {
    ...message,
    context: {
      ...((message.context as ApiObject) || {}),
      ruddertyper: {
        sdk: 'analytics.js',
        language: 'typescript',
        rudderTyperVersion: '1.2.0',
        trackingPlanId: 'tp_2oqj4hgNjJPQzlcDURpv2axlLLT',
        trackingPlanVersion: 2,
      },
    },
  }
}

/**
 * @typedef ButtonClicked
 * @property {string} button_name - Name of the button being clicked
 * @property {string} [content] - Text content of the button
 * @property {string} [page_section] - Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
 */
/**
 * @typedef CartViewedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef CartViewed
 * @property {string} [cart_id] - Cart ID of the cart being viewed (a UUID)
 * @property {CartViewedProductsItem[]} [products] - List of products displayed in the product list
 * @property {number} total - Gross Total
 */
/**
 * @typedef CheckoutStartedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Mame of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [affiliation] - Store or affiliation details from where the transaction occured
 * @property {string} [coupon] - Coupon redeemed with the transaction
 * @property {string} [currency] - Currency code associated with an order or transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} [order_id] - Order ID or transaction ID, whichever is applicable
 * @property {CheckoutStartedProductsItem[]} [products] - List of products in the order or transaction
 * @property {number} [revenue] - Revenue associated with the transaction, excluding the shipping and tax details
 * @property {number} [shipping] - Shipping cost associated with the order or transaction
 * @property {number} [tax] - Total tax associated with the order or the transaction
 * @property {number} [value] - Revenue with discount and coupons factored in
 */
/**
 * @typedef CheckoutStepCompletedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Mame of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [payment_method] - Payment method
 * @property {CheckoutStepCompletedProductsItem[]} products - List of products in the order or transaction
 * @property {string} [shipping_method] - Chosen shipping method
 * @property {string} [shipping_option] - Chosen shipping option as presented to the user (e.g. "Ground", "Free Shipping", or "Two Business Days")
 * @property {string} step - Checkout process step identifier.  ('address' | 'delivery')
 * @property {number} total - Gross Total
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [payment_method] - Payment method
 * @property {string} [shipping_method] - Chosen shipping method
 * @property {number} [step] - Checkout process step number
 */
/**
 * @typedef ContactSubscribed
 * @property {string} [email] - Email address of the visitor
 * @property {string} [phone] - Phone number of the visitor
 * @property {string} [reason] - Reason for subscribing to the contact form, examples could be an offer, signing up for a newsletter, etc.
 * @property {string} [source] - Source of the contact form, examples could be a newsletter sign up, a quiz, a promotion offer, etc.
 */
/**
 * @typedef CouponApplied
 * @property {string} cart_id - Cart ID the coupon was applied to
 * @property {string} coupon - Coupon code applied
 * @property {string} [order_id] - Order ID or transaction ID, if applicable
 */
/**
 * @typedef ExperimentViewed
 * @property {string} [campaign_id] - Identifier of the testing platform top-level grouping; optional because the CMS and search engine do not use such a grouping and just serve a single experiment for the requested content.
 * @property {string} [campaign_name] - Name of the testing platform top-level grouping.
 * @property {string} experiment_id - Identifier of the direct test being run. For DY this would be the group that contains variations and declares general targeting criteria; in the CMS it will reflect the ID of the content entry; and search engine will reflect the ID of the test applied to results.
 * @property {string} [experiment_name] - Name of the direct test being run. For the CMS it will reflect the name of the content entry; while the search engine will reflect the name of the test as displayed in the dashboard.
 * @property {string} variation_id - Identifier of the variation served. May point to the control; for the CMS the control would match the experiment_id value.
 * @property {string} [variation_name] - Name of the variation served. May point to the control; for the CMS the control may either match the experiment_name value or state Default.
 */
/**
 * @typedef MenuLinkClicked
 * @property {string} link_text - Text displayed to users of the link that was clicked
 * @property {string} menu_name - Name of the current menu, defaulting to Main for the initial menu; otherwise reflecting the text displayed to users at the top of the sub-menu such as "Nuts & Seeds"
 * @property {string} source - Type of menu that the user is currently viewing, distinguishing between desktop and mobile.
 */
/**
 * @typedef MenuViewed
 * @property {string} menu_name - Name of the current menu, defaulting to Main for the initial menu; otherwise reflecting the text displayed to users at the top of the sub-menu such as "Nuts & Seeds"
 * @property {string} source - Type of menu that the user is currently viewing, distinguishing between desktop and mobile.
 */
/**
 * @typedef OrderCancelledProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef OrderCancelled
 * @property {string} [affiliation] - Store or affiliation details from where the transaction was started
 * @property {string} [coupon] - Coupon which can be redeemed with the transaction
 * @property {string} [currency] - Currency code associated with an order or transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order ID or transaction ID, whichever is applicable
 * @property {OrderCancelledProductsItem[]} [products] - List of products in the order or transaction
 * @property {number} [revenue] - Revenue associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the order or transaction
 * @property {number} [tax] - Total tax associated with the order or the transaction
 * @property {number} [total] - Revenue with the discount and coupons factored in
 */
/**
 * @typedef OrderPlacedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef OrderPlaced
 * @property {string} [affiliation] - Store or affiliation details from where the transaction was started
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [coupon] - Coupon redeemed with the transaction
 * @property {string} [currency] - Currency code associated with an order or transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {boolean} [is_first_order] - Denotes if the order is the first order for the user
 * @property {number} [order_count] - Number of previous orders for an identified user. Omitted for anonymous users. Set to 0 for identified users with no orders. For users with purchase history, shows actual order count. Historical events maintain count accurate at event time.
 * @property {string} order_id - Order ID or transaction ID
 * @property {string} [payment_method] - The primary payment method used for the order (e.g. "American Express", "PayPal", "Gift Certificate", etc). When multiple payment types are used, the method covering the largest portion of the total cost is recorded.
 * @property {OrderPlacedProductsItem[]} [products] - List of products in the order or transaction
 * @property {number} [revenue] - Revenue associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the order or transaction
 * @property {number} [subtotal] - Order total after discounts but not including the taxes and shipping charges
 * @property {number} [tax] - Total tax associated with the order or the transaction
 * @property {number} [total] - Revenue with the discount and coupons factored in
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [order_id] - Order ID, optionsal
 * @property {string} [payment_method] - Payment method
 * @property {string} [shipping_method] - Chosen shipping method
 * @property {number} [step] - Checkout process step number
 */
/**
 * @typedef ProductAdded
 * @property {string} [cart_id] - Cart ID the product was added to (a UUID)
 * @property {string} [coupon] - Coupon code associated with the variant
 * @property {string} [list_algolia_search_id] - Algolia search ID that delivered the product recommendations from which the product was added
 * @property {number} [list_dy_campaign_id] - DY campaign ID that delivered the product recommendations from which the product was added
 * @property {string} [list_dy_campaign_name] - DY campaign name that delivered the product recommendations from which the product was added
 * @property {number} [list_dy_experience_id] - DY experience ID that delivered the product recommendations from which the product was added
 * @property {string} [list_dy_experience_name] - DY experience name that delivered the product recommendations from which the product was added
 * @property {number} [list_dy_variation_id] - DY variation ID that delivered the product recommendations from which the product was added
 * @property {string} [list_dy_variation_name] - DY variation name that delivered the product recommendations from which the product was added
 * @property {string} [list_source] - Indicates which data feed was the source of the product
 * @property {string} [list_title] - The header seen by users if the product is in a list (e.g. `You may also like`, `Frequently Bought Together`, ...)
 * @property {string} name - Name of the product
 * @property {string} [page_section] - Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Single-piece price of variant
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Number of pieces added in this operation
 * @property {string} [query] - Query searched by the user
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductAddedToWishlist
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of the product in the product list
 * @property {number} [price] - Price of the product (in USD)
 * @property {string} [product_id] - Product key (4 digits, e.g. 4003)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} [sku] - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 * @property {string} [wishlist_id] - Wishlist ID
 * @property {string} [wishlist_name] - Name of the wishlist the product was added to
 */
/**
 * @typedef ProductClicked
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [list_algolia_search_id] - Algolia search ID that delivered the product recommendations from which the product was added
 * @property {number} [list_dy_campaign_id] - DY campaign ID that delivered the product recommendations from which the product was added
 * @property {string} [list_dy_campaign_name] - DY campaign name that delivered the product recommendations from which the product was added
 * @property {number} [list_dy_experience_id] - DY experience ID that delivered the product recommendations from which the product was added
 * @property {string} [list_dy_experience_name] - DY experience name that delivered the product recommendations from which the product was added
 * @property {number} [list_dy_variation_id] - DY variation ID that delivered the product recommendations from which the product was added
 * @property {string} [list_dy_variation_name] - DY variation name that delivered the product recommendations from which the product was added
 * @property {string} [list_source] - Indicates which data feed was the source of the product
 * @property {string} [list_title] - The header seen by users if the product is in a list (e.g. `You may also like`, `Frequently Bought Together`, ...)
 * @property {string} name - Name of the product being viewed
 * @property {string} [page_section] - Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [query] - Query searched by the user
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductListFilteredFiltersItem
 * @property {string} [type] - ID of the filter type that the customer is using
 * @property {string} [value] - ID of the selection chosen by the customer
 */
/**
 * @typedef ProductListFilteredProductsItem
 * @property {string} [brand] - Brand name associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Name of the product being viewed by the user
 * @property {number} position - Position of the product in the product list
 * @property {number} price - Price of the product being viewed (in USD)
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of a product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductListFilteredSortsItem
 * @property {string} [type] - ID of the sort type used by the customer
 * @property {string} [value] - ID of the selection-type the customer is using
 */
/**
 * @typedef ProductListFiltered
 * @property {ProductListFilteredFiltersItem[]} [filters] - Product filters that the customer has applied
 * @property {string} [list_id] - Name of the product list being viewed
 * @property {ProductListFilteredProductsItem[]} [products] - Products displayed in the product list
 * @property {ProductListFilteredSortsItem[]} [sorts] - Product sorting used by the customer
 */
/**
 * @typedef ProductListViewedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Name of the product being viewed
 * @property {number} position - Position of the product in the product list
 * @property {number} price - Price of the product being viewed (in USD)
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductListViewed
 * @property {string} [list_id] - Name of the product list being viewed
 * @property {string} [page_section] - Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
 * @property {ProductListViewedProductsItem[]} [products] - Array of products displayed in the product list
 */
/**
 * @typedef ProductPurchased
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductRemoved
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [cart_id] - Cart ID the product was removed from (a UUID)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductsSearched
 * @property {string} [query] - Query searched by the user
 */
/**
 * @typedef ProductSubscriptionCreated
 * @property {string} [currency] - Expected value: `USD`
 * @property {number} [dy_campaign_id] - DY campaign ID that either served a feature flag or Auto-Delivery recommendations to drive this subscription
 * @property {string} [dy_campaign_name] - DY campaign name that either served a feature flag or Auto-Delivery recommendations to drive this subscription
 * @property {number} [dy_experience_id] - DY experience ID that either served a feature flag or Auto-Delvery recommendations to drive this subscription
 * @property {string} [dy_experience_name] - DY experience name that either served a feature flag or Auto-Delivery recommendations to drive this subscription
 * @property {number} [dy_variation_id] - DY variation ID that either served a feature flag or Auto-Delivery recommendations to drive this subscription
 * @property {string} [dy_variation_name] - DY variation name that either served a feature flag or Auto-Delivery recommendations to drive this subscription
 * @property {number} interval - Interval between auto-deliveries (in days)
 * @property {string} name - Name of the product
 * @property {string} page_section - Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
 * @property {number} price - Single-piece price of variant
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {string} product_subscription_id - CT Shopping List Line Item ID (a UUID)
 * @property {number} quantity - Number of pieces added in this operation
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductViewed
 * @property {string} [cart_id] - Cart ID the product was added to (a UUID)
 * @property {string} [coupon] - (TBD)
 * @property {string} [currency] - (TBD)
 * @property {string} name - Name of the product
 * @property {number} price - Single-piece price of variant
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} [quantity] -
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef PromotionClicked
 * @property {string} content - The text or image alt text or image filename the visitor saw/clicked
 * @property {string} page_section - Predefined keywords maintained in Webfront types (e.g. `Global Campaign Carousel`)
 * @property {number} [position] - Slide/panel/promotion number if the promotion is in a list of promotions; index value starts at 1, not 0.
 * @property {string} promotion_name - Internal but human-meaningful identifier for a single promotion.
 */

/**
 * This event is triggered when a visitor clicks on a button.
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
  props: ButtonClicked,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Button Clicked',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered whenever a visitor or customer views their shopping cart.
 *
 * @param {CartViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
  props: CartViewed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Cart Viewed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when an order or transaction is initiated.
 *
 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
  props?: CheckoutStarted,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Checkout Started',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a checkout step is completed.
 *
 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
  props: CheckoutStepCompleted,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Checkout Step Completed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a checkout step is completed.
 *
 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(
  props?: CheckoutStepViewed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Checkout Step Viewed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a visitor inputs their email address into a form.
 *
 * @param {ContactSubscribed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contactSubscribed(
  props: ContactSubscribed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Contact Subscribed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a coupon is applied to a cart or a transaction successfully
 *
 * @param {CouponApplied} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponApplied(
  props: CouponApplied,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Coupon Applied',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when there is a content decision, usually from systems including Dynamic Yield, Algolia, or Builder.io
 *
 * @param {ExperimentViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experimentViewed(
  props: ExperimentViewed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Experiment Viewed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event can be fired when a user clicks any link in a menu that would trigger navigation.
 *
 * @param {MenuLinkClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuLinkClicked(
  props: MenuLinkClicked,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Menu Link Clicked',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event can be fired when a user causes the menu to open up, or when opening a sub-menu (e.g. Nuts & Seeds).
 *
 * @param {MenuViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuViewed(
  props: MenuViewed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Menu Viewed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when an order is canceled.
 *
 * @param {OrderCancelled} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCancelled(
  props: OrderCancelled,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Cancelled',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when an order has been placed by the user.
 *
 * @param {OrderPlaced} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderPlaced(
  props: OrderPlaced,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Placed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when payment information is successfully entered.
 *
 * @param {PaymentInfoEntered} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(
  props?: PaymentInfoEntered,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Payment Info Entered',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a visitor adds a product to the cart.
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
  props: ProductAdded,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Added',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a customer adds a product to their shopping wishlist.
 *
 * @param {ProductAddedToWishlist} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAddedToWishlist(
  props?: ProductAddedToWishlist,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Added to Wishlist',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a visitor clicks on a product.
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(
  props: ProductClicked,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Clicked',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a visitor filters a list or category of products on your website or app.
 *
 * @param {ProductListFiltered} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(
  props?: ProductListFiltered,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product List Filtered',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a visitor views a list or category of products on your website or app.
 *
 * @param {ProductListViewed} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(
  props?: ProductListViewed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product List Viewed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductPurchased} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productPurchased(
  props: ProductPurchased,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Purchased',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a product is removed from the shopping cart by the customer.
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
  props: ProductRemoved,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Removed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event is triggered when a visitor searches for a product on your app/website.
 *
 * @param {ProductsSearched} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsSearched(
  props?: ProductsSearched,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Products Searched',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event can be fired once the auto-delivery is activated.
 *
 * @param {ProductSubscriptionCreated} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productSubscriptionCreated(
  props: ProductSubscriptionCreated,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Subscription Created',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * Not yet provided
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
  props: ProductViewed,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Product Viewed',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}
/**
 * This event can be fired when a promotional element is clicked
 *
 * @param {PromotionClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionClicked(
  props: PromotionClicked,
  options?: ApiOptions,
  callback?: apiCallback
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Promotion Clicked',
      props || {},
      withRudderTyperContext(options),
      callback
    )
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this RudderTyper client.
   *
   * @param {RudderTyperOptions} options - the options to upsert
   *
   * @typedef {Object} RudderTyperOptions
   * @property {Rudder.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setRudderTyperOptions,
  /**
   * This event is triggered when a visitor clicks on a button.
   *
   * @param {ButtonClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  buttonClicked,
  /**
   * This event is triggered whenever a visitor or customer views their shopping cart.
   *
   * @param {CartViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cartViewed,
  /**
   * This event is triggered when an order or transaction is initiated.
   *
   * @param {CheckoutStarted} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStarted,
  /**
   * This event is triggered when a checkout step is completed.
   *
   * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStepCompleted,
  /**
   * This event is triggered when a checkout step is completed.
   *
   * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStepViewed,
  /**
   * This event is triggered when a visitor inputs their email address into a form.
   *
   * @param {ContactSubscribed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contactSubscribed,
  /**
   * This event is triggered when a coupon is applied to a cart or a transaction successfully
   *
   * @param {CouponApplied} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  couponApplied,
  /**
   * This event is triggered when there is a content decision, usually from systems including Dynamic Yield, Algolia, or Builder.io
   *
   * @param {ExperimentViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  experimentViewed,
  /**
   * This event can be fired when a user clicks any link in a menu that would trigger navigation.
   *
   * @param {MenuLinkClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  menuLinkClicked,
  /**
   * This event can be fired when a user causes the menu to open up, or when opening a sub-menu (e.g. Nuts & Seeds).
   *
   * @param {MenuViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  menuViewed,
  /**
   * This event is triggered when an order is canceled.
   *
   * @param {OrderCancelled} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderCancelled,
  /**
   * This event is triggered when an order has been placed by the user.
   *
   * @param {OrderPlaced} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderPlaced,
  /**
   * This event is triggered when payment information is successfully entered.
   *
   * @param {PaymentInfoEntered} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentInfoEntered,
  /**
   * This event is triggered when a visitor adds a product to the cart.
   *
   * @param {ProductAdded} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAdded,
  /**
   * This event is triggered when a customer adds a product to their shopping wishlist.
   *
   * @param {ProductAddedToWishlist} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAddedToWishlist,
  /**
   * This event is triggered when a visitor clicks on a product.
   *
   * @param {ProductClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productClicked,
  /**
   * This event is triggered when a visitor filters a list or category of products on your website or app.
   *
   * @param {ProductListFiltered} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productListFiltered,
  /**
   * This event is triggered when a visitor views a list or category of products on your website or app.
   *
   * @param {ProductListViewed} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productListViewed,
  /**
   * User added a product to their shopping cart
   *
   * @param {ProductPurchased} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productPurchased,
  /**
   * This event is triggered when a product is removed from the shopping cart by the customer.
   *
   * @param {ProductRemoved} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productRemoved,
  /**
   * This event is triggered when a visitor searches for a product on your app/website.
   *
   * @param {ProductsSearched} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productsSearched,
  /**
   * This event can be fired once the auto-delivery is activated.
   *
   * @param {ProductSubscriptionCreated} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productSubscriptionCreated,
  /**
   * Not yet provided
   *
   * @param {ProductViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productViewed,
  /**
   * This event can be fired when a promotional element is clicked
   *
   * @param {PromotionClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  promotionClicked,
}

export const RudderTyperAnalytics = new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && Object.keys(target).includes(method)) {
      return target[method as keyof typeof clientAPI]
    }

    return () => {
      const a = analytics()
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withRudderTyperContext()
        )
      }
    }
  },
})
