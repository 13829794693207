import {
  confirmed,
  email as emailValidation,
  length,
  max_value as maxValue,
  min,
  min_value as minValue,
  numeric,
  regex,
  required,
} from '@vee-validate/rules';
import mailcheck from 'mailcheck';
import { defineRule, FieldMeta, FormMeta } from 'vee-validate';
import { computed, Ref, ref, unref } from 'vue';

import {
  AddressSuggestion,
  ExpandableAddressSuggestion,
  expandSuggestion,
  getAddressSuggestions,
} from '@/api/address/autocomplete';
import {
  addressFieldVariationsForCountry,
  NutsAddress,
  statesForCountry,
  validationRulesForCountry,
} from '@/utils/address';

defineRule('confirmed', confirmed);
defineRule('email', emailValidation);
defineRule('length', length);
defineRule('max_value', maxValue);
defineRule('min_value', minValue);
defineRule('min', min);
defineRule('numeric', numeric);
defineRule('regex', regex);
defineRule('required', (value: unknown) => required(value) || 'Please enter a value');

export type FlexibleMessageValidator<T> = (value: T) => true | string;

export const borderRules: { readonly [key: string]: string } = {
  width: 'border',
  style: 'border-solid',
  color: 'border-neutral-300',
  focusColor: 'focus:border-black',
  focusOutline: 'focus:outline-none',
};

export const fontRules: { readonly [key: string]: string } = {
  letterSpacing: 'leading-4 md:leading-4',
  placeholderColor: 'placeholder-transparent',
  fontSize: 'text-base',
  color: 'text-black',
};

export const sizeRules: { readonly [key: string]: string } = {
  height: 'h-11 md:h-12',
  padding: 'px-4',
  width: 'w-full',
};

function insertAtSymbol(typo: string) {
  const { defaultDomains, defaultSecondLevelDomains } = mailcheck;
  const matchedDomain = defaultDomains
    .concat(defaultSecondLevelDomains)
    .find((d) => typo.endsWith(d));
  if (matchedDomain) {
    return typo.replace(new RegExp(`2?${matchedDomain}$`), `@${matchedDomain}`);
  }
  return typo;
}

function handleEmailTypos(emailRef: Ref) {
  const email = emailRef;
  const suggestedEmail = ref<string | null>(null);
  const checkForSuggestions = () =>
    mailcheck.run({
      email: unref(email),
      suggested: (suggestion: MailcheckModule.ISuggestion) => {
        suggestedEmail.value = suggestion.full.replace(/%20/g, '');
      },
      empty: () => {
        let subject = unref(email);
        subject = subject.replace(/\s/g, '');
        if (!subject.includes('@')) subject = insertAtSymbol(subject);
        suggestedEmail.value = subject === email.value ? null : subject;
      },
    });
  const animationClasses = computed(() => {
    const utilities = ['duration-300', 'transition-transform', 'transform-gpu', 'translate-y-1'];
    if (suggestedEmail.value) utilities.push('shadow-sharp', 'translate-y-12');
    return utilities.join(' ');
  });
  return {
    acceptSuggestion: (inputElement?: HTMLElement) => {
      email.value = suggestedEmail.value;
      suggestedEmail.value = null;
      if (inputElement) inputElement.focus();
    },
    removeSuggestion: () => {
      suggestedEmail.value = null;
    },
    animationClasses,
    checkForSuggestions,
    suggestedEmail,
  };
}

const errorMessages = {
  genericRequire: {
    required: 'Please enter a value',
  },
  company: {
    required: 'Please enter your company name',
  },
  city: {
    required: 'Please enter your city',
  },
  email: {
    email: 'Please enter a valid email address',
    required: 'Please enter your email address',
  },
  name: {
    required: 'Please enter your full name',
  },
  password: {
    confirm: 'Password does not match',
    min: 'Your password needs to be at least {length} characters. Capitalization matters.',
    required: 'Please enter a password',
  },
  phone: {
    min: 'Phone number must be at least {length} characters',
    regex: 'Please enter a valid phone number',
    required: 'Please enter your phone number',
  },
  zipCode: {
    regex: 'Please enter a valid zip code',
    required: 'Please enter your zip code',
  },
  postalCode: {
    regex: 'Please enter a valid postal code',
    required: 'Please enter your postal code',
  },
  recipientEmail: {
    email: 'Please enter a valid email address',
    required: 'Please enter the recipient email address',
  },
  recipientEmailConfirmation: {
    confirmed: 'Please make sure the email address matches',
    email: 'Please enter a valid email address',
    required: 'Please confirm the recipient email address',
  },
  recipientName: {
    required: 'Please enter the recipient full name',
  },
  state: {
    required: 'Please enter your state',
  },
  province: {
    required: 'Please enter your province',
  },
  street1: {
    required: 'Please enter your street address',
  },
  quantity: {
    max_value: 'Quantity cannot exceed {max}',
    min_value: 'Quantity must be higher than {min}',
    numeric: 'Quantity must be a number',
  },
};

const validatorRegex = {
  phone: /^[\d()+\- ]+$/,
};

const countryCodes: readonly {
  readonly label: string;
  readonly value: string;
}[] = [
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' },
];

export function useAddressFields(addressModel?: Ref<NutsAddress>) {
  const initializeAddress = (initialAddress?: NutsAddress): NutsAddress => ({
    ...initialAddress,
    city: initialAddress?.city || '',
    country: initialAddress?.country || 'US',
    name: initialAddress?.name || '',
    company: initialAddress?.company || '',
    phone: initialAddress?.phone || '',
    postalCode: initialAddress?.postalCode || '',
    residential: initialAddress?.residential ?? undefined,
    state: initialAddress?.state || '',
    street1: initialAddress?.street1 || '',
    street2: initialAddress?.street2 || '',
  });

  const address = addressModel ?? ref();
  address.value = initializeAddress(address.value);

  const addressSuggestions = ref<AddressSuggestion[]>([]);
  const showSuggestions = ref(false);

  const declineAddressSuggestions = () => {
    showSuggestions.value = false;
  };

  const lookupAddress = async (addressFragment: string, coordinates?: string) => {
    if (addressFragment.length >= 3) {
      const country = address.value?.country || 'US';
      const suggestions = await getAddressSuggestions(
        addressFragment,
        country,
        country === 'US' ? coordinates : undefined,
      );
      addressSuggestions.value = suggestions;
      showSuggestions.value = suggestions.length > 0;
    } else {
      showSuggestions.value = false;
    }
  };

  const selectAddressSuggestion = async (
    suggestion: ExpandableAddressSuggestion,
  ): Promise<void> => {
    const expandedAddress = await expandSuggestion(suggestion, address.value?.country || 'US');
    if (expandedAddress) {
      Object.assign(address.value ?? {}, expandedAddress);
      showSuggestions.value = false;
    }
  };

  return {
    address,
    addressFieldVariationsForCountry: computed(() =>
      addressFieldVariationsForCountry(address.value?.country || 'US'),
    ),
    addressSuggestions,
    countryCodes,
    declineAddressSuggestions,
    initializeAddress,
    lookupAddress,
    selectAddressSuggestion,
    showSuggestions,
    statesOrProvinces: computed(() => {
      const dataSet = statesForCountry(address.value?.country || 'US');
      return Object.keys(dataSet).map((key) => ({ label: key, value: key }));
    }),
    validationRulesForCountry: computed(() =>
      validationRulesForCountry(address.value?.country || 'US'),
    ),
  };
}

export function useForm() {
  return {
    errorMessages,
    validatorRegex,
    handleEmailTypos,
    useStyles: (invalid: Ref<boolean>, size: Ref<'default' | 'small'>) => ({
      borderRules: computed(() => {
        const utilities = { ...borderRules };
        if (unref(invalid)) utilities.color = 'border-nuts-red-800';
        return Object.values(utilities);
      }),
      fontRules: computed(() => {
        const utilities = { ...fontRules };
        if (unref(invalid)) utilities.color = 'text-nuts-red-800';
        if (unref(size) === 'small') {
          utilities.fontSize = 'text-sm';
        }
        return Object.values(utilities);
      }),
      sizeRules: computed(() => {
        const utilities = { ...sizeRules };
        if (unref(size) === 'small') utilities.height = 'h-6 md:h-8';
        return Object.values(utilities);
      }),
    }),
    validatorFailed: (meta: FormMeta<any> | FieldMeta<any>) => {
      if ('initialValues' in meta) {
        return !meta.valid && (meta.dirty || meta.touched);
      }
      return meta.touched && !meta.valid && meta.validated;
    },
    validatorPassed: (meta: FormMeta<any> | FieldMeta<any>) => meta.valid,
  };
}
