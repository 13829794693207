<script lang="ts">
import { useElementSize } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import SliderNav from '@/components/base/SliderNav.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header4 from '@/components/base/typography/Header4.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { BuilderComponent, TWColorsText } from '@/utils/cms';

interface GapByBreakpoint {
  desktop?: number;
  mobile: number;
  tablet?: number;
}

interface Props {
  controlsColor?: string;
  flushRightOnMobile?: boolean;
  gap?: number;
  justifyContent?: 'around' | 'between' | 'center' | 'end' | 'start';
  layout?: 'grid' | 'row';
  link?: string;
  list?: any[];
  responsiveGaps?: GapByBreakpoint;
  scrollWidth?: number;
  subtitle?: string;
  title?: string;
  useResponsiveGaps?: boolean;
}

export const SliderRegistration: BuilderComponent = {
  canHaveChildren: true,
  name: 'Slider',
  inputs: [
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      defaultValue: 'Carousel Title',
      name: 'title',
      type: 'string',
    },
    {
      defaultValue: '(Optional) Carousel Subtitle',
      name: 'subtitle',
      type: 'string',
    },
    {
      defaultValue: false,
      name: 'useResponsiveGaps',
      type: 'boolean',
    },
    {
      defaultValue: 16,
      helperText: 'Number of pixels between each carousel entry',
      name: 'gap',
      showIf: (options) => !options.get('useResponsiveGaps'),
      type: 'number',
    },
    {
      defaultValue: {
        desktop: 16,
        mobile: 16,
        tablet: 16,
      },
      helperText: 'Number of pixels between each carousel entry',
      name: 'responsiveGaps',
      showIf: (options) => options.get('useResponsiveGaps'),
      subFields: ['mobile', 'tablet', 'desktop'].map((size) => ({
        name: size,
        required: size === 'mobile',
        type: 'number',
      })),
      type: 'object',
    },
    {
      advanced: true,
      defaultValue: true,
      friendlyName: 'Mobile: flush with right',
      helperText: 'Scroll entries to be flush with the right side of mobile devices',
      name: 'flushRightOnMobile',
      type: 'boolean',
    },
  ],
};
</script>

<script setup lang="ts">
const justifyClassesMap = {
  around: 'justify-around',
  between: 'justify-between',
  center: 'justify-center',
  end: 'justify-end',
  start: 'justify-start',
};

const props = withDefaults(defineProps<Props>(), {
  justifyContent: 'around',
  layout: 'row',
});

const nextDisabled = ref(false);
const prevDisabled = ref(true);
const scrollContainer = ref<HTMLElement>();
const { height } = useElementSize(scrollContainer);

const scrollByDirection = (direction: 'next' | 'prev') => {
  if (!scrollContainer.value) return;
  const { offsetWidth } = scrollContainer.value;

  const offset = props.scrollWidth ?? offsetWidth;
  const scrollFactor = direction === 'prev' ? -1 : 1;
  scrollContainer.value.scrollBy({ left: offset * scrollFactor, behavior: 'smooth' });
};

const updateCarouselControls = () => {
  if (!scrollContainer.value) return;
  const { offsetWidth, scrollLeft, scrollWidth } = scrollContainer.value;
  const offset = props.scrollWidth ?? offsetWidth;

  nextDisabled.value = scrollWidth <= offset + scrollLeft;
  prevDisabled.value = scrollLeft === 0;
};

const next = () => scrollByDirection('next');
const prev = () => scrollByDirection('prev');
const textColor = computed(() => TWColorsText[props.controlsColor ?? '#333333']);

onMounted(updateCarouselControls);

watch(() => height.value, updateCarouselControls);

watch(
  () => props.list ?? [],
  () => {
    if (!scrollContainer.value) return;
    scrollContainer.value.scrollTo({ left: 0, behavior: 'smooth' });
  },
  { deep: true },
);
</script>

<template>
  <div
    class="w-full overflow-x-scroll slider"
    data-test="recommendations-rows"
    :class="{ 'px-4 sm:px-0': flushRightOnMobile }"
  >
    <div class="flex flex-row items-end justify-between mb-4" data-test="slider-header">
      <div v-if="title" data-test="slider-title">
        <div class="inline-flex flex-wrap items-baseline">
          <Header4 :class="[textColor, { 'mr-2 md:mr-4': link }]" headerTag="h3">
            {{ title }}
          </Header4>
          <RouteLink v-if="link" :to="link" data-test="view-all-link">
            <SmallBodyText class="py-1" underline :class="[textColor]">View all</SmallBodyText>
          </RouteLink>
        </div>
        <BaseBodyText v-if="subtitle" class="mt-2 font-semibold">{{ subtitle }}</BaseBodyText>
      </div>
      <div
        class="flex items-center text-sm"
        :class="{ 'w-full justify-between md:justify-end': !title }"
      >
        <SmallBodyText
          v-if="list?.length"
          class="visible mr-3 ws-sm:block text-nuts-neutral-800"
          :class="[textColor, title && 'hidden']"
        >
          {{ list.length }} items
        </SmallBodyText>
        <div class="flex">
          <button
            aria-label="Prev"
            data-test="carousel-prev-button"
            :aria-hidden="prevDisabled || undefined"
            class="flex p-2 rotate-180 bg-transparent border-none cursor-pointer padding-none prev"
            :class="{ 'opacity-25': prevDisabled }"
            :disabled="prevDisabled || undefined"
            @click="prev"
          >
            <SliderNav :controlsColor="controlsColor" />
          </button>
          <button
            aria-label="Next"
            data-test="carousel-next-button"
            class="flex p-2 bg-transparent border-none cursor-pointer padding-none next"
            :class="{ 'opacity-25': nextDisabled }"
            :disabled="nextDisabled || undefined"
            @click="next"
          >
            <SliderNav :controlsColor="controlsColor" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="grid grid-flow-col grid-rows-1 gap-3 slider-contents snap-x snap-mandatory"
      :class="[
        { '-mr-4 sm:mr-0': flushRightOnMobile },
        { 'lg:grid-rows-2': layout === 'grid' },
        justifyClassesMap[justifyContent],
      ]"
      data-test="slider-contents"
      ref="scrollContainer"
      @scroll="updateCarouselControls"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider::-webkit-scrollbar {
  display: none;
}

.slider {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.slider-contents {
  gap: v-bind('`${(useResponsiveGaps ? responsiveGaps?.mobile: gap) ?? 0}px`');
  scroll-snap-type: x proximity;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;

  // CMS appends <style> tags to blocks, but we want to handle any viable element
  :deep(> :not(style):last-of-type) {
    margin-right: 16px;
    @media screen and (min-width: $tailwind-sm-min) {
      margin-right: 0;
    }
  }

  @media screen and (min-width: $tailwind-sm-min) {
    gap: v-bind(
      '`${(useResponsiveGaps ? (responsiveGaps?.tablet ?? responsiveGaps?.mobile) : gap) ?? 0}px`'
    );
  }
  @media screen and (min-width: $tailwind-lg-min) {
    gap: v-bind(
      '`${(useResponsiveGaps ? (responsiveGaps?.desktop ?? responsiveGaps?.tablet ?? responsiveGaps?.mobile) : gap) ?? 0}px`'
    );
  }
}
</style>
