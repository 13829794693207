<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import AccountIcon from '@/components/base/assets/AccountIcon.vue';
import CartIcon from '@/components/base/assets/CartIcon.vue';
import ReorderIcon from '@/components/base/assets/ReorderIcon.vue';
import Container from '@/components/base/layout/Container.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import CampaignCarousel from '@/components/layout/header/CampaignCarousel.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useCart } from '@/composables/useCart';
import { useChat } from '@/composables/useChat';
import { useCheckout } from '@/composables/useCheckout';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { useReferralElement } from '@/composables/useReferralElement';
import { useCustomer } from '@/stores/customer';
import { useSession } from '@/stores/session';
import { guardClickEvent } from '@/utils/browser';
import { GlobalCampaignCarousel } from '@/utils/cms';

// Note: make `theme` required if layoutRebrandingChangesV1 wins
const props = defineProps<{
  campaigns?: GlobalCampaignCarousel['items'];
  isCarouselLoading: boolean;
  theme?: 'black' | 'yellow';
}>();

const router = useRouter();
const store = useStore();

const { preCheckoutLineItems: lineItems } = useCart(store);
const { displayChatInfoText, initChat, isChatStatusPending, openChat } = useChat();
const { cxMode } = useCheckout(store);
const { flags } = useFeatureFlags();
const { handleOpen } = useLoginModal();
const { navigateTo } = useRouteChange(router);
const { userFirstName } = storeToRefs(useSession());

const referralLink = ref<HTMLElement>();
const topShelfLinks = ref<HTMLElement>();

const requireUser = (event: MouseEvent) => {
  if (!guardClickEvent(event)) return;
  const destination = event.currentTarget?.getAttribute('href') || undefined;

  if (useCustomer().customer?.id || cxMode.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
};

const getDataPromo = (name: string) => ({
  'data-promo': '1',
  'data-promo-creative': 'Header Links',
  'data-promo-name': `Utility Links: ${name}`,
});

onMounted(initChat);

const { showReferralElement } = useReferralElement(
  referralLink,
  flags.layoutRebrandingChangesV1 ? 'global_header_rebrandv1' : 'global_header_redesignv1',
);

const cartIcon = nutshell['img/sprite-cart-white.svg'];
const easyReorderIcon = nutshell['img/rasterized/sprite-easy-reorder-icon@1x.png'];
const accountIcon = nutshell['img/sprite-tiny-peanut-white.svg'];

useRouterLinks(topShelfLinks, router);

const backgroundColor = computed(() => {
  if (props.theme === 'black') return 'bg-nuts-neutral-950';
  if (props.theme === 'yellow') return 'bg-nuts-amber-400';
  return 'bg-nuts-neutral-950';
});

const foregroundColor = computed(() => {
  if (props.theme === 'black') return 'text-white';
  if (props.theme === 'yellow') return 'text-black';
  return 'text-nuts-cyan-400';
});
</script>

<template>
  <div
    class="fixed inset-x-0 top-0 z-20 transition-colors duration-300"
    :class="backgroundColor"
    data-test="top-shelf"
  >
    <Container tag="nav" aria-label="Top shelf">
      <div class="flex items-center justify-between" ref="topShelfLinks">
        <CampaignCarousel :items="campaigns" :isLoading="isCarouselLoading || isChatStatusPending">
          <template v-if="!isChatStatusPending">
            <SmallBodyText
              v-if="displayChatInfoText"
              :class="!theme ? 'text-white' : foregroundColor"
              data-test="chat-text"
            >
              Order online or via
              <UnstyledButton
                class="underline"
                :class="[!theme ? 'text-white' : foregroundColor, { 'rebranded-link': theme }]"
                data-test="open-live-chat"
                @click="openChat"
              >
                live chat
              </UnstyledButton>
            </SmallBodyText>
            <a v-else href="/bestsellers/">
              <SmallBodyText> Discover our Best-Selling Favorites </SmallBodyText>
            </a>
          </template>
        </CampaignCarousel>

        <ul class="inline-flex items-center ml-auto gap-x-6" data-test="persistent-nav">
          <li v-if="showReferralElement" data-test="referral-link">
            <a
              href="/refer"
              class="top-shelf-link"
              :class="[
                {
                  'py-0.5': !flags.layoutRebrandingChangesV1,
                },
                theme,
              ]"
              ref="referralLink"
              v-bind="getDataPromo('Refer & Earn')"
            >
              <SmallBodyText class="hover:underline">Refer &amp; Get $20</SmallBodyText>
            </a>
          </li>

          <li data-test="nuts-for-business">
            <a
              :class="{ 'py-0.5': !flags.layoutRebrandingChangesV1 }"
              href="/wholesale/"
              v-bind="getDataPromo('Nuts for Business')"
            >
              <SmallBodyText
                class="hover:underline"
                :class="[foregroundColor, { 'rebranded-link': theme }]"
              >
                Nuts For Business
              </SmallBodyText>
            </a>
          </li>

          <li class="pr-6 border-r border-solid border-nuts-neutral-700" data-test="help">
            <a
              :class="{ 'py-0.5': !flags.layoutRebrandingChangesV1 }"
              :href="flags.enableGladly ? '/help' : 'https://help.nuts.com'"
              v-bind="getDataPromo('Need Help?')"
            >
              <SmallBodyText
                class="hover:underline"
                :class="[foregroundColor, { 'rebranded-link': theme }]"
              >
                Help
              </SmallBodyText>
            </a>
          </li>

          <li
            class="inline-flex items-center justify-center gap-x-1"
            :class="{ 'py-0.5': !flags.layoutRebrandingChangesV1 }"
            data-test="user"
          >
            <AccountIcon
              v-if="flags.layoutRebrandingChangesV1"
              :class="foregroundColor"
              data-test="top-shelf-account-icon"
              :size="24"
            />
            <img
              v-else
              alt=""
              class="object-contain w-5 h-5"
              data-test="peanut-image"
              :src="accountIcon"
            />
            <template v-if="userFirstName">
              <SmallBodyText :class="!theme ? 'text-white' : foregroundColor">Hi</SmallBodyText>
              <a href="/account" data-test="top-shelf-account-link">
                <SmallBodyText
                  class="hover:underline"
                  :class="[foregroundColor, { 'rebranded-link': theme }]"
                >
                  {{ userFirstName }}
                </SmallBodyText>
              </a>
              <SmallBodyText aria-hidden="true" :class="!theme ? 'text-white' : foregroundColor">
                •
              </SmallBodyText>
              <a href="/sign/out" class="g_id_signout">
                <SmallBodyText
                  class="hover:underline"
                  :class="[foregroundColor, { 'rebranded-link': theme }]"
                  data-test="sign-out"
                >
                  Sign out
                </SmallBodyText>
              </a>
            </template>
            <a
              v-else
              href=""
              data-test="sign-in-link"
              @click.prevent="requireUser"
              v-bind="getDataPromo('Sign in to your account')"
            >
              <SmallBodyText
                class="hover:underline"
                :class="[foregroundColor, { 'rebranded-link': theme }]"
              >
                Sign in to your account
              </SmallBodyText>
            </a>
          </li>

          <li class="flex" data-test="easy-order">
            <a
              href="/account/easy-reorder"
              class="inline-flex items-center justify-center"
              :class="{ 'py-0.5': !flags.layoutRebrandingChangesV1 }"
              @click.prevent="requireUser"
              v-bind="getDataPromo('Easy Reorder')"
            >
              <ReorderIcon
                v-if="flags.layoutRebrandingChangesV1"
                :class="foregroundColor"
                data-test="top-shelf-easy-reorder-icon"
                :size="24"
              />
              <img
                v-else
                alt=""
                class="object-contain w-5 h-5"
                data-test="easy-reorder-image"
                :src="easyReorderIcon"
              />
              <SmallBodyText
                class="content-center h-full ml-1 hover:underline"
                :class="[foregroundColor, { 'rebranded-link': theme }]"
              >
                Easy Reorder
              </SmallBodyText>
            </a>
          </li>

          <li class="relative flex" id="cart-utility-link">
            <UnstyledButton
              class="inline-flex items-center justify-center"
              :class="flags.layoutRebrandingChangesV1 ? 'py-1.5' : 'py-2.5'"
              data-test="cart"
              @click="navigateTo('/cart')"
              v-bind="getDataPromo('Your Cart')"
            >
              <CartIcon
                v-if="flags.layoutRebrandingChangesV1"
                :class="foregroundColor"
                data-test="top-shelf-cart-icon"
                :size="24"
              />
              <img
                v-else
                alt=""
                class="object-contain w-5 h-5"
                data-test="cart-image"
                :src="cartIcon"
              />
              <span
                v-if="lineItems.length"
                class="px-1.5 rounded-xl text-tiny -ml-2 min-w-[1.25rem]"
                :class="{
                  'text-white bg-red-600 py-0.5': !theme,
                  'text-black bg-nuts-amber-400 relative -top-2 -left-1': theme === 'black',
                  'text-black bg-white relative -top-2 -left-1': theme === 'yellow',
                }"
              >
                <span aria-hidden="true" class="text-xs font-semibold leading-tight text-center">
                  {{ lineItems.length }}
                </span>
              </span>
              <SmallBodyText
                class="ml-1 hover:underline"
                :class="[foregroundColor, { 'rebranded-link': theme }]"
              >
                <span class="sr-only">{{ lineItems.length }} items in</span>Your Cart
              </SmallBodyText>
            </UnstyledButton>
          </li>
        </ul>
      </div>
    </Container>
  </div>
</template>

<style scoped lang="scss">
.rebranded-link {
  @apply hover:font-semibold h-full content-center;
}

.top-shelf-link {
  @apply text-nuts-cyan-400;

  &.black {
    @apply text-white hover:font-semibold;
  }

  &.yellow {
    @apply text-black hover:font-semibold;
  }
}
</style>
