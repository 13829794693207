<script setup lang="ts">
import { Money } from '@commercetools/platform-sdk';
import { cents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed } from 'vue';

import DiscountBadge, { Size } from '@/components/base/DiscountBadge.vue';
import Caption from '@/components/base/typography/Caption.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import money from '@/filters/money';
import { MoneyRange } from '@/utils/productCard';

const props = defineProps<{
  comparisonPrice?: string | Money; // TODO: stop supporting strings
  discountDisplayValue?: string;
  discountType?: 'product';
  displayDiscount: boolean;
  onePoundBulk?: boolean;
  piecePrice: number | Money;
  piecePriceRange?: MoneyRange;
  size?: Size;
}>();

const { flags } = useFeatureFlags();

const displayPrice = computed<string | undefined>(() => {
  if (props.piecePriceRange) {
    const { from, to } = props.piecePriceRange;
    return `${money(from)} - ${money(to)}`;
  }
  if (props.piecePrice === 0) return undefined;
  if (typeof props.piecePrice === 'object' && cents(props.piecePrice) === 0) return undefined;
  const price = money(props.piecePrice);
  return props.onePoundBulk && !flags.testVariantNameDisplay ? `${price}/lb` : price;
});

const strikethroughPrice = computed<string | undefined>(() => {
  if (!props.comparisonPrice || !props.displayDiscount) return undefined;
  if (typeof props.comparisonPrice === 'string') return props.comparisonPrice;
  const price = money(props.comparisonPrice);
  return props.onePoundBulk && !flags.testVariantNameDisplay ? `${price}/lb` : price;
});
</script>

<template>
  <div class="flex flex-wrap items-center" data-test="product-discount">
    <Header6 :class="{ 'text-nuts-red-800': discountType === 'product' }" data-test="product-price">
      <template v-if="displayPrice">
        <span v-if="displayDiscount" class="sr-only">Discounted price of </span>
        {{ displayPrice }}
      </template>
      <template v-else>Build your own</template>
    </Header6>
    <template v-if="strikethroughPrice">
      <DiscountBadge class="mx-1 md:mx-1.5" :discountDisplayValue :discountType :size />
      <Caption class="text-neutral-500" data-test="strikethrough-price">
        <span class="sr-only">{{ discountDisplayValue }} off {{ strikethroughPrice }} </span>
        <del aria-hidden="true">{{ strikethroughPrice }}</del>
      </Caption>
    </template>
  </div>
</template>
