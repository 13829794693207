<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const TopCategoryCard = defineComponent({
  name: 'TopCategoryCard',
  props: {
    label: { required: true, type: String, default: 'Headline' },
    link: { required: true, type: String, default: 'nuts.com' },
    img: { required: true, type: String },
  },
});

export const TopCategoryCardRegistration: CmsRegisteredComponent = {
  component: TopCategoryCard,
  name: 'Top Category Card',
  inputs: [
    {
      name: 'label',
      type: 'string',
      defaultValue: 'Category',
    },
    {
      name: 'link',
      friendlyName: 'Category Link',
      type: 'string',
    },
    {
      name: 'img',
      friendlyName: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
  ],
};

export default TopCategoryCard;
</script>

<template>
  <RouterLink
    :to="link"
    class="flex no-underline rounded-md color-inherit hover:no-underline bg-nuts-orange-50 h-36 w-36 md:h-48 md:w-48 shrink-0"
  >
    <div class="relative flex flex-col w-full h-full">
      <img :src="img" :alt="label" class="flex flex-1 object-contain w-full" loading="lazy" />
    </div>
  </RouterLink>
</template>
