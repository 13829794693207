import { PromotionClicked, promotionClicked } from '@/rudder-typer';
import { PageSection } from '@/utils/analytics/PageSection';

type CampaignClickedPageSection = PageSection<
  'Floating Sticky CTA' | 'Global Campaign Carousel' | 'Hero Banner'
>;
export interface NutsPromotion extends Omit<PromotionClicked, 'page_section'> {
  readonly pageSection: CampaignClickedPageSection;
}

function isCampaignClickedPageSection(
  pageSection?: string,
): pageSection is CampaignClickedPageSection {
  if (!pageSection) return true;
  return ['Floating Sticky CTA', 'Global Campaign Carousel', 'Hero Banner'].includes(pageSection);
}

export function sendPromotionClickedEvent({ pageSection, ...eventProps }: NutsPromotion) {
  if (!isCampaignClickedPageSection(pageSection)) {
    reportError(`Incorrect pageSection for Campaign Clicked event: ${pageSection}`);
    return;
  }
  promotionClicked({
    ...eventProps,
    page_section: pageSection,
  });
}
