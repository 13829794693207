import createDebug from 'debug';

const debug = createDebug('nuts:localStorage');

export function useLocalStorage(namespace = '') {
  if (typeof window === 'undefined') {
    throw Error('This helper is client-side only');
  }

  if (!window.localStorage) {
    throw new Error('Local Storage is not available');
  }

  const getKey = (key: string) => (namespace ? `${namespace}.${key}` : `${key}`);

  return {
    set: <T>(key: string, value: T | T[]) => {
      const namespacedKey = getKey(key);
      debug(`setting ${namespacedKey}: ${value}`);
      window.localStorage.setItem(namespacedKey, JSON.stringify(value));
    },
    get: <T>(key: string): T | null => {
      const namespacedKey = getKey(key);
      debug(`getting ${namespacedKey}`);
      const value = window.localStorage.getItem(namespacedKey);
      if (value === null) {
        return null;
      }
      const json = JSON.parse(value);
      if (typeof json !== undefined) {
        return json;
      }
      throw new Error(`Invalid data found for key ${getKey(key)}`);
    },
    remove: (key: string) => {
      const namespacedKey = getKey(key);
      debug(`removing ${namespacedKey}`);
      window.localStorage.removeItem(namespacedKey);
    },
  };
}
