export function waitABit({ timeout = 2000, window: passedWindow } = {}) {
  return new Promise((resolve) => {
    const w = passedWindow || window;
    if (w) {
      w.addEventListener('load', resolve, { once: true });
    }
    if (timeout) {
      setTimeout(resolve, timeout);
    } else if (!w) {
      resolve();
    }
  });
}
