<script setup lang="ts">
import { HeadAttrs, useHead } from '@vueuse/head';
import compact from 'lodash/compact';
import { storeToRefs } from 'pinia';
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { init as initAlgoliaClient } from '@/api/algolia';
import { getConfigEntry } from '@/api/config';
import PageFooter from '@/components/layout/footer/Footer.vue';
import BaseHeader from '@/components/layout/header/BaseHeader.vue';
import FlashMessages from '@/components/layout/notifications/FlashMessages.vue';
import CompletedReviewsModal from '@/components/reviews/CompletedReviewsModal.vue';
import ExpiredLinkModal from '@/components/reviews/ExpiredLinkModal.vue';
import { useDataPromo } from '@/composables/analytics/useDataPromo';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { PageTypeMetadata } from '@/router/routes';
import { useLayout } from '@/stores/layout';
import { useNotifications } from '@/stores/notifications';
import { isDefined } from '@/utils/isDefined';

const props = defineProps<{
  logoPath?: string;
  isAdmin?: boolean;
  fallBackLogoPath?: string;
}>();

const route = useRoute();
const router = useRouter();
const { displayFooter, displayHeader } = storeToRefs(useLayout());

const displayCompletedReviewsModal = ref(!!route.query.hasCompletedReviews);
const displayExpiredLinkModal = ref(!!route.query.reviewsLinkExpired);
const justCompletedReviews = ref(!!route.query.justCompletedReviews);
const orderDate = ref(route.query.orderDate?.toString() ?? '');
const totalSkusCount = ref(route.query.totalSkusCount?.toString() ?? '');

const { flags } = useFeatureFlags();
const { dismissAllNotifications, dismissAllUnkeyedNotifications } = useNotifications();

provide('algoliaClient', initAlgoliaClient());
provide('isAdmin', props.isAdmin);

const inTransition = ref(false);
const mountedClass = ref('');

const disableFlashMessages = computed(() =>
  ['Checkout', 'CheckoutReceipt', 'SignIn'].includes(route.name as string),
);

const fonts = [
  nutshell['fonts/proxima-nova-regular.woff2'],
  nutshell['fonts/proxima-nova-semibold.woff2'],
  nutshell['fonts/sofia-pro-semi-bold.woff2'],
  nutshell['fonts/sofia-pro-bold.woff2'],
  // sofia-pro-regular skipped because we barely use it
].map<HeadAttrs>((href) => ({
  rel: 'preload',
  as: 'font',
  type: 'font/woff2',
  crossorigin: true,
  href,
}));

const hasFooterOverride = computed(() => 'pageFooter' in (route.matched[0]?.components ?? {}));
const hasHeaderOverride = computed(() => 'pageHeader' in (route.matched[0]?.components ?? {}));

const { sitekey } = getConfigEntry('google').recaptcha.v3.invisible;

useDataPromo();

useHead({
  title: 'Nuts.com',
  link: computed(() => {
    const resolvedCss = compact((route.meta as PageTypeMetadata['meta']).webstoreAssets?.css);
    return [
      ...fonts,
      {
        rel: 'stylesheet',
        href: 'https://use.typekit.net/qjh0ikv.css',
      },
      {
        rel: 'stylesheet',
        href: nutshell['css/main.css'],
      },
      ...resolvedCss.map((href) => ({
        rel: 'stylesheet',
        href,
      })),
    ];
  }),
  meta: [
    {
      content:
        'Nuts.com is a family-owned business offering the highest quality nuts, snacks, dried fruit and pantry staples at home, in the office and on-the-go!',
      key: 'og:description',
      property: 'og:description',
    },
    {
      content: 'https://nuts.com/images/email/2024/WebstoreImage.1eaa3799.png',
      key: 'og:image',
      property: 'og:image',
    },
    {
      content:
        'Nuts.com | Bulk & Snack-size Nuts, Dried Fruits, Sweets, Specialty Baking Products & More!',
      key: 'og:title',
      property: 'og:title',
    },
    {
      content: 'https://nuts.com/',
      key: 'og:url',
      property: 'og:url',
    },
  ],
  script: computed(() => {
    interface Script {
      async?: boolean;
      defer?: boolean;
      src: string;
    }

    const scripts: Script[] = [
      {
        src: `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`,
        async: true,
      },
      {
        src: `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit`,
        async: true,
      },
      {
        src: `https://cdn.shopify.com/shopifycloud/shop-js/shop-pay-payment-request.js`,
        async: true,
      },
    ];

    if (flags.enableConstructorBeacon) {
      scripts.push({
        src: 'https://cnstrc.com/js/cust/nuts-dot-com_M2spnh.js',
        defer: true,
      });
    }

    return scripts;
  }),
  bodyAttrs: computed(() => {
    const activeFlags = Object.keys(flags).filter((flag) => flags[flag]);

    if (!isDefined(flags.socialProofMessaging)) {
      return { class: activeFlags };
    }

    return {
      class: activeFlags,
      'data-tagg-experience': flags.socialProofMessaging ? 'treatment-v1' : 'control',
    };
  }),
});

onMounted(() => {
  mountedClass.value = 'page-mounted';

  router.beforeResolve(() => {
    if (window.innerWidth > 768) {
      inTransition.value = true;
    }
  });

  watch(
    () => route.fullPath,
    () => {
      inTransition.value = false;
    },
  );
  watch(
    () => route.path,
    (dest) => {
      if (dest.startsWith('/checkout/')) dismissAllUnkeyedNotifications();
      else dismissAllNotifications();
    },
  );
});
</script>

<template>
  <div :class="mountedClass">
    <VueAnnouncer class="sr-only" />
    <template v-if="displayHeader">
      <RouterView v-if="hasHeaderOverride" name="pageHeader" />
      <BaseHeader v-else />
    </template>
    <FlashMessages v-if="!disableFlashMessages" />
    <div class="sitewide-zone" v-once />
    <div>
      <RouterView role="main" />
      <CompletedReviewsModal
        :isOpen="displayCompletedReviewsModal"
        :justCompletedReviews
        :orderDate
        :totalSkusCount
        @handle-close="displayCompletedReviewsModal = false"
      />
      <ExpiredLinkModal
        :isOpen="displayExpiredLinkModal"
        @handle-close="displayExpiredLinkModal = false"
      />
    </div>
    <div v-if="inTransition" @mousemove="inTransition = false" class="transition-cursor-blocker" />
    <template v-if="displayFooter">
      <RouterView v-if="hasFooterOverride" name="pageFooter" />
      <PageFooter v-else />
    </template>
  </div>
</template>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
