import { fromNutsJson, NutsJson, webstore } from '@/api';

export async function getBraintreeToken() {
  const { data } = await fromNutsJson(
    webstore.get<NutsJson<{ token: string }>>('/api/braintree-client-token'),
  );
  return data.token;
}

export async function getIsFirstOrder(receiptToken: string) {
  const { data } = await fromNutsJson(
    webstore.get<NutsJson<{ isFirstOrder: boolean; orderCount: number }>>(
      '/api/orders/is-first-order',
      {
        params: { receiptToken },
      },
    ),
  );
  return data;
}

export async function getSafcToken(email?: string) {
  const customer = email ? `email=${email}` : 'me';
  return webstore.get<NutsJson<{ safcToken: string | null }>>(
    `/api/customers/${customer}/safc-token`,
  );
}
