<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const SmallBodyText = defineComponent({
  name: 'SmallBodyText',
  props: {
    underline: { required: false, type: Boolean, default: false },
  },
});

export const SmallBodyTextRegistration: CmsRegisteredComponent = {
  component: SmallBodyText,
  name: 'Small Body Text',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Small Body text here' } },
    },
  ],
  inputs: [
    {
      name: 'underline',
      type: 'boolean',
      defaultValue: false,
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default SmallBodyText;
</script>

<template>
  <p class="text-xs leading-4 lg:text-sm" :class="{ underline: underline }">
    <slot />
  </p>
</template>

<style scoped>
p {
  @apply mb-0;
}
</style>
