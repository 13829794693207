import chunk from 'lodash/chunk';

import encoding from '@/utils/encoding';
import { isDefined } from '@/utils/isDefined';
import { Money } from '@/utils/money';

export interface Category {
  readonly name: string;
  readonly filter: string;
  readonly children: Category[];
}

// eslint-disable-next-line no-shadow
export enum Sort {
  Popularity = 'popularity',
  Price = 'price',
}

export interface BaseSelection {
  readonly imageUrl: string;
  readonly name: string;
  readonly productKey: string;
}

export interface SkuSelection extends BaseSelection {
  readonly listPrice: Money;
  readonly singlePiecePrice: Money;
  readonly sku: string;
}

export interface VolumetricSelection extends BaseSelection {
  readonly density: number;
  readonly parts: number;
  readonly volumePrice: Money;
}

interface IngredientLimits {
  readonly min: number;
  readonly max: number;
}

export abstract class BaseCustomizer<T extends SkuSelection | VolumetricSelection> {
  abstract readonly type: string;

  selections: (T | undefined)[] = [];

  constructor(
    readonly ingredientLimits: IngredientLimits,
    selections?: (T | undefined)[],
    public quantity = 1,
    readonly redeemable = false,
  ) {
    this.selections = selections ?? new Array<T | undefined>(ingredientLimits.min).fill(undefined);
  }

  abstract calculatePrice(): Money;

  abstract calculateTotal(): Money;

  get canAddToCart() {
    if (!this.selections.every(isDefined)) return false;
    if (this.selections.length < this.ingredientLimits.min) return false;
    return this.selections.length <= this.ingredientLimits.max;
  }

  encodeCustomizerSelections(includeParts?: boolean) {
    const code = new Array(this.ingredientLimits.min).fill('000');
    this.selections.forEach((selection, slot: number) => {
      if (selection) {
        const hash = encoding.numToSxg(selection.productKey, 3);
        if (hash !== 0) {
          code[slot] = hash;
          if ('parts' in selection && includeParts) {
            code[slot] += selection.parts;
          }
        }
      }
    });
    return code.join('');
  }

  abstract prefillSelections(...args: unknown[]): void;

  reset() {
    this.quantity = 1;
    this.selections = new Array<T | undefined>(this.ingredientLimits.min).fill(undefined);
  }

  setQuantity(quantity: number) {
    this.quantity = quantity;
  }

  abstract setSelection(...args: unknown[]): void;
}

export function decodeCustomizerSelections(code: string, includesParts?: boolean): string[] {
  const chunkLength = includesParts ? 4 : 3;
  return chunk(code, chunkLength).map((segment) => {
    const productKey = encoding.sxgToNum(segment.slice(0, 3).join('')).toString();
    return includesParts ? `${productKey}:${segment.pop()}` : productKey;
  });
}
