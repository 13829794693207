import { Notification } from '@/stores/notifications';

/**
 * Helper to trigger SPA router navigation, with optional messaging. Catch handlers can look
 * for messages and use them on an as-needed basis, before routing to a new page.
 */
export class NutsRedirect extends Error {
  errors: Notification[];

  notices: Notification[];

  url: string;

  warnings: Notification[];

  constructor(
    url: string,
    messages?: { errors?: Notification[]; notices?: Notification[]; warnings?: Notification[] },
  ) {
    super('SPA redirect!');
    this.errors = messages?.errors ?? [];
    this.notices = messages?.notices ?? [];
    this.url = url;
    this.warnings = messages?.warnings ?? [];
  }
}
