import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';

export const useOverlay = defineStore('overlay', () => {
  const visible = ref(false);
  const scrollUnlocked = ref(false);

  const hide = () => {
    visible.value = false;
    scrollUnlocked.value = false;
  };

  const show = (unlockScroll = false) => {
    visible.value = true;
    scrollUnlocked.value = unlockScroll;
  };

  const toggle = () => {
    visible.value = !visible.value;
  };

  return {
    hide,
    scrollUnlocked: readonly(scrollUnlocked),
    show,
    toggle,
    visible: readonly(visible),
  };
});
