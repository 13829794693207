import type { SnakeCase } from 'type-fest';

import { AnalyticsMetadata } from '@/lib/personalization/dynamicYield';
import { ProductAdded } from '@/rudder-typer';

type DataSourceProvider = 'Algolia' | 'Dynamic Yield';

interface ListSourceAttributionContext {
  listSource?: DataSourceProvider;
  listTitle?: string;
}

interface AlgoliaListSourceAttribution {
  listSource: 'Algolia';
  listAlgoliaSearchId?: string;
  query?: string;
}

interface DynamicYieldListSourceAttribution {
  listSource: 'Dynamic Yield';
  listDyCampaignId: number;
  listDyCampaignName: string;
  listDyExperienceId: number;
  listDyExperienceName: string;
  listDyVariationId: number;
  listDyVariationName: string;
}

export type ListSourceAttribution = ListSourceAttributionContext &
  (AlgoliaListSourceAttribution | DynamicYieldListSourceAttribution);

export type ListSourceAttributionFields = keyof (ListSourceAttribution &
  Omit<AlgoliaListSourceAttribution, 'listSource'> &
  Omit<DynamicYieldListSourceAttribution, 'listSource'>);

export function formatAnalyticsMetadata(
  analyticsMetadata: AnalyticsMetadata,
): Omit<DynamicYieldListSourceAttribution, 'listSource'> {
  return {
    listDyCampaignId: analyticsMetadata.campaignId,
    listDyCampaignName: analyticsMetadata.campaignName,
    listDyExperienceId: analyticsMetadata.experienceId,
    listDyExperienceName: analyticsMetadata.experienceName,
    listDyVariationId: analyticsMetadata.variationId,
    listDyVariationName: analyticsMetadata.variationName,
  };
}

export function formatListSourceAttribution(
  listSourceAttribution?: ListSourceAttribution,
): Pick<ProductAdded, SnakeCase<ListSourceAttributionFields>> {
  let attributes: Pick<ProductAdded, SnakeCase<ListSourceAttributionFields>> = {
    list_source: listSourceAttribution?.listSource,
    list_title: listSourceAttribution?.listTitle,
  };

  if (listSourceAttribution?.listSource === 'Algolia') {
    attributes = {
      ...attributes,
      list_algolia_search_id: listSourceAttribution.listAlgoliaSearchId,
      query: listSourceAttribution.query,
    };
  } else if (listSourceAttribution?.listSource === 'Dynamic Yield') {
    attributes = {
      ...attributes,
      list_dy_campaign_id: listSourceAttribution.listDyCampaignId,
      list_dy_campaign_name: listSourceAttribution.listDyCampaignName,
      list_dy_experience_id: listSourceAttribution.listDyExperienceId,
      list_dy_experience_name: listSourceAttribution.listDyExperienceName,
      list_dy_variation_id: listSourceAttribution.listDyVariationId,
      list_dy_variation_name: listSourceAttribution.listDyVariationName,
    };
  }
  return attributes;
}
