<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';
import { CmsRegisteredComponent } from '@/utils/cms';

const BaseBodyText = defineComponent({
  name: 'BaseBodyText',
  props: {
    tag: { required: false, type: String as PropType<TypographyTags>, default: 'p' },
    underline: { required: false, type: Boolean, default: false },
  },
});

export const BaseBodyTextRegistration: CmsRegisteredComponent = {
  component: BaseBodyText,
  name: 'Base Body Text',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Base Body text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'underline',
      type: 'boolean',
      defaultValue: false,
    },
  ],
};

export default BaseBodyText;
</script>

<template>
  <component
    :is="tag"
    class="text-sm base-text lg:text-base lg:leading-6"
    :class="{ underline: underline }"
  >
    <slot />
  </component>
</template>

<style scoped>
.base-text {
  @apply mb-0;
}
.title {
  @apply leading-4 lg:leading-5;
}
</style>
