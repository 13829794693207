import type { captureEvent, init, setUser } from '@sentry/browser';
import { Integration } from '@sentry/core';

import { getConfigEntry } from '@/api/config';

export interface SentryWithoutReplay {
  captureEvent: typeof captureEvent;
  init: typeof init;
  setUser: typeof setUser;
}

// eslint-disable-next-line import/no-mutable-exports
export let sentry: SentryWithoutReplay | undefined;

export interface SentryOptions {
  tunnel?: string;
  integrations?: Integration[] | ((integrations: Integration[]) => Integration[]);
}

export function initSentry(constructor: SentryWithoutReplay, options?: SentryOptions) {
  const dsn = getConfigEntry('sentry')?.dsn;
  if (!dsn || sentry) return;

  sentry = constructor;
  sentry.init({
    dsn,
    integrations: options?.integrations,
    maxBreadcrumbs: 5,
    tunnel: options?.tunnel,
    attachStacktrace: true,
  });
}
