<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const SuperTitle = defineComponent({
  name: 'SuperTitle',
});

export const SuperTitleRegistration: CmsRegisteredComponent = {
  component: SuperTitle,
  name: 'Super Title',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Super Title text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default SuperTitle;
</script>

<template>
  <p class="font-bold uppercase super-title font-sofia-pro lg:text-xs">
    <slot />
  </p>
</template>

<style scoped>
.super-title {
  @apply mb-0;
  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
