import { BuilderContent, ComponentInfo as BuilderComponent } from '@builder.io/sdk-vue';
import { email, min, required } from '@vee-validate/rules';
import type { Entry, SetRequired } from 'type-fest';
import { Component } from 'vue';
import { LocationQuery } from 'vue-router';

import { getCookie } from '@/utils/isomorphic/cookie';

export type BooleanOrBooleanString = boolean | 'true' | 'false';

export type { ComponentInfo as BuilderComponent } from '@builder.io/sdk-vue';

export interface CmsRegisteredComponent extends BuilderComponent {
  component: Component;
}

type BuilderContentVariation = Entry<BuilderContent['variations']>[1];

export interface DataModel<T extends BuilderContent['data']> extends BuilderContent {
  data?: T;
  variations?: {
    [key: string]: BuilderContentVariation & { data?: T };
  };
}

export interface ExpandableReference<T extends BuilderContent> {
  '@type': '@builder.io/core:Reference';
  id: string;
  model: string;
  value?: T;
}

export interface CategoriesThumbnailGroup {
  listTitle: string;
  categories: {
    name: string;
    image: string;
    url: string;
  }[];
}

export interface CustomizerRecommendation {
  code: string;
  description: string;
  image: string;
  name: string;
}
export interface CustomizerRecommendationsResponse {
  type: 'mixes' | 'boxes' | 'trays';
  entries: CustomizerRecommendation[];
}

export interface HeaderAnnouncements {
  headerAnnouncements: {
    announcement: string;
    link: string;
  }[];
}

export interface GlobalCampaignCarousel {
  items: {
    content: string;
    promotionName: string;
  }[];
}
export interface HeaderLogoResponse {
  headerLogo: ExpandableReference<
    DataModel<{
      name: string;
      logo: string;
    }>
  >;
}

export function adaptLegacyBoolean(val?: BooleanOrBooleanString): boolean | undefined {
  if (typeof val === 'boolean') return val;
  if (typeof val === 'string') return val === 'true';
  return undefined;
}

export function contentHasVariations(
  content: BuilderContent,
): content is SetRequired<BuilderContent, 'variations'> {
  return Object.values(content.variations ?? {})?.length > 0;
}

function prioritizeWinner<T extends BuilderContent['data']>(content: DataModel<T>) {
  if (content.testRatio === 1) return content;
  return Object.values(content.variations ?? {}).find((v) => v?.testRatio === 1);
}

export function getContentVariationInfoFromCookie(content: BuilderContent) {
  // Builder's SDK currently fails to prioritize the winner with visual content
  const winner = content.data?.blocks ? undefined : prioritizeWinner(content);

  const cookieName = `builder.tests.${content.id}`;
  const testVariationId: BuilderContent['testVariationId'] =
    winner?.id ?? getCookie(cookieName, false);
  if (!testVariationId) {
    throw new Error(`Decision has not been stored for content: ${content.id}`);
  }
  const testVariationName: BuilderContent['testVariationName'] =
    content.variations?.[testVariationId]?.name ?? 'Default';
  return { testVariationId, testVariationName };
}

export function getVariationOrControl<T extends BuilderContent['data']>(
  content?: DataModel<T> | null,
) {
  if (!content || !contentHasVariations(content)) return content;
  const winner = prioritizeWinner<T>(content);
  if (winner) return winner;

  const { id, testVariationId, variations } = content;
  const chosenVariationId = testVariationId ?? getCookie(`builder.tests.${id}`, false);
  if (chosenVariationId) {
    return variations[chosenVariationId] ?? content;
  }

  const choice = Object.values(variations).find(
    (candidate) => candidate?.testRatio && Math.random() <= candidate.testRatio,
  );

  return choice ?? content;
}

export function normalizeQueryParam(urlQuery?: LocationQuery | URLSearchParams, key?: string) {
  if (!urlQuery || !key) return '';
  if (urlQuery instanceof URLSearchParams) {
    return urlQuery.get(key) ?? '';
  }
  let value = urlQuery[key] ?? '';
  if (Array.isArray(value)) {
    value = value.join(',');
  }
  return value;
}

export const TWColorsBackground: { readonly [key: string]: string } = {
  'baby blue': 'bg-nuts-sky-100',
  background: 'bg-white',
  'background (secondary)': 'bg-nuts-stone-100',
  'background (tertiary)': 'bg-nuts-neutral-100',
  black: 'bg-black',
  blue: 'bg-sky-500',
  'pink (candy)': 'bg-nuts-red-200',
  cream: 'bg-nuts-orange-50',
  'chocolate (dark)': 'bg-nuts-orange-950',
  error: 'bg-nuts-red-800',
  'chocolate (milk)': 'bg-nuts-amber-700',
  green: 'bg-nuts-lime-800',
  'green (dark)': 'bg-nuts-lime-900',
  navy: 'bg-nuts-sky-950',
  'nuts-sky': 'bg-nuts-sky-600',
  pistachio: 'bg-nuts-lime-600',
  white: 'bg-white',
  yellow: 'bg-nuts-amber-400',
  kale: 'bg-nuts-emerald-900',
  red: 'bg-nuts-red-800',
  'red (dark)': 'bg-nuts-red-900',
  transparent: 'bg-transparent',
  'true-gray': 'bg-black',
  'true-gray (light)': 'bg-nuts-neutral-700',
  'true-gray (bright)': 'bg-nuts-neutral-400',
};

export const TWColorsText: { readonly [key: string]: string } = {
  'baby blue': 'text-nuts-sky-100',
  background: 'text-white',
  'background (secondary)': 'text-nuts-stone-100',
  'background (tertiary)': 'text-nuts-neutral-100',
  black: 'text-black',
  blue: 'text-sky-500',
  'pink (candy)': 'text-nuts-red-200',
  cream: 'text-nuts-orange-50',
  'chocolate (dark)': 'text-nuts-orange-950',
  error: 'text-nuts-red-800',
  'chocolate (milk)': 'text-nuts-amber-700',
  green: 'text-nuts-lime-800',
  'green (dark)': 'text-nuts-lime-900',
  navy: 'text-nuts-sky-950',
  pistachio: 'text-nuts-lime-600',
  white: 'text-white',
  yellow: 'text-nuts-amber-400',
  kale: 'text-nuts-emerald-900',
  red: 'text-nuts-red-800',
  'red (dark)': 'text-nuts-red-900',
  'true-gray': 'text-black',
  'true-gray (light)': 'text-nuts-neutral-700',
  'true-gray (bright)': 'text-nuts-neutral-400',
};

export const validationRules = {
  email: (value: string) => {
    if (!email(value)) return 'Please enter a valid email';
    if (!required(value)) return 'This field is required';
    return true;
  },
  required: (value: string) => required(value) || 'This field is required',
  phone: (value: string) => min(value, [10]) || 'Please enter a valid phone number',
};
