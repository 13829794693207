import { shallowReactive } from 'vue';

import { reportError } from '@/utils/reportError';

export interface UseCallback<T = any, U extends any[] = any[]> {
  error: Error | undefined;
  isPending: boolean;
  result: T | undefined;

  execute(...args: U): Promise<T | undefined>;
  reset(): void;
}

/**
 * Inspired by `vue-promised`'s `usePromise()` but different in a few key ways:
 * - `.isPending` starts out false
 * - the returned object has a type declaration
 * - focused on letting the developer implement an arbitrary inner function
 *   rather than worrying about a `Ref<Promise<>>` incantation
 * - returns a `shallowReactive()` that will survive ref-unwrapping
 *
 * Like `usePromise()` this resolves (with `undefined`) the outer promise if the
 * callback rejects to save the callsite dealing with an unhandled rejection.
 *
 * Also informed by `@vue/core`'s `useAsyncState()` but focused on triggering
 * and monitoring an operation rather than on the state that is returned by that
 * operation.
 *
 * @param fn inner function to be called when returned `.execute(…)` is called
 * @returns a shallow reactive object with `error`, `isPending`, `result`, and `execute(…)`
 */
export function useCallback<T, U extends any[]>(
  fn: (...args: U) => T | PromiseLike<T>,
): UseCallback<T, U> {
  const self = shallowReactive({
    error: undefined as Error | undefined,
    isPending: false,
    result: undefined as T | undefined,

    async execute(...args: U) {
      self.error = undefined;
      self.isPending = true;
      try {
        self.result = await fn(...args);
      } catch (e) {
        const message = reportError(e);
        self.error = e instanceof Error ? e : new Error(message);
      } finally {
        self.isPending = false;
      }
      return self.result;
    },
    reset() {
      self.error = undefined;
      self.isPending = false;
      self.result = undefined;
    },
  });
  return self;
}
