<script lang="ts">
import { defineComponent, ref } from 'vue';

import VerticalImageAndText from '@/components/base/layout/VerticalImageAndText.vue';
import IngredientsModal from '@/components/digital-gift/IngredientsModal.vue';
import SelectionButton from '@/components/digital-gift/SelectionButton.vue';
import { CustomProductType } from '@/lib/customizers/digitalGifting';
import { CmsRegisteredComponent } from '@/utils/cms';

const OurGiftOptions = defineComponent({
  name: 'OurGiftOptions',
  props: {
    headline: { required: false, type: String, default: 'Our Gift Options' },
    text: {
      required: false,
      type: String,
      default:
        'Browse our selection of customizable gifts, ready to be tailored by your recipient(s).',
    },
  },
  components: {
    IngredientsModal,
    SelectionButton,
    VerticalImageAndText,
  },
  setup() {
    const showIngredientsModal = ref(false);
    const ingredientsModalCurrentType = ref();

    const handleOpenIngredientsModal = (type: CustomProductType) => {
      ingredientsModalCurrentType.value = type;
      showIngredientsModal.value = true;
    };

    const handleOpenBoxIngredientsModal = () => {
      handleOpenIngredientsModal(CustomProductType.Box);
    };

    const handleOpenTrayIngredientsModal = () => {
      handleOpenIngredientsModal(CustomProductType.Tray);
    };

    const handleCloseIngredientsModal = () => {
      ingredientsModalCurrentType.value = undefined;
      showIngredientsModal.value = false;
    };

    return {
      classicImage: nutshell['static_img/digital-gift/tray_classic_sm_thumb.png'],
      comingSoonImage: nutshell['static_img/digital-gift/coming-soon.jpg'],
      giftBoxImage: nutshell['static_img/digital-gift/gift_option_box.jpg'],
      giftTrayImage: nutshell['static_img/digital-gift/gift_option_tray.jpg'],
      showIngredientsModal,
      ingredientsModalCurrentType,
      handleOpenBoxIngredientsModal,
      handleOpenTrayIngredientsModal,
      handleCloseIngredientsModal,
      premiumImage: nutshell['static_img/digital-gift/tray_premium_sm_thumb.png'],
    };
  },
});

export const OurGiftOptionsRegistration: CmsRegisteredComponent = {
  component: OurGiftOptions,
  name: 'Our Gift Options',
  inputs: [
    {
      name: 'headline',
      type: 'string',
    },
    {
      name: 'text',
      type: 'string',
    },
  ],
};

export default OurGiftOptions;
</script>

<template>
  <div
    class="w-full h-full py-16 text-center lg:py-24"
    data-promo="1"
    data-promo-name="GiftDrop LP Gift Options"
    data-promo-creative="Get Started"
  >
    <div class="container px-0 mx-8 lg:px-4 md:mx-auto">
      <h2 class="text-3xl">{{ headline }}</h2>
      <p class="mt-2 text-base lg:mx-44">{{ text }}</p>
      <!-- eslint-disable-next-line max-len -->
      <div
        class="flex flex-col flex-wrap content-center justify-center mt-8 lg:flex-nowrap lg:flex-row lg:px-20"
      >
        <VerticalImageAndText
          :imageSource="giftTrayImage"
          class="bg-white border border-gray-300 border-solid rounded-lg lg:mr-2 lg:w-1/2"
        >
          <template v-slot:header>Custom Gift Tray</template>
          <template v-slot:content>
            <div>
              <div class="px-4 mb-4">
                Your giftee(s) can choose their favorites from 130+ of our core and gourmet snack
                offerings.
              </div>
              <RouterLink
                to="/gift-drop/build/gift?type=Tray&category=Premium"
                data-promo="1"
                data-promo-name="GiftDrop LP Gift Options"
                data-promo-creative="Select Size - Premium Gift Tray"
                class="text-current no-underline"
              >
                <SelectionButton
                  class="mx-4"
                  dataTest="GiftDrop LP Select Premium Tray"
                  description="130+ Choices"
                  :imageSource="premiumImage"
                  title="Premium - $69.99+"
                />
              </RouterLink>
              <RouterLink
                to="/gift-drop/build/gift?type=Tray&category=Classic"
                data-promo="1"
                data-promo-name="GiftDrop LP Gift Options"
                data-promo-creative="Select Size - Classic Gift Tray"
                class="text-current no-underline"
              >
                <SelectionButton
                  class="mx-4 mt-4"
                  dataTest="GiftDrop LP Select Classic Tray"
                  description="50+ Choices"
                  :imageSource="classicImage"
                  title="Classic - $59.99+"
                />
              </RouterLink>
            </div>
          </template>
          <template v-slot:footer>
            <div class="flex flex-col items-center text-sm md:flex-row">
              <div>Wondering what they can choose?</div>
              <div
                class="ml-1 font-semibold underline cursor-pointer"
                @click="handleOpenTrayIngredientsModal()"
                data-promo="1"
                data-promo-name="GiftDrop LP Gift Options"
                data-promo-creative="See Ingredient List"
              >
                See Ingredients
              </div>
            </div>
          </template>
        </VerticalImageAndText>
        <VerticalImageAndText
          :imageSource="giftBoxImage"
          class="mt-8 bg-white border border-gray-300 border-solid rounded-lg lg:mt-0 lg:ml-2 lg:w-1/2"
        >
          <template v-slot:header>Custom Gift Box</template>
          <template v-slot:content>
            <div>
              <div class="px-4 mb-4">
                Or, let them choose six delicious treats from a menu of 50 gourmet flavors.
              </div>
              <RouterLink
                to="/gift-drop/build/gift?type=Box&category=Classic"
                data-promo="1"
                data-promo-name="GiftDrop LP Gift Options"
                data-promo-creative="Select Size - Custom Gift Box"
                class="text-current no-underline"
              >
                <SelectionButton
                  class="mx-4"
                  dataTest="GiftDrop LP Select Medium"
                  description="50 Choices"
                  :imageSource="giftBoxImage"
                  title="Medium - $42.99"
                />
              </RouterLink>
              <SelectionButton
                class="mx-4 mt-4"
                dataTest="GiftDrop LP Select Coming Soon"
                :imageSource="comingSoonImage"
                isDisabled
                title="New Option Coming Soon"
              />
            </div>
          </template>
          <template v-slot:footer>
            <div class="flex flex-col items-center mb-4 text-sm md:flex-row">
              <div>Wondering what they can choose?</div>
              <div
                class="ml-1 font-semibold underline cursor-pointer"
                @click="handleOpenBoxIngredientsModal()"
                data-promo="1"
                data-promo-name="GiftDrop LP Gift Options"
                data-promo-creative="See Ingredient List"
              >
                See Ingredients
              </div>
            </div>
          </template>
        </VerticalImageAndText>
      </div>
      <div class="flex flex-col items-center mt-8 text-sm">
        *Can only ship throughout the United States (including Hawaii and Alaska) and Puerto Rico.
      </div>
    </div>
    <Teleport to="#teleported" v-if="showIngredientsModal">
      <IngredientsModal
        :isOpen="showIngredientsModal"
        :type="ingredientsModalCurrentType"
        @close-modal="handleCloseIngredientsModal"
      />
    </Teleport>
  </div>
</template>
