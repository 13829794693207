import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch';
import { Hit, SearchResponse } from 'instantsearch.js';

import { getConfigEntry } from '@/api/config';
import { VariantPrice } from '@/utils/productCard';

export const init = () => {
  const { app, key } = getConfigEntry('algolia');
  const client = algoliasearch(app, key);
  return client;
};

export function getIndex(client: SearchClient, name: string) {
  return client.initIndex(name);
}

interface QueryOptions {
  analyticsTags?: string[];
  clickAnalytics?: boolean;
  facetFilters?: string[] | string[][];
  filters?: string;
  hitsPerPage?: number;
  query?: string;
  ruleContexts?: string[];
  restrictSearchableAttributes?: string[];
  tags?: string[];
  typoTolerance?: boolean | 'min' | 'max';
}

export interface CategoryQueryOptions extends QueryOptions {
  categoryKey: string;
}

interface BaseAlgoliaCategory {
  objectID: string;
  name: string;
  idealForSuggestions: boolean;
  parentKey: string;
  path: string;
  url: string;
  description: string;
  // eslint-disable-next-line camelcase
  image_url: string;
  depth: number;
  parentId: number;
  parentName?: string;
  quantities: {
    products: number;
    subcategories: number;
    tags: number;
  };
  orderHint: number;
  queryID?: string;
}
export interface AlgoliaCategory extends BaseAlgoliaCategory {
  type: 'department';
}

export interface SynthesizedAlgoliaCategory extends BaseAlgoliaCategory {
  total: number;
  type: 'synthesizedDepartment';
}

export interface AlgoliaProduct {
  ancestryPairs: string[];
  categoryKeys: string[];
  /**
   * Formatted mapping of taxonomy lineages that contain this product. Supports hierarchical
   * refinement of products by category. Currently reaches at least `lvl3` in practice for some
   * products, may exceed. For now, we only use `lvl0` and `lvl1` for refinement.
   *
   * Uses a ` > ` separator for each level, e.g. `Chocolates & Sweets > Nuts`.
   */
  categoryNames: {
    lvl0: string[];
    lvl1?: string[];
  };
  /**
   * See {@link AlgoliaProduct.categoryNames} for format details.
   *
   * New category hierarchy added to support customizers categories.
   */
  customizerCategoryNames?: {
    lvl0: string[];
    lvl1?: string[];
  };
  density?: number;
  facets: {
    [facetName: string]: string[];
  };
  key: string;
  listingImageUrl: string;
  merchandisingCategory?: string;
  name: string;
  path: string;
  reportingCategory?: string;
  requiresCustomization: boolean;
  reviews: {
    averageRating?: number;
    count: number;
    totalRatings: number;
    totalReviews: number;
  };
  searchableTags: string[];
  shortDescription: string;
  tags: string[];
}

interface SuggestionOriginInsights {
  exact_nb_hits: number;
  facets: {
    analytics: Record<string, any>;
    exact_matches: Record<string, any>;
  };
}
export interface AlgoliaSuggestedSearch {
  Departments?: SuggestionOriginInsights;
  nb_words: number;
  objectID: string;
  popularity: number;
  Products?: SuggestionOriginInsights;
  query: string;
}

export interface AlgoliaVariant extends Hit {
  autoDeliveryEligible: boolean;
  bulk?: boolean;
  comparisonPrice?: number;
  cost: string;
  discountPercent?: number;
  displayPriceByChannel: Record<'default' | string, string>;
  formattedUnitPrice: string;
  hasSiblings?: boolean;
  outOfStock: boolean;
  path: string;
  popularity: number;
  preferFormattedUnitPrice: boolean;
  prices: VariantPrice[];
  Product: AlgoliaProduct;
  // eslint-disable-next-line camelcase
  Product_activatedAt: number;
  // eslint-disable-next-line camelcase
  Product_allVariantsOutOfStock: boolean;
  promoteShortVariantName: boolean;
  queryID?: string;
  shortVariantName: string;
  singlePiecePrice: number;
  singlePiecePriceByChannel: Record<'default' | string, number>;
  sku: string;
  traits: string[];
  warehouses: string[];
  weight: string;
  wholesale?: boolean;
}

export const parseResponse = <T extends { queryID?: string }>(response: SearchResponse<T>) => {
  if (!response.queryID) return response.hits;

  return response.hits.map((h) => ({ ...h, queryID: response.queryID }));
};

export async function browseProducts(index: SearchIndex, options: QueryOptions) {
  let hits: AlgoliaVariant[] = [];
  await index.browseObjects<AlgoliaVariant>({
    clickAnalytics: options.clickAnalytics,
    facetFilters: options.facetFilters ?? [],
    filters: options.filters ?? '',
    query: options.query ?? '',
    ruleContexts: options.ruleContexts ?? [],
    restrictSearchableAttributes: options.restrictSearchableAttributes ?? [],
    tags: options.tags ?? [],
    batch: (batch) => {
      hits = hits.concat(batch);
    },
  });
  return hits;
}

export async function searchCategories(index: SearchIndex, options: QueryOptions) {
  const response = await index.search<AlgoliaCategory | SynthesizedAlgoliaCategory>(
    options.query ?? '',
    {
      analyticsTags: options.analyticsTags ?? ['Search Categories'],
      clickAnalytics: options.clickAnalytics,
      filters: options.filters ?? '',
      facetFilters: options.facetFilters ?? [],
      hitsPerPage: options.hitsPerPage ?? 50,
      ruleContexts: options.ruleContexts ?? [],
    },
  );

  return parseResponse(response);
}

export async function searchProductsByAllTags(index: SearchIndex, options: QueryOptions) {
  const { tags = [] } = options;
  const response = await index.search<AlgoliaVariant>(options.query ?? '', {
    analyticsTags: options.analyticsTags ?? ['Search Products By All Tags'],
    clickAnalytics: options.clickAnalytics,
    facetFilters: [tags.map((tag) => `Product.tags:${tag}`)],
    hitsPerPage: options.hitsPerPage ?? 50,
    ruleContexts: options.ruleContexts ?? [],
  });

  return parseResponse(response);
}

export async function searchProducts(index: SearchIndex, options: QueryOptions) {
  const response = await index.search<AlgoliaVariant>(options.query ?? '', {
    analyticsTags: options.analyticsTags ?? ['Search Products'],
    clickAnalytics: options.clickAnalytics,
    facetFilters: options.facetFilters ?? [],
    hitsPerPage: options.hitsPerPage ?? 50,
    ruleContexts: options.ruleContexts ?? [],
    restrictSearchableAttributes: options.restrictSearchableAttributes ?? [],
  });

  return parseResponse(response);
}

export async function getChildCategories(index: SearchIndex, options: CategoryQueryOptions) {
  const response = await index.search<AlgoliaCategory | SynthesizedAlgoliaCategory>(
    options.query ?? '',
    {
      analyticsTags: options.analyticsTags ?? ['Search Child Categories'],
      clickAnalytics: options.clickAnalytics,
      facetFilters: [`parentKey:${options.categoryKey}`],
      hitsPerPage: options.hitsPerPage ?? 50,
      ruleContexts: options.ruleContexts ?? [],
      typoTolerance: false,
    },
  );

  return parseResponse(response);
}

export async function getProductsByCategory(index: SearchIndex, options: CategoryQueryOptions) {
  const response = await index.search<AlgoliaVariant>(options.query ?? '', {
    analyticsTags: ['Search Products By Category'],
    clickAnalytics: options.clickAnalytics,
    facetFilters: [`Product.categoryKeys:${options.categoryKey}`],
    filters: options.filters ?? 'traits:primary',
    hitsPerPage: options.hitsPerPage ?? 50,
    ruleContexts: options.ruleContexts ?? [],
    typoTolerance: false,
  });

  return parseResponse(response);
}

export async function entryCountsForChildCategories(
  index: SearchIndex,
  options: CategoryQueryOptions,
) {
  const ancestorPairStart = `${options.categoryKey.replace('-', '')}subcat`;
  const { facetHits } = await index.searchForFacetValues(
    'Product.ancestryPairs',
    ancestorPairStart,
    {
      analyticsTags: options.analyticsTags ?? ['Search Entry Count Child Categories'],
      clickAnalytics: options.clickAnalytics,
      filters: options.filters ?? 'traits:primary',
      maxFacetHits: 100,
      ruleContexts: options.ruleContexts ?? [],
      typoTolerance: false,
    },
  );

  return facetHits.reduce<{ [objectID: string]: number }>((countByCategory, facetHit) => {
    if (facetHit.value.includes('subcat')) {
      return {
        ...countByCategory,
        [facetHit.value.split('subcat')[1]]: facetHit.count,
      };
    }
    return countByCategory;
  }, {});
}

export default {
  init,
  entryCountsForChildCategories,
  getChildCategories,
  getIndex,
  getProductsByCategory,
  searchProductsByAllTags,
};
