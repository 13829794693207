<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';
import { CmsRegisteredComponent } from '@/utils/cms';

const Header1 = defineComponent({
  name: 'Header1',
  props: {
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'h1' },
  },
});

export const Header1Registration: CmsRegisteredComponent = {
  component: Header1,
  name: 'Header1',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Header 1 text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default Header1;
</script>

<template>
  <component :is="headerTag" class="font-bold header-1 font-gelica">
    <slot />
  </component>
</template>

<style scoped>
.header-1 {
  @apply mb-0;
  font-size: 32px;
  line-height: 36px;
}
@media (min-width: 1024px) {
  .header-1 {
    font-size: 48px;
    line-height: 52px;
  }
}
</style>
