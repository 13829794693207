/* eslint-disable no-shadow */
import { computed } from 'vue';

import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useState } from '@/composables/useState';

export enum ChatStatus {
  DISABLED = 'disabled',
  OFFLINE = 'offline',
  ONLINE = 'online',
  HIDDEN = 'hidden',
  NONE = 'none',
}

export type GladlyChatStatus = 'AVAILABLE' | 'UNAVAILABLE_BUSY' | 'UNAVAILABLE_OFFICE_CLOSED';

let isSuppressed = false;

export function useChat() {
  const { isInitiated, displayChatInfoText, ignoreChatInfoText } = useState('chat', () => ({
    isInitiated: false,
    displayChatInfoText: false,
    ignoreChatInfoText: false,
  }));

  const { flags } = useFeatureFlags();

  function openChat() {
    if (flags.enableGladly) {
      window.Gladly?.show();
    } else {
      window.Kustomer?.open();
    }
  }

  function hideChatIcon() {
    if (flags.enableGladly) {
      const chatWidget = document.getElementById('gladlyChat_container');
      if (chatWidget) {
        chatWidget.style.visibility = 'hidden';
      }
    } else {
      window.Kustomer?.stop();
    }
  }

  function showChatIcon() {
    if (isSuppressed) return;
    if (flags.enableGladly) {
      const chatWidget = document.getElementById('gladlyChat_container');
      if (chatWidget) {
        chatWidget.style.visibility = 'visible';
      }
    } else {
      window.Kustomer?.start();
    }
  }

  function suppressChat() {
    isSuppressed = true;
    hideChatIcon();
  }

  function unsuppressChat() {
    isSuppressed = false;
  }

  const isChatStatusPending = computed(
    () => !displayChatInfoText.value && !ignoreChatInfoText.value,
  );

  const checkChatAvailability = () => {
    if (flags.enableGladly) {
      const availability = window.Gladly?.getAvailability();
      return availability === 'AVAILABLE';
    }
    const status = window.Kustomer?.isChatAvailable();
    return status.availability === ChatStatus.ONLINE;
  };

  function initChat() {
    if (!isInitiated.value) {
      isInitiated.value = true;
      setTimeout(() => {
        ignoreChatInfoText.value = true;
      }, 3000);
      if (flags.enableGladly) {
        const availability = window.Gladly?.getAvailability();
        if (availability === 'AVAILABLE') {
          displayChatInfoText.value = true;
        }
      } else {
        window.addEventListener('kustomerStarted', () => {
          const status = window.Kustomer.isChatAvailable();
          if (status.availability === ChatStatus.ONLINE && !ignoreChatInfoText.value) {
            displayChatInfoText.value = true;
          }
        });
      }
    }
  }

  return {
    checkChatAvailability,
    hideChatIcon,
    initChat,
    displayChatInfoText,
    openChat,
    isChatStatusPending,
    showChatIcon,
    suppressChat,
    unsuppressChat,
  };
}
