<script setup lang="ts">
import { computed } from 'vue';

const badges: Record<string, string> = {
  'badge-limited': 'Seasonal',
  'badge-shipsfree': 'Ships Free',
  'badge-bestseller': 'Best Seller',
  'badge-onsale': 'Sale',
};

const badgesStyles: Record<string, string> = {
  'badge-limited': 'bg-nuts-orange-50 text-amber-700',
  'badge-shipsfree': 'bg-nuts-sky-100 text-nuts-sky-800',
  'badge-bestseller': 'bg-nuts-lime-100 text-nuts-emerald-900',
  'badge-onsale': 'bg-nuts-red-800 text-white',
};

const props = withDefaults(defineProps<{ alignment?: 'left' | 'right'; tags: string[] }>(), {
  alignment: 'right',
});
const badge = computed(() => {
  const badgeKey = props.tags?.find((tag) => badges[tag]);
  if (!badgeKey) return {};
  return { label: badges[badgeKey], key: badgeKey, colorClass: badgesStyles[badgeKey] };
});

const borderClass = computed(() => {
  if (props.alignment === 'left') {
    return 'rounded-br rounded-tl';
  }
  return 'rounded-bl rounded-tr';
});
</script>

<template>
  <div
    v-show="badge.key"
    class="text-[10px] leading-5 md:text-sm box-border h-5 font-semibold px-1.5 text-xs"
    :class="[badge.colorClass, borderClass]"
    data-test="badge"
  >
    {{ badge.label }}
  </div>
</template>
