<script setup lang="ts">
import { useVModel } from '@vueuse/core';

import Select from '@/components/base/form/OutlinedSelect.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';

interface AvailableSize {
  label: string;
  value: string;
}

const props = defineProps<{
  displayShortVariantName?: boolean;
  hasSiblings?: boolean;
  requiresCustomization?: boolean;
  selectedSku: string;
  shortUnitName: string;
  showVariants: boolean;
  sizesAvailable?: AvailableSize[];
}>();

const emit = defineEmits<{
  (e: 'update:selectedSku', value: string): void;
}>();

const selection = useVModel(props, 'selectedSku', emit);

const { flags } = useFeatureFlags();
</script>

<template>
  <template v-if="showVariants">
    <Select
      v-if="hasSiblings && sizesAvailable && !requiresCustomization"
      v-model="selection"
      :options="sizesAvailable"
      size="small"
      class="mt-2"
      aria-label="sizes-available"
      data-test="product-size-dropdown"
    />
    <SmallBodyText v-else class="mt-2 text-neutral-500" data-test="unit-name">
      {{ shortUnitName }}
    </SmallBodyText>
  </template>
  <SmallBodyText
    v-else-if="displayShortVariantName && !flags.testVariantNameDisplay"
    class="mt-2 text-neutral-500"
    data-test="unit-name"
  >
    {{ shortUnitName }}
  </SmallBodyText>
</template>
