<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const CarouselContainer = defineComponent({
  name: 'CarouselContainer',
  props: {
    title: { required: false, type: String },
  },
  setup() {
    const scrollContainer = ref<HTMLElement>();
    const nextDisabled = ref(false);
    const prevDisabled = ref(true);
    const handleScroll = () => {
      const { offsetWidth, scrollLeft, scrollWidth } = scrollContainer.value!;
      nextDisabled.value = scrollWidth <= offsetWidth + scrollLeft;
      prevDisabled.value = scrollLeft === 0;
    };

    const calculateTotalScrollWidth = (containerOffsetWidth: number, cardWidth: number) => {
      const visibleCardCount = Math.round(containerOffsetWidth / cardWidth);
      return cardWidth * visibleCardCount;
    };

    const pageScrollTo = (scrollDir: 'next' | 'prev') => {
      if (scrollContainer.value?.children[0]) {
        const cardWidth = scrollContainer.value.children[0].offsetWidth + 12;
        const scrollWidth = calculateTotalScrollWidth(scrollContainer.value.offsetWidth, cardWidth);
        const scrollFactor = scrollDir === 'prev' ? -1 : 1;
        scrollContainer.value.scrollBy({
          left: scrollWidth * scrollFactor,
          behavior: 'smooth',
        });
      }
    };

    const scrollToStart = () => {
      if (scrollContainer.value) {
        const scrolledPixels = scrollContainer.value.scrollLeft + scrollContainer.value.offsetWidth;
        scrollContainer.value.scrollLeft = -scrolledPixels;
      }
    };

    const prev = () => {
      pageScrollTo('prev');
    };

    const next = () => {
      pageScrollTo('next');
    };

    onMounted(() => {
      scrollToStart();
    });

    return {
      nextDisabled,
      prevDisabled,
      handleScroll,
      calculateTotalScrollWidth,
      pageScrollTo,
      scrollToStart,
      prev,
      next,
      scrollContainer,
    };
  },
});

export const CarouselContainerRegistration: CmsRegisteredComponent = {
  component: CarouselContainer,
  name: 'Carousel Container',
  canHaveChildren: true,
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Categories',
    },
  ],
};

export default CarouselContainer;
</script>

<template>
  <div>
    <div class="flex justify-between">
      <div
        id="title"
        class="h-auto mb-5 text-xl font-semibold font-sofia-pro md:text-3xl sm:w-full"
      >
        {{ title }}
      </div>
      <div class="flex max-h-7 md:hidden">
        <button
          class="h-auto prev"
          :class="{ 'opacity-25': prevDisabled }"
          @click="prev"
          :disabled="prevDisabled"
          aria-label="Prev"
        />
        <button
          class="h-auto next"
          :class="{ 'opacity-25': nextDisabled }"
          @click="next"
          :disabled="nextDisabled"
          aria-label="Next"
        />
      </div>
    </div>
    <div
      class="flex flex-row gap-3 px-1 pb-2 overflow-x-auto overflow-y-hidden categories md:justify-between lg:flex-wrap"
      data-test=""
      ref="scrollContainer"
      v-on:scroll="handleScroll"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
.categories::-webkit-scrollbar {
  display: none;
}
.prev {
  border: none;
  background: url('/nutshell/img/caret-left-5aa5b9dc.svg') top center no-repeat;
  margin-right: 26px;
}
.next {
  border: none;
  background-color: transparent;
  background: url('/nutshell/img/caret-right-85ceb05d.svg') top center no-repeat;
  margin-right: 40px;
}
</style>
