import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import type { ApiCallback, ApiObject, ApiOptions, IdentifyTraits } from '@rudderstack/analytics-js';
import type { IsEqual } from 'type-fest';

import { NutsLineItem } from '@/lib/cart/lineItem';
import {
  CartViewedProductsItem,
  CheckoutStartedProductsItem,
  OrderPlacedProductsItem,
  productsSearched,
  setRudderTyperOptions,
} from '@/rudder-typer';
import { isDefined } from '@/utils/isDefined';
import { getUnitName } from '@/utils/product';
import { getSsrState } from '@/utils/ssrStorage';

interface IdentifyEventFromServer {
  context?: ApiObject;
  traits?: IdentifyTraits;
  type: 'identify';
  userId?: string;
}

interface TrackEventFromServer {
  context?: ApiObject;
  event: string;
  properties?: ApiObject;
  type: 'track';
}

export type RudderstackEventFromServer = IdentifyEventFromServer | TrackEventFromServer;

export interface GoogleEventObjItem {
  coupon?: string;
  index?: number;
  item_id: string;
  item_name?: string;
  item_variant?: string;
  price: number;
  quantity?: number;
}

export interface GoogleEventObj {
  items: GoogleEventObjItem[];
}

export type StandardProductsItem = CartViewedProductsItem;

// assert that various cart/checkout-related RS *ProductsItem types are identical
type StaticAssert<T extends true> = T;
type _check = StaticAssert<IsEqual<StandardProductsItem, CartViewedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, CheckoutStartedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, OrderPlacedProductsItem>>;

/**
 * Additional fields we attach to each track event's context.page
 */
interface NutsTrackPageContext {
  category?: string;
  referrer?: string;
}

let trackPageContext: NutsTrackPageContext | undefined;

export const formatStandardProductsItem = (lineItem: NutsLineItem): StandardProductsItem => ({
  name: lineItem.name?.en,
  price: dollars(lineItem.piecePrice),
  product_id: lineItem.productKey,
  quantity: lineItem.quantity,
  reporting_category: lineItem.variant.attributes?.find((attr) => attr.name === 'reportingCategory')
    ?.value.key,
  sku: lineItem.variant.sku,
  variant: getUnitName(lineItem.variant.variantName) ?? undefined,
});

export const sendProductsSearchedEvent = (query: string): void => {
  if (query) productsSearched({ query });
};

export function sendRudderstackEvents(events: RudderstackEventFromServer[]) {
  events.forEach((payload) => {
    const options = payload.context && { context: payload.context };
    if (payload.type === 'identify') {
      identify(payload.userId, payload.traits, options);
    } else if (payload.type === 'track') {
      track(payload.event, payload.properties, options);
    }
  });
}

export function setTrackPageContext(context?: NutsTrackPageContext): void {
  trackPageContext = context;
}

/**
 * Copy `trackPageContext` into `context.page` but let any existing values win
 *
 * Note: rudderanalytics also supports top-level `page`, but since this function
 * doesn't go out of its way to handle it, `trackPageContext` and `context.page`
 * will end up overriding any matching fields. For consistent results, prefer
 * `context.page` for any custom, one-off values.
 */
export function withPageContext(options?: ApiOptions): ApiOptions | undefined {
  if (!trackPageContext) return options;

  const context = (options?.context as ApiObject) ?? {};
  const incomingPageContext = (context.page as ApiObject) ?? {};

  return {
    ...options,
    context: {
      ...context,
      page: { ...trackPageContext, ...incomingPageContext },
    },
  };
}

/**
 * Wrapper for `rudderanalytics.identify()` which:
 * - supports SSR (by queuing events for the client to "replay")
 * - doesn't support skipping arguments (messy to support and we don't need it)
 *   - to "skip" `userId`, pass `undefined`
 */
export function identify(
  userId?: string,
  traits?: IdentifyTraits,
  options?: ApiOptions,
  callback?: ApiCallback,
): void {
  if (import.meta.env.SSR) {
    // During SSR, just queue
    getSsrState().rudderstackEvents?.push({
      context: options?.context as ApiObject | undefined,
      traits,
      type: 'identify',
      userId,
    });
  } else if (isDefined(userId)) {
    window.rudderanalytics?.identify(userId, traits, options, callback);
  } else {
    window.rudderanalytics?.identify(traits ?? null, options, callback);
  }
}

/**
 * Wrapper for `rudderanalytics.track()` which:
 * - supports SSR (by queuing events for the client to "replay")
 * - merges our custom page context into `context.page` (when running client side)
 * - doesn't support skipping arguments (messy to support and we don't need it)
 */
export function track(
  event: string,
  properties?: ApiObject,
  options?: ApiOptions,
  callback?: ApiCallback,
): void {
  if (import.meta.env.SSR) {
    // During SSR, just queue
    getSsrState().rudderstackEvents?.push({
      context: options?.context as ApiObject | undefined,
      event,
      properties,
      type: 'track',
    });
  } else {
    // On the client side, merge in the latest custom page context
    window.rudderanalytics?.track(event, properties, withPageContext(options), callback);
  }
}

/**
 * Install into RudderTyper a wrapper to help with our custom page context tracking
 *
 * (It's okay for this to be called multiple times.)
 */
export function installRudderTyperWrapper() {
  setRudderTyperOptions({
    analytics: { track },
  });
}
