import { storeToRefs } from 'pinia';
import { readonly } from 'vue';

import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import { AnalyticsMetadata, FeatureFlagsChoice } from '@/lib/personalization/dynamicYield';
import {
  FeatureFlags,
  FlagsByLayer,
  Layer,
  layers,
  useFeatureFlags as useFeatureFlagsStore,
} from '@/stores/featureFlags';
import { dyEvent, gaEvent } from '@/utils/analytics';

export function fromFlagSpec(flagSpec: string): FeatureFlags {
  const entries = flagSpec.split(',').map((spec) => {
    const [_, setting, name] = /^(-?)(.+)$/.exec(spec)!;
    return [name, setting !== '-'];
  });
  return Object.fromEntries(entries);
}

export function toFlagSpec(flags: FeatureFlags, flagNames?: (keyof FeatureFlags)[]): string {
  return (flagNames ?? Object.keys(flags).sort())
    .map((name) => `${flags[name] ? '' : '-'}${name}`)
    .join();
}

export function useFeatureFlags() {
  const { choicesByName, loadExperiences } = useDyChooseResults<FeatureFlagsChoice>([]);

  const { flags, flagsByLayer } = storeToRefs(useFeatureFlagsStore());

  /**
   * Update the manually-maintained reactive `flags` object to match the current
   * state of the `flagsByLayer` object. Called by replaceFlags() and setFlags().
   */
  function updateReactiveFlags(updatedKeys: string[]) {
    updatedKeys.forEach((key) => {
      const newValue = layers.flatMap((l) => flagsByLayer.value[l][key] ?? [])[0];
      if (newValue === undefined) {
        delete flags.value[key];
      } else if (flags.value[key] !== newValue) {
        flags.value[key] = newValue;
      }
    });
  }

  /**
   * Replace all flags in all layers with the given flags. Meant for use client
   * side when a whole new set is received from the server, such as after
   * sign-in or other identification change.
   */
  function replaceFlagsByLayer(newFlagsByLayer: FlagsByLayer) {
    // update the canonical layered flag sets
    layers.forEach((layer) => {
      flagsByLayer.value[layer] = newFlagsByLayer[layer] ?? {};
    });

    // update the reactive object to match
    // (examine all flag keys, old and new, to make sure none are accidentally left around)
    const allKeys = [flags.value, ...Object.values(flagsByLayer.value)].flatMap(Object.keys);
    const uniqueKeys = [...new Set(allKeys)];
    updateReactiveFlags(uniqueKeys);
  }

  /**
   * Set one or more specific flags in the given layer (merging with existing
   * flags) and propagate changes to the server be persisted. Used by
   * loadDyFlags().
   */
  function setFlags(updates: FeatureFlags, layer: Layer = 'local') {
    // update the canonical layered flag sets
    const updatedLayer = { ...flagsByLayer.value[layer], ...updates };
    Object.keys(updates).forEach((key) => updates[key] === undefined && delete updatedLayer[key]);
    flagsByLayer.value[layer] = updatedLayer;

    // update the reactive object to match
    updateReactiveFlags(Object.keys(updates));
  }

  /**
   * Load visitor-level flags from a DY campaign
   *
   * DY will be queried if and only if we don't already have a value for at
   * least one of the listed flags. Flags are expected to be in a `flags` field
   * of the DY API campaign variation. After awaiting this promise, look at the
   * `flags` reactive object as usual for the latest flags.
   *
   * @param selector  API Selector of the DY campaign that will (hopefully)
   * provide at least one of the flags in question
   * @param flagNames One or more flags that we hope DY can tell us about
   * @param attributionStorage Optional function to call with the `analyticsMetadata` field
   */
  async function loadDyFlags(
    selector: string,
    flagNames: (keyof FeatureFlags)[],
    attributionStorage?: (analyticsMetadata?: AnalyticsMetadata) => void,
  ) {
    if (flagNames.some((name) => name in flags.value)) {
      return;
    }

    let variation = choicesByName[selector]?.variations[0];
    if (!variation) {
      await loadExperiences({ newExperiences: [selector], skipLoadedExperiences: true });
      variation = choicesByName[selector]?.variations[0];
    }

    const data = variation?.payload.data;
    if (data?.flags && typeof data.flags === 'string') {
      attributionStorage?.(variation?.analyticsMetadata);
      setFlags(fromFlagSpec(data.flags), 'visitor');
    }

    if (data?.dyEvent && typeof data.dyEvent === 'object') {
      dyEvent(data.dyEvent);
    }

    if (data?.gaEvent && typeof data.gaEvent === 'object') {
      gaEvent(data.gaEvent);
    }
  }

  return {
    flags: readonly(flags.value),
    loadDyFlags,
    setFlags,
    replaceFlagsByLayer,
  };
}
